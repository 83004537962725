import { JSONSchema7 } from "json-schema";
import { isMap, isPair, isScalar, Scalar, YAMLMap, YAMLSeq } from "yaml";
import { YAMLDocNode } from "../hooks/useYamlDoc";
import { get, isString, tail } from "lodash-es";
import { RootWidgetContext } from "../containers/WidgetPropertyPanel/fields/RootWidgetContext";
import { ActionSchema } from "../hooks/useSchemas";

// all the root keys that are not custom widgets
const NON_CUSTOM_WIDGETS_NAMES = [
  "View",
  "API",
  "Global",
  "Socket",
  "Action",
  "App",
  "Function",
  "Model",
  "Variable",
];

export const getCustomWidgetNodes = (root: YAMLMap): Scalar[] => {
  const result = root.items.filter(
    (item) =>
      !NON_CUSTOM_WIDGETS_NAMES.includes((item.key as Scalar).value as string),
  );
  return result.map((item) => item.key as Scalar);
};

export const getKey = (node: YAMLDocNode): Scalar | undefined => {
  if (isPair(node)) {
    return node.key as Scalar;
  }

  if (isScalar(node)) {
    return node;
  }

  return;
};

export const getKeyValue = (node: YAMLDocNode) => {
  if (isPair(node)) {
    return String((node.key as Scalar).value);
  }

  if (isScalar(node)) {
    return String(node.value);
  }

  return;
};

/**
 * We want the actions' schemas map, but we want the nested action names
 * to appear in the Tree instead of the Property Panel. So we build and return:
 * 1. A map of action names to their schema (without child action names)
 * 2. A map of action names to a list of child action names
 *
 * Note that this will remove the direct child actions from the main Action schema
 */
export const buildActionSchemaMapAndMutate = (
  root: JSONSchema7,
): ReadonlyMap<string, ActionSchema> => {
  const actionSchemaMap = new Map<string, ActionSchema>();
  (root.$defs?.Action as JSONSchema7)?.oneOf?.map((entry) => {
    const properties = (entry as JSONSchema7)?.properties;
    if (!properties) return;

    // we expect a mapping of ActionName -> $ref
    Object.entries(properties).map(([actionName, actionValue]) => {
      if ((actionValue as JSONSchema7).$ref) {
        const refName = (actionValue as JSONSchema7).$ref!.split("/").pop();
        if (refName) {
          // we go further by fetching the definition and remove all the root child Action
          // This is because nested Actions will be shown in the tree.
          const action = getDefinitionByName(root, refName);
          if (action && action.properties) {
            // found our action schema

            // remove the child actions from the action schema and add to the actionChildrenMap
            const actionChildren = new Set<string>();
            Object.keys(action.properties).map((propName) => {
              const prop = action.properties![propName] as JSONSchema7;
              if (prop.$ref === "#/$defs/Action") {
                actionChildren.add(propName);

                // mutate the original schema
                delete action.properties![propName];
              }
            });

            // add to the map
            actionSchemaMap.set(actionName, {
              ref: refName,
              childEvents: actionChildren,
            });
          }
        }
      }
    });
  });
  return actionSchemaMap;
};

/**
 * Compile a list of container names and widget names (including containers)
 * This will also mutate the original schema by removing 'children' property.
 */
export const buildWidgetSchemaMapAndMutate = (
  root: JSONSchema7 | undefined,
): {
  widgetNames: Set<string>;
  containerNames: Set<string>;
  widgetSchemaMap: Map<string, JSONSchema7>;
} => {
  const widgetSchemaMap = new Map<string, JSONSchema7>();
  const containerSchemaMap = new Map<string, JSONSchema7>();

  (root && (root.$defs?.Widget as JSONSchema7))?.oneOf?.map((entry) => {
    const properties = (entry as JSONSchema7)?.properties as JSONSchema7;
    if (!properties) return;

    Object.entries(properties).map(([widgetName, ref]) => {
      const value = getDefinitionByName(root, widgetName, ref.$ref);
      if (!value) return;

      // strip the children out as children are handled as the tree's child nodes
      if (value.properties?.children) {
        containerSchemaMap.set(widgetName, value);
      }
      widgetSchemaMap.set(widgetName, value);
    });
  });

  containerSchemaMap.forEach((value) => delete value.properties?.children);
  return {
    widgetNames: new Set(widgetSchemaMap.keys()),
    containerNames: new Set(containerSchemaMap.keys()),
    widgetSchemaMap,
  };
};

export const getDefinitionByName = (
  root: JSONSchema7,
  name: string,
  ref?: string,
): JSONSchema7 | undefined => {
  let schema = root.$defs?.[name] as JSONSchema7;
  if (isString(ref)) {
    schema = get(root, tail(ref.split("/")));
  }
  while (schema?.$ref) {
    const refName = schema.$ref.split("/").pop();
    if (!refName) break;

    schema = root.$defs?.[refName] as JSONSchema7;
    if (!schema) break;
  }
  return schema;
};

// generate a new widget name starting with (NEW_WIDGET_PREFIX + number)++
const NEW_WIDGET_PREFIX = "MyWidget";
export const generateWidgetName = (root: YAMLMap) => {
  const filteredPairs = root.items.filter((pair) => {
    const key = (pair.key as Scalar).value as string;
    return (
      key.startsWith(NEW_WIDGET_PREFIX) &&
      /^\d+$/.test(key.substring(NEW_WIDGET_PREFIX.length))
    );
  });

  const maxNumber = filteredPairs.reduce((max, pair) => {
    const key = (pair.key as Scalar).value as string;
    const numPart = parseInt(key.substring(NEW_WIDGET_PREFIX.length), 10);
    return Math.max(max, numPart);
  }, 0);

  return `${NEW_WIDGET_PREFIX}${maxNumber + 1}`;
};

// generate new API name
const NEW_API_PREFIX = "MyApi";
export const generateAPIName = (root: YAMLMap) => {
  const apiNode = root.get("API");

  if (!apiNode || !isMap(apiNode)) {
    return `${NEW_API_PREFIX}1`;
  }

  const filteredPairs = apiNode.items.filter((pair) => {
    const key = (pair.key as Scalar).value as string;
    return (
      key.startsWith(NEW_API_PREFIX) &&
      /^\d+$/.test(key.substring(NEW_API_PREFIX.length))
    );
  });

  const maxNumber = filteredPairs.reduce((max, pair) => {
    const key = (pair.key as Scalar).value as string;
    const numPart = parseInt(key.substring(NEW_API_PREFIX.length), 10);
    return Math.max(max, numPart);
  }, 0);

  return `${NEW_API_PREFIX}${maxNumber + 1}`;
};

// add a empty child widget to the parent's children property
export const addChildWidget = (parent: YAMLMap, child: YAMLDocNode) => {
  let childrenNode = parent.get("children");
  if (childrenNode instanceof YAMLSeq) {
    childrenNode.add(child);
  } else {
    childrenNode = new YAMLSeq();
    (childrenNode as YAMLSeq).add(child);
    parent.set("children", childrenNode);
  }
};
