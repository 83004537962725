import { FieldProps } from "@rjsf/utils";
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import "./TextAlignmentField.sass";
import { getOptionTooltip, OptionItem } from "../utils/rendererUtils";
import { CustomJSONSchema7 } from "../utils/propertyPanelSchemaUtils";

const alignmentIconMap: { [key: string]: string } = {
  start: "align-left",
  center: "align-center",
  end: "align-right",
  justify: "align-justify",
  left: "align-left",
  right: "align-right",
};

export const TextAlignmentField: React.FC<FieldProps> = ({
  schema,
  formData = (schema as CustomJSONSchema7).defaultValue,
  onChange,
  formContext,
}) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const options: OptionItem[] =
    schema.oneOf
      ?.map((option) => {
        if (typeof option === "object" && option !== null) {
          return {
            value: option.const as string,
            description: option.description,
          };
        }
        return null;
      })
      .filter((option): option is OptionItem => option !== null) ?? [];

  const handleOnClick = (newValue: string) => {
    if (newValue !== formData) {
      onChange(newValue);
      setHasPendingChanges(true);
    }
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges, formContext]);

  return (
    <div className="text-alignment-field">
      {options.map((option) => (
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={getOptionTooltip(option)}
          placement="top"
          key={option.value}
        >
          <div
            className={`text-alignment-cell ${
              formData === option.value ? "selected" : ""
            } ${
              option.value === "justify"
                ? "text-alignment-after-primary-actions"
                : ""
            }`}
            onClick={() => handleOnClick(option.value)}
          >
            {alignmentIconMap[option.value] && (
              <RemixIcon name={alignmentIconMap[option.value]} />
            )}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
