import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { List, Popover, Tabs } from "antd";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import "./ItemPicker.sass";
import { RemixIcon } from "../Widgets";
import { getTreeIcon } from "../../utils/treeUtils";
import { camelCaseToWords } from "../../containers/WidgetPropertyPanel/utils/propertyPanelUtils";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import { useSchemas } from "../../hooks/useSchemas";

interface Item {
  icon?: React.ReactNode;
  label: string;
  value: string;
}

interface Group {
  icon?: React.ReactNode;
  label: string;
  items: Item[];
}

// interface for WidgetPicker/ActionPicker
export interface ItemPickerActionProps {
  onItemSelect: (itemValue: string) => void;
  trigger?: React.ReactNode;
}

// Widget Picker
export const WidgetItemPicker: React.FC<ItemPickerActionProps> = ({
  onItemSelect,
  trigger,
}) => {
  const { docTreeStore } = useYamlDoc();
  return (
    <ItemPicker
      onItemSelect={onItemSelect}
      trigger={
        trigger ?? (
          <div className={"widget-picker-trigger-label-placeholder"}>
            <RemixIcon name={"layout-masonry-line"} style={{ fontSize: 18 }} />
            Select a widget
          </div>
        )
      }
      groups={[
        {
          label: "PLATFORM",
          items:
            docTreeStore?.widgetList?.map((name) => {
              return {
                icon: getTreeIcon(name),
                label: camelCaseToWords(name),
                value: name,
              };
            }) ?? [],
        },
        {
          label: "CUSTOM",
          items:
            docTreeStore?.customWidgetList.map((name) => {
              return {
                icon: getTreeIcon(name),
                label: camelCaseToWords(name),
                value: name,
              };
            }) ?? [],
        },
      ]}
    />
  );
};

export const ActionItemPicker: React.FC<ItemPickerActionProps> = ({
  onItemSelect,
}) => {
  const { actionSchemaMap } = useSchemas();
  return (
    <ItemPicker
      onItemSelect={onItemSelect}
      trigger={
        <div className={"action-builder-field-placeholder"}>
          <span>
            <RemixIcon name={"slideshow-3-line"} />
          </span>
          <span> Select an Action</span>
        </div>
      }
      items={Array.from(actionSchemaMap.keys()).map((key) => {
        return {
          label: camelCaseToWords(key),
          value: key,
        };
      })}
    />
  );
};

interface ItemPickerProps extends ItemPickerActionProps {
  items?: Item[];
  groups?: Group[];
}

// Generic Item Picker
const ItemPicker: React.FC<ItemPickerProps> = ({
  trigger,
  items = [],
  groups,
  onItemSelect,
}) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusedIndex, itemRef] = useKeyboardNavigation(0);

  const handleSelect = useCallback(
    (value: string) => {
      onItemSelect(value);
      setVisible(false);
    },
    [onItemSelect],
  );

  const contentItems = useMemo(() => {
    const searchLower = search.toLowerCase();
    if (groups) {
      return groups.reduce<Item[]>((acc, group) => {
        const filteredItems = group.items.filter((item) =>
          item.label.toLowerCase().includes(searchLower),
        );
        return acc.concat(filteredItems);
      }, []);
    }
    return items.filter((item) =>
      item.label.toLowerCase().includes(searchLower),
    );
  }, [search, groups, items]);

  const tabsContent = useMemo(() => {
    // Check if there are groups, render tabs in all cases
    if (groups) {
      return (
        <Tabs
          defaultActiveKey="0"
          onChange={() => inputRef.current?.focus()}
          items={groups.map((group, index) => ({
            disabled: search.length > 0, // Disable tabs if search is active
            label: group.label,
            key: String(index),
            children: (
              <div className={"item-picker-body-content"}>
                <List
                  itemLayout="horizontal"
                  dataSource={search.length > 0 ? contentItems : group.items}
                  renderItem={(item) => (
                    <List.Item
                      key={item.value}
                      onClick={() => handleSelect(item.value)}
                      className="item-picker-menu-item"
                    >
                      <List.Item.Meta
                        title={
                          <div className={"item-picker-menu-item-content"}>
                            {item.icon && <span>{item.icon}</span>}
                            <a>{item.label}</a>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            ),
          }))}
        />
      );
    }
    // If no groups, simply render the flat list of items
    else if (items) {
      return (
        <div className={"item-picker-body-content"}>
          <List
            itemLayout="horizontal"
            dataSource={contentItems}
            renderItem={(item, index) => (
              <List.Item
                key={item.value}
                ref={index === focusedIndex ? itemRef : null}
                onClick={() => handleSelect(item.value)}
                className="item-picker-menu-item"
              >
                <List.Item.Meta title={<a>{item.label}</a>} />
              </List.Item>
            )}
          />
        </div>
      );
    }
    return null;
  }, [
    groups,
    search,
    contentItems,
    handleSelect,
    items,
    focusedIndex,
    itemRef,
  ]);

  const content = (
    <div className={"item-picker-body"}>
      <input
        type={"search"}
        ref={inputRef}
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: 8 }}
      />
      {tabsContent}
    </div>
  );

  useEffect(() => {
    if (visible) {
      setSearch("");
    }
  }, [visible]);

  return (
    <Popover
      overlayClassName="item-picker"
      open={visible}
      onOpenChange={setVisible}
      content={content}
      trigger="click"
      placement="right"
      afterOpenChange={(open) => {
        if (open) {
          inputRef.current?.focus();
        }
      }}
    >
      <div className={"item-picker-trigger"}>{trigger}</div>
    </Popover>
  );
};
