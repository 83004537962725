import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  addEnvironmentVariable,
  deleteEnvironmentVariable,
} from "../hooks/useAPIs";
import { ReactComponent as IconDelete } from "../assets/icon_delete.svg";
import { APP_CONFIG_QUERY_ID } from "../pages/AppSettings";

interface EnvProps {
  appId: string;
  isLoading: boolean;
  envVariables?: Map<string, string>;
}

const Environment: React.FC<EnvProps> = ({
  appId,
  isLoading,
  envVariables,
}) => {
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const queryClient = useQueryClient();

  const useAddEnvVariable = useMutation<
    unknown,
    unknown,
    { newKey: string; newValue: string }
  >(
    async () => {
      await addEnvironmentVariable(appId, newKey, newValue);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(APP_CONFIG_QUERY_ID),
    },
  );

  const useDeleteEnvVar = useMutation<unknown, unknown, { envKey: string }>(
    async ({ envKey }) => {
      await deleteEnvironmentVariable(appId, envKey);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(APP_CONFIG_QUERY_ID),
    },
  );

  const onAddEnvVariableSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    useAddEnvVariable.mutate({
      newKey,
      newValue,
    });
  };

  const deleteEnvVariable = async (envKey: string) => {
    useDeleteEnvVar.mutate({ envKey });
  };

  return (
    <div>
      <p className="small">
        Use env.variable_name to access them throughout your app.
        <br />
        Note that if you are referring to a variable in YAML, you need to wrap
        it inside {"${env.variable_name}"}.
      </p>
      <div>
        {envVariables &&
          !isLoading &&
          Array.from(envVariables.keys()).map((envKey) => {
            return (
              <div className="env-card" key={envKey}>
                <p>{envKey}</p>
                <p>{envVariables?.get(envKey)}</p>
                <IconDelete onClick={() => deleteEnvVariable(envKey)} />
              </div>
            );
          })}
      </div>

      {/*add new environment variable*/}
      <form onSubmit={onAddEnvVariableSubmit}>
        <div className="env-card-editable">
          <div>
            <label>Environment key</label>
            <input
              type="text"
              value={newKey}
              onChange={(event) => setNewKey(event.currentTarget.value)}
            />
          </div>
          <div>
            <label>Environment value</label>
            <input
              type="text"
              value={newValue}
              style={{ width: "400px" }}
              onChange={(event) => setNewValue(event.currentTarget.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="button__primary"
              disabled={useAddEnvVariable.isLoading || !newKey}
            >
              {(useAddEnvVariable.isIdle || useAddEnvVariable.isSuccess) && (
                <>Add</>
              )}
              {useAddEnvVariable.isLoading && <>Adding ...</>}
            </button>
          </div>
        </div>
        <span className="status" style={{ padding: "0 10px" }}>
          {useAddEnvVariable.isSuccess && <>Environment variable added !</>}
          {useAddEnvVariable.isError && <>Ooops ! We have a problem.</>}
        </span>
      </form>
    </div>
  );
};

export default Environment;
