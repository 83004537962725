import React from "react";
import { FieldProps } from "@rjsf/utils";
import { ExpressionInput } from "../components/ExpressionInput";

// a custom field to handle string and can submit onBlur or on Enter
export const StringField: React.FC<FieldProps> = (props) => {
  const { idSchema, formData, onChange, onBlur } = props;

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  const handleBlur = () => {
    onBlur(idSchema.$id, formData);
  };

  const handleEnterKey = () => {
    onBlur(idSchema.$id, formData);
  };

  return (
    <ExpressionInput
      value={formData}
      onChange={handleChange}
      onEnterKey={handleEnterKey}
      onBlur={handleBlur}
    />
  );
};
