import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatScriptName } from "../../../utils";
import { useCreateWidget } from "../../../hooks/useFirebase";
import { AuthContext } from "../../../App";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { useNavigate } from "react-router-dom";

const ScriptCreateModal = () => {
  const navigate = useNavigate();
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const scriptNameInputRef = createRef<HTMLInputElement>();
  const [scriptNameInput, setScriptNameInput] = useState("");

  const widget = useMemo(
    () => ({
      id: "",
      name: scriptNameInput,
      content: "",
      isArchived: false,
      isRoot: false,
    }),
    [scriptNameInput],
  );

  const createScriptQuery = useCreateWidget(
    currentUser,
    app.id,
    widget,
    "internal_script",
  );

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const formattedName = formatScriptName(event.currentTarget.value);
    setScriptNameInput(formattedName);
  };

  const handleCreateScript = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (app.internalScripts?.find((widget) => widget.name === scriptNameInput))
      alert(`Script name ${scriptNameInput} already exist`);
    else createScriptQuery.mutate();
  };

  useEffect(() => {
    if (createScriptQuery.isSuccess) {
      const newScriptId = createScriptQuery.data?.insert_screen.returning[0].id;
      navigate(`/app/${app.id}/script/${newScriptId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, createScriptQuery.status]);

  return (
    <React.Fragment>
      <form className="screen-create-modal" onSubmit={handleCreateScript}>
        <label>Widget name</label>
        <input
          type="text"
          autoFocus
          value={scriptNameInput}
          placeholder="Enter script name"
          onChange={screenNameChange}
          ref={scriptNameInputRef}
        />

        <button
          className="button__primary"
          type="submit"
          disabled={!scriptNameInput || createScriptQuery.isLoading}
        >
          {createScriptQuery.isIdle && <>Create script</>}
          {createScriptQuery.isLoading && <>Processing...</>}
        </button>
      </form>
    </React.Fragment>
  );
};

export default ScriptCreateModal;
