import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import ScreenDraftModal from "./ScreenDraftModal";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

interface ScreenDraftProps {
  screen: EnsembleScreenData;
  displayDraftModal: boolean;
  setDraftTrigger: any;
}

const ScreenDraft: React.FC<ScreenDraftProps> = ({
  screen,
  displayDraftModal,
  setDraftTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setDraftTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setDraftTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayDraftModal}
      onHide={onCancel}
      headerText={`Draft ${screen.name}?`}
      modalContent={
        <ScreenDraftModal
          screen={screen}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScreenDraft;
