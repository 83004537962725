import React, { useEffect, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import "./AlignmentField.sass";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { camelCaseToWords } from "../utils/propertyPanelUtils";

// draw 9 boxes to represent the 9 alignment options
export const AlignmentField: React.FC<FieldProps> = ({
  schema,
  formData,
  onChange,
  formContext,
}) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const options: string[] = schema.enum?.map((value) => value as string) ?? [];

  const handleOnClick = (newValue: string) => {
    // note that we can unselect to remove the alignment attribute
    onChange(newValue !== formData ? newValue : undefined);
    setHasPendingChanges(true);
  };

  // request form submission after changes have propagated
  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  // position the tooltip outward from the cell so it doesn't cover the click area
  const positionTooltip = (option: string): TooltipPlacement | undefined => {
    switch (option) {
      case "center":
        return "top";
      case "topCenter":
        return "top";
      case "bottomCenter":
        return "bottom";
      case "topLeft":
      case "centerLeft":
      case "bottomLeft":
        return "left";
      case "topRight":
      case "centerRight":
      case "bottomRight":
        return "right";
    }
  };

  return (
    <div className="alignment-grid">
      {options.map((option) => (
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={camelCaseToWords(option)}
          placement={positionTooltip(option)}
          key={option}
        >
          <div
            className={`alignment-cell ${
              formData === option ? "selected" : ""
            }`}
            onClick={() => handleOnClick(option)}
          >
            <div className="alignment-cell-dot" />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
