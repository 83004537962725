import React, { useEffect, useContext, MouseEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";

// APIs
import { useUpdateApp } from "../hooks/useAPIs";

// Interfaces
import { AppData } from "../models/model";

interface AppVisibilityModalProps {
  app: AppData;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  onSubmit: () => void;
}

const AppVisibilityModal: React.FC<AppVisibilityModalProps> = ({
  app,
  onCancel,
  onSubmit,
}) => {
  const { currentUser } = useContext(AuthContext);
  const updateAppQuery = useUpdateApp(app.id, currentUser?.id, {
    isPublic: !app.isPublic,
  });
  const navigate = useNavigate();

  // trigger update
  const updateAppVisibility = (
    event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    updateAppQuery.mutate();
  };

  // update complete
  useEffect(() => {
    if (updateAppQuery.isSuccess) {
      onSubmit();
      navigate(`/`);
    }
  }, [updateAppQuery.isSuccess, onSubmit, navigate]);

  return (
    <form className="app-create-modal" onSubmit={updateAppVisibility}>
      <button
        type="submit"
        onClick={updateAppVisibility}
        className="button__primary"
      >
        {updateAppQuery.isIdle
          ? app.isPublic
            ? "Private app"
            : "Public app"
          : updateAppQuery.isLoading
          ? "Processing..."
          : null}
      </button>
      <button
        className="button__link"
        onClick={(event: MouseEvent<HTMLButtonElement>) => onCancel(event)}
      >
        Cancel
      </button>
    </form>
  );
};

export default AppVisibilityModal;
