import React, { useState } from "react";
import "./ErrorWrapper.sass"; // Assume your tooltip styles are defined here

// a wrapper around the widget to show RJSF errors
export const ErrorWrapper: React.FC<{
  children: React.ReactNode;
  errors?: string[];
}> = ({ children, errors }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const errorText = () => {
    return errors?.join("\n") ?? "";
  };

  return (
    <div
      className={`property-panel-widget-error-wrapper ${
        errorText() ? "property-panel-widget-error" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {errorText() && isHovered && (
        <div className={"property-panel-widget-error-tooltip"}>
          {errorText()}
        </div>
      )}
    </div>
  );
};
