import { CustomJSONSchema7, UiType } from "./propertyPanelSchemaUtils";
import { path } from "ramda";

export const camelCaseToWords = (str: string) => {
  const words = str.split(/(?=[A-Z])(?<![A-Z])/);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// convert camelCase to words, but first character is lowercase (e.g. 'on Response')
export const camelCaseToWordsExceptFirstLetter = (str: string) => {
  const result = camelCaseToWords(str);
  return result.length > 0
    ? result.charAt(0).toLowerCase() + result.slice(1)
    : "";
};

export const getDataPreview = (
  schema: CustomJSONSchema7,
  formData: any,
): { short: string | undefined; long: string | undefined } | undefined => {
  if (formData && typeof formData === "object") {
    const short =
      schema.previewShortKey && prettyPrint(formData[schema.previewShortKey]);
    const long =
      schema.previewLongKey && prettyPrint(formData[schema.previewLongKey]);
    return { short, long };
  }
  return undefined;
};

const prettyPrint = (data: any): string | undefined => {
  if (data) {
    if (Array.isArray(data)) {
      return data.join(", ");
    } else if (typeof data === "object" && Object.keys(data).length > 0) {
      return Object.entries(data)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
    } else {
      return String(data);
    }
  }
};

// mapping of Flutter's color names to their hex values
// https://api.flutter.dev/flutter/material/Colors-class.html
// These are nice colors so use these for HTML too
export const defaultPresets = {
  transparent: "rgba(0, 0, 0, 0)",
  black: "#000000",
  blue: "#2196F3",
  white: "#FFFFFF",
  red: "#F44336",
  grey: "#9E9E9E",
  teal: "#009688",
  amber: "#FFC107",
  pink: "#E91E63",
  purple: "#9C27B0",
  yellow: "#FFEB3B",
  green: "#4CAF50",
  brown: "#795548",
  cyan: "#00BCD4",
  indigo: "#3F51B5",
  lime: "#CDDC39",
  orange: "#FF9800",
};

export const isTransparent = (hex: string): boolean => {
  if (hex.length === 8) {
    return hex.substring(6) === "00";
  }
  return false;
};

export const getColorName = (color: string | undefined): string | undefined => {
  if (color && color in defaultPresets) {
    return color;
  }
  // if starts with FF, exclude it
  if (color && color.toLowerCase().startsWith("0xff")) {
    return `${color.substring(0, 2)}${color.substring(4).toUpperCase()}`;
  }
  return color;
};

// convert our Color (e.g. blue, 0xFF343434) to HTML's hex #343434
export const fromColorToHex = (
  color: string | undefined,
  isHtml?: boolean,
): string | undefined => {
  if (color) {
    if (color in defaultPresets) {
      return defaultPresets[color as keyof typeof defaultPresets];
    }

    if (isHtml) {
      return color;
    }

    // remove 0x prefix
    const hex = color.substring(2).toUpperCase();

    if (/^[0-9A-F]+$/i.test(hex)) {
      if (hex.length === 8) {
        const alpha = hex.substring(0, 2);
        return `#${hex.substring(2)}${alpha === "FF" ? "" : alpha}`;
      } else if (hex.length === 6) {
        return `#${hex}`;
      }
    }
  }
};

// convert HTML's hex #343434 to 0xFF343434 (first 2 are alpha)
export const fromHexToColor = (
  input: string | undefined,
  isHtml?: boolean,
): string | undefined => {
  if (input) {
    input = input.startsWith("#") ? input : `#${input}`;

    // named color
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const namedColor = Object.entries(defaultPresets).find(([_, value]) => {
      return input!.toUpperCase() === value;
    });
    if (namedColor && namedColor.length > 0) return namedColor[0];

    if (/^#[0-9A-F]+$/i.test(input)) {
      // strip out the # and uppercase it
      const hex = (
        input.startsWith("#") ? input.substring(1) : input
      ).toUpperCase();

      // invalid hex code
      if (hex.length !== 3 && hex.length !== 6 && hex.length !== 8) return;

      if (isHtml) {
        return input;
      }

      // hex can have just 3 characters
      if (hex.length === 3) {
        const [r, g, b] = hex.split("");
        return `0xFF${r}${r}${g}${g}${b}${b}`;
      }

      if (hex.length === 8) {
        return `0x${hex.substring(6)}${hex.substring(0, 6)}`;
      } else if (hex.length === 6) {
        return `0xFF${hex.substring(0)}`;
      }
    }
  }
};

// whether this widget can toggle between Expression and its native UI
export const canToggleExpression = (schema: CustomJSONSchema7) => {
  if (
    schema.type === "object" ||
    schema.type === "array" ||
    // special widget/action builder type should not have expression
    schema.uiType === UiType.widget ||
    schema.uiType === UiType.action
  ) {
    return false;
  }

  if (schema.type === "string") {
    // Dropdown (oneOf/enum) should have expression.
    // string type without any special uiType means it's a text input, which is always in expression mode
    if (!schema.oneOf && !schema.enum && !schema.uiType) {
      return false;
    }
  }

  return true;
};

// whether this string contains an expression
export const hasExpression = (input: string) => {
  return /\$\{[^{}]*}/.test(input);
};

// given the RJSF form data and the property path, traverse and return the data.
// Note that propertyPath contains an initial period.
export const getFormDataByPath = (formData: unknown, propertyPath: string) => {
  if (!formData || !propertyPath) return undefined;
  propertyPath.startsWith(".") && (propertyPath = propertyPath.substring(1));

  const pathSegments = propertyPath.split(".").flatMap((segment) => {
    // Extract array indices and convert them to numbers
    const match = segment.match(/^(\w+)\[(\d+)]$/);
    return match ? [match[1], parseInt(match[2], 10)] : [segment];
  });

  return path(pathSegments, formData);
};
