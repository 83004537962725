/* eslint-disable prettier/prettier */
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import React, { useEffect, useRef } from "react";

export type ChangeHandler = (
  value: string,
  event: monaco.editor.IModelContentChangedEvent,
  model: monaco.editor.ITextModel | null
) => void;

export type DiffEditorProps = {
  labelValue?: string;
  value: string;
  originalValue: string;
  onChange: ChangeHandler;
};
export const DiffEditor: React.FC<DiffEditorProps> = ({
  labelValue = "Original",
  value,
  originalValue,
  onChange,
}) => {
  const containerElement = useRef<HTMLDivElement | null>(null);

  const editor = useRef<monaco.editor.IStandaloneDiffEditor | null>(null);

  const _changeSubscription = useRef<monaco.IDisposable | null>(null);

  const __prevent_trigger_change_event = useRef<boolean | null>(null);

  // Init monaco editor
  useEffect(() => {
    if (containerElement.current) {
      const newEditor = monaco.editor.createDiffEditor(
        containerElement.current,
        {
          automaticLayout: true,
          theme: "vs-dark",
          smoothScrolling: true,
          minimap: {
            enabled: false,
          },
          quickSuggestions: {
            other: true,
            strings: true,
          },
          scrollbar: {
            horizontal: "hidden",
          },
          wordWrap: "on",
          fontSize: 14,
          ignoreTrimWhitespace: false
        }
      );
      editor.current = newEditor;
      const originalModel = monaco.editor.createModel(originalValue);
      const modifiedModel = monaco.editor.createModel(value);
      editor.current.setModel({
        original: originalModel,
        modified: modifiedModel,
      });

      _changeSubscription.current = modifiedModel.onDidChangeContent(
        (event) => {
          onChange(modifiedModel.getValue(), event, modifiedModel);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update value
  useEffect(() => {
    const diffModel = editor.current?.getModel();
    if (editor.current && diffModel) {
      const { original: originalEditor } = diffModel;
      if (originalValue !== originalEditor.getValue()) {
        originalEditor.setValue(originalValue);
      }
    }
  }, [originalValue]);

  useEffect(() => {
    const diffModel = editor.current?.getModel();
    if (editor.current && diffModel) {
      const { modified } = diffModel;
      const curValue = modified.getValue();
      if (value === curValue) {
        return;
      }
      __prevent_trigger_change_event.current = true;
      editor.current.getModifiedEditor().executeEdits("", [
        {
          range: modified.getFullModelRange(),
          text: value,
          forceMoveMarkers: curValue.length === 0,
        },
      ]);
      editor.current.getModifiedEditor().pushUndoStop();
      __prevent_trigger_change_event.current = false;
    }
  }, [onChange, originalValue, value]);

  // Clean up editor, model, and event handlers
  useEffect(
    () => () => {
      if (editor.current) {
        editor.current?.dispose();
        const model = editor.current.getModel();
        if (model) {
          model.modified?.dispose();
          model.original?.dispose();
        }
      }
      if (_changeSubscription.current) {
        _changeSubscription.current?.dispose();
      }
    },
    []
  );

  return (
    <div ref={containerElement} className="monaco-base">
      <div className="title-bar">
        <span className="title">{labelValue}</span>
        <span className="title current-title">Current</span>
      </div>
    </div>
  );
};
