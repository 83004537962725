import React, { useContext, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import { AuthContext, AppDataContext } from "../App";

// APIs
import { getApp } from "../hooks/useAPIs";

// Interfaces
import { AccessType, AppCategory, AppData } from "../models/model";

interface IEnsembleAppContext {
  app: AppData;
  isAppReadOnly: boolean;
  isDemoApp: boolean;
  // setIsAppUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppPagesWrapper: React.FC = () => {
  const params = useParams();
  const appId = params.app_id;

  const { currentUser } = useContext(AuthContext);
  const {
    app,
    setAppData,
    isPermissionChecked,
    setIsPermissionChecked,
    isDemoApp,
    setIsDemoApp,
    isAppReadOnly,
    setIsAppReadOnly,
    resetAppData,
  } = useContext(AppDataContext);
  const appQuery = useQuery(["app", appId], () =>
    getApp(appId!, currentUser?.id ?? "")
  );

  const navigate = useNavigate();

  useEffect(() => {
    // app doesn't exist
    if (appQuery.isError || (appQuery.isSuccess && !appQuery.data)) {
      navigate(`/404?app_id=${appId}`);
    }

    if (!appQuery.isLoading && appQuery.data) {
      if (appQuery.data.screens)
        appQuery.data.screens.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      setAppData(appQuery.data);
    }
  }, [appQuery]);

  // check app authorization
  useEffect(() => {
    if (app) {
      if (app.category === AppCategory.Demo) setIsDemoApp(true);
      else setIsDemoApp(false);

      setIsAppReadOnly(
        app.accessType === AccessType.read ||
          (app.isPublic &&
            app.accessType !== AccessType.write &&
            app.accessType !== AccessType.owner)
      );

      setIsPermissionChecked(true);
    }
    return () => resetAppData();
  }, [app]);

  return (
    <div className="page-ct" key={appId}>
      {(appQuery.isLoading || !isPermissionChecked) && (
        <div className="center">
          <p className="small"> loading app... </p>
        </div>
      )}

      {app && isPermissionChecked && (
        <Outlet context={{ app, isAppReadOnly, isDemoApp }} />
      )}
    </div>
  );
};

export default AppPagesWrapper;

export function useAppContext() {
  return useOutletContext<IEnsembleAppContext>();
}
