import { isMap, isPair, Pair, Scalar, YAMLMap } from "yaml";
/**
 * When working with nested Tree/PropertyPanel for widgets/actions, we only
 * have the formData which knows nothing about its ancestors, which might not
 * have exist yet (we only update the model when something is created). For this
 * reason we need the entire paths to the root selected widget to build the
 * document tree to our widget/action.
 */
export class RootWidgetContext {
  constructor(formContext: any, idPath: string) {
    // root can be "Button" or "Button: ..."
    this.rootNode = formContext.node;
    // the ID looks like "root_backgroundImage_fallback" or "root_onTap_invokeAPI"
    this.paths = idPath.split("_").slice(1);
    this.rootPrefix =
      formContext.activeCategory === "Styles"
        ? formContext.activeCategory.toLowerCase()
        : null;
  }
  readonly rootNode: Scalar | Pair;
  // the entire paths from the root selected widget to the current RJSF Field/widget
  readonly paths: string[];
  // prefix to use (e.g. "styles" or "actions") if we are in a nested category
  readonly rootPrefix: string | null;

  // traverse the paths from the root and return leaf node
  getLeafNode(): YAMLMap | null {
    let node = this._getStartingMap();
    if (!node) return null;

    for (const path of this.paths) {
      const foundNode = node.get(path);
      if (!foundNode || !isMap(foundNode)) return null;
      node = foundNode as YAMLMap;
    }
    return node;
  }

  getLeafNodePair(): Pair | null {
    let node = this._getStartingMap();
    if (!node) return null;

    let foundPair;
    for (const path of this.paths) {
      foundPair = node.items.find(
        (item) => (item.key as Scalar)?.value === path,
      );
      if (foundPair) {
        node = foundPair.value as YAMLMap;
      }
    }
    return foundPair ?? null;
  }

  getOrCreateLeafNodePair(): Pair<Scalar, YAMLMap> {
    // if the root node's value is not a Map to start out, make it so (e.g. Text:)
    if (!isMap(this.rootNode.value)) {
      this.rootNode.value = new YAMLMap();
    }
    let node: YAMLMap = this.rootNode.value as YAMLMap;
    // traverse and create prefix if applicable
    if (this.rootPrefix) {
      if (!isMap(node.get(this.rootPrefix))) {
        node.set(new Scalar(this.rootPrefix), new YAMLMap());
      }
      node = node.get(this.rootPrefix) as YAMLMap;
    }
    // now iterate through the paths, create node if necessary
    let foundPair: Pair<Scalar, YAMLMap> = this.rootNode as Pair<
      Scalar,
      YAMLMap
    >;
    for (const path of this.paths) {
      let found = node.items.find(
        (item) => (item.key as Scalar)?.value === path,
      );
      // create if missing
      if (!found || !isMap(found.value)) {
        found = new Pair(new Scalar(path), new YAMLMap());
        node.add(found, true);
      }
      foundPair = found as Pair<Scalar, YAMLMap>;
      node = foundPair.value as YAMLMap;
    }
    return foundPair;
  }

  /**
   * Returns the starting Map that can be used to traverse the paths.
   * This will also account for the prefix.
   */
  private _getStartingMap(): YAMLMap | null {
    if (isPair(this.rootNode) && this.rootNode.value instanceof YAMLMap) {
      if (this.rootPrefix) {
        const node = this.rootNode.value.get(this.rootPrefix);
        return isMap(node) ? node : null;
      } else {
        return this.rootNode.value;
      }
    }
    return null;
  }

  // We use bracket [] notation to designate array index in ArrayField
  private _isArrayPath(path: string): boolean {
    return path.startsWith("[") && path.endsWith("]");
  }
  private _getArrayIndex(path: string): number {
    if (!this._isArrayPath(path)) return NaN;
    return parseInt(path.slice(1, -1));
  }

  // get the property name, which should be the last item in the path
  getPropertyName(): string | null {
    return this.paths.length > 0 ? this.paths[this.paths.length - 1] : null;
  }
}
