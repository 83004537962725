import { DataNode } from "rc-tree/lib/interface";
import { Pair, Scalar, YAMLMap } from "yaml";
import { NodeAction } from "../../hooks/useYamlDoc";

// our TreeNode with additional YAML Node reference
export type TreeNode = DataNode & {
  ref: NodeRef;
  payload?: TreeItemPayload;
};

// a node reference with its parent
export type NodeRef = {
  node: Pair | Scalar;
  parent: YAMLMap | null;
  category?: NodeCategory;
  action?: NodeAction;
};

export enum NodeCategory {
  ViewRoot = "viewRoot",
  ViewGroupRoot = "viewGroupRoot",
  WidgetRoot = "widgetRoot",

  CustomWidget = "customWidget",
  API = "api",
  CodeBlock = "codeBlock",
}

export interface TreeItemPayload {}

// payload for setting the widget map in the tree
export class TreeItemWidgetPayload implements TreeItemPayload {
  constructor(
    readonly property: string,
    readonly label: string,
  ) {}
}
