import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactTooltip from "react-tooltip";

import WidgetActionsMenu from "../ActionMenus/WidgetActionsMenu";

import { EnsembleWidgetData } from "../../config/interfaces";

interface WidgetCardProps {
  widget: EnsembleWidgetData;
  app_id: string;
  isReadOnly: boolean;
}

const WidgetCard: React.FC<WidgetCardProps> = ({
  widget,
  app_id,
  isReadOnly,
}) => {
  const [image, setImage] = useState("");
  const storage = getStorage();

  useEffect(() => {
    getDownloadURL(ref(storage, `widgets/${widget.id}.jpg`))
      .then((url) => {
        setImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storage, widget.id]);

  return (
    <Link to={`/app/${app_id}/widget/${widget.id}`}>
      <div className="screen-card">
        {image ? (
          <div
            className="screen-card__image"
            style={{ backgroundImage: `url(${image}` }}
          ></div>
        ) : (
          <div className="screen-card__code">
            <pre data-text={`${widget.content}`} />
          </div>
        )}

        <div className="screen-card__content">
          <h4>{widget.name}</h4>
          {/* screen actions */}
          {!isReadOnly && (
            <WidgetActionsMenu widget={widget} isReadOnly={isReadOnly} />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default WidgetCard;
