// store for accessing the Code/Visual editor functions
import { create } from "zustand";

export interface EditorStore {
  isSourceView: boolean;
  setIsSourceView: (isSourceView: boolean) => void;
}

export const useEditor = create<EditorStore>((set) => ({
  isSourceView: true,
  setIsSourceView: (isSourceView: boolean) => set(() => ({ isSourceView })),
}));
