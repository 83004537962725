import React from 'react';
import {ReactComponent as Ios} from "../assets/app-store.svg";
import {ReactComponent as Android} from "../assets/google-play.svg";

const imageLinks = {
  Ios,
  Android
};

interface IProps{
  store:'Ios'|'Android',
  url:string
}

const MobileStoreButton=(props:IProps)=> {
    const {store, url} = props;

    const Store=imageLinks[store];

    return (
      <Store style={{cursor:'pointer',margin:'0.5rem 0.5rem 0 0'}} onClick={()=>window.open(url,'_blank')}/>
    );
  }

export default MobileStoreButton;
