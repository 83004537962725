import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../App";

enum FeatureFlag {
  // If true, enables GPT features
  aiEnabled = "aiEnabled",
  // If true, enables editing screens via property panel
  propertyPanelEnabled = "propertyPanelEnabled",
  // If true, disables automatically updating preview on changes
  instantPreviewDisabled = "instantPreviewDisabled",
  // If true, enable selection of screen from templates
  screenTemplateEnabled = "screenTemplateEnabled",
  // If true, enable editing of ensemble-react apps
  reactEnabeld = "reactEnabled",
}

export type FeatureFlags = Record<FeatureFlag, boolean>;

const defaultValues = {
  aiEnabled: false,
  propertyPanelEnabled: true,
  instantPreviewDisabled: true,
  screenTemplateEnabled: true,
  reactEnabled: true,
};

export const FeatureFlagContext = createContext<FeatureFlags>(defaultValues);

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useContext(AuthContext);

  const featureFlags = useMemo<FeatureFlags>(() => {
    const flags = { ...defaultValues };

    for (const flag of Object.values(FeatureFlag)) {
      const maybeFlag = searchParams.get(flag);
      if (maybeFlag) {
        flags[flag] = maybeFlag === "true";
      }
    }
    return flags;
    // Only determine feature flags on first render OR user login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    for (const flag in featureFlags) {
      const value = featureFlags[flag as FeatureFlag];
      if (value !== defaultValues[flag as FeatureFlag]) {
        searchParams.set(flag, String(value));
      }
    }
    setSearchParams(searchParams, { replace: true });
  }, [featureFlags, searchParams, setSearchParams]);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => useContext(FeatureFlagContext);
