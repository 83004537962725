import React, { useEffect, useContext } from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import { AuthContext } from "../App";

// APIs
import { useUpdateScreen } from "../hooks/useAPIs";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

interface ScreenDraftModalProps {
  screen: EnsembleScreenData;
  onCancel: () => void;
  onSubmit: () => void;
}

const ScreenDraftModal: React.FC<ScreenDraftModalProps> = ({
  screen,
  onCancel,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const screenChanges = { isDraft: !screen.isDraft };
  const updateScreenQuery = useUpdateScreen(currentUser, app.id, screen.id);

  // trigger update
  const archiveScreen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateScreenQuery.mutate(screenChanges);
  };

  // update complete
  useEffect(() => {
    if (updateScreenQuery.isSuccess) onSubmit();
  }, [updateScreenQuery.status]);

  return (
    <div className="app-create-modal">
      <button onClick={archiveScreen} className="button__primary">
        {updateScreenQuery.isIdle && <>{
          !screen.isDraft ? `Draft screen` : `Remove Draft`
        }</>}
        {updateScreenQuery.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default ScreenDraftModal;
