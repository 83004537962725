import React, { useEffect, useRef, useState } from "react";
import * as monaco from "monaco-editor";

const editorConfig: monaco.editor.IStandaloneEditorConstructionOptions = {
  automaticLayout: true,
  theme: "vs-dark",
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
  },
  wordWrap: "off",
  fontSize: 14,
  tabSize: 2,
  insertSpaces: true,
};

interface YamlEditorProps {
  editorContent?: string;
  setEditorContent: (content: string) => void;
  readOnly?: boolean;
  onSaveRequest?: (newContent: string) => void;
}

export const YamlEditor: React.FC<YamlEditorProps> = ({
  editorContent,
  setEditorContent,
  readOnly,
  onSaveRequest,
}) => {
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] =
    useState<monaco.editor.IStandaloneCodeEditor | null>(null);

  // editor only created once so it needs access to the latest callback
  const onSaveRequestRef = useRef(onSaveRequest);
  onSaveRequestRef.current = onSaveRequest;

  // create the Editor instance once
  useEffect(() => {
    if (editorRef.current) {
      const editor = monaco.editor.create(editorRef.current, {
        ...editorConfig,
        language: "yaml",
        value: editorContent,
        readOnly: readOnly,
      });
      setEditorInstance(editor);

      if (!readOnly && onSaveRequest) {
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
          onSaveRequestRef.current!(editor.getValue());
        });
      }

      editor.onDidChangeModelContent(() => {
        setEditorContent(editor.getValue());
      });

      return () => {
        editor.dispose();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editorInstance && editorContent !== editorInstance.getValue()) {
      editorInstance.setValue(editorContent || "");
    }
  }, [editorContent, editorInstance]);

  return (
    <div className="yaml-editor">
      <div
        ref={editorRef}
        style={{
          height: window.innerHeight - 150 + "px",
          width: window.innerWidth - 150 + "px",
        }}
      />
    </div>
  );
};
