import React, { useEffect, useState } from "react";
import { ReactComponent as IconQuestion } from "../assets/icon_question.svg";
import ReactTooltip from "react-tooltip";
import { AppConfigData } from "../models/model";
import { useMutation, useQueryClient } from "react-query";
import { updateAppConfig } from "../hooks/useAPIs";
import { APP_CONFIG_QUERY_ID } from "../pages/AppSettings";

interface AppConfigProps {
  appId: string;
  isLoading: boolean;
  appConfig?: AppConfigData;
}

/// App Configuration (BaseURl, ..)
const AppConfig: React.FC<AppConfigProps> = ({
  appId,
  isLoading,
  appConfig,
}) => {
  const [baseUrl, setBaseUrl] = useState(appConfig?.baseUrl ?? "");
  const [useBrowserUrl, setUseBrowserUrl] = useState(appConfig?.useBrowserUrl);
  const queryClient = useQueryClient();

  const useUpdateAppConfig = useMutation(
    async () => {
      await updateAppConfig(appId, baseUrl, useBrowserUrl);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(APP_CONFIG_QUERY_ID),
    },
  );

  useEffect(() => {
    setBaseUrl(appConfig?.baseUrl ?? "");
    setUseBrowserUrl(appConfig?.useBrowserUrl);
  }, [isLoading, appConfig]);

  const onAppConfigSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    useUpdateAppConfig.mutate();
  };

  const baseUrlTip = `
    <p>Set your app's base URL such that your web server and APIs can reference the same domain, thus avoiding the cross-domain issue on Web apps.</p>
    <p>This parameter can be referenced anywhere in your pages using <span style="font-weight: 500">{app.baseUrl}</span>.</p>
    <br/>`;
  const useWebBrowserUrlTip = `
    <p>For Web app we can read the base URL from the browser. If this is checked, ignore the Base URL above and use what the web browsers return. This is recommended if your app can be accessed with more than one URLs.</p>
    <p>This flag is not applicable for non-Web apps.</p>`;

  return (
    <form className="app-settings-form" onSubmit={onAppConfigSubmit}>
      <div>
        <label>App&apos;s Base URL</label>
        <a data-tip={baseUrlTip} data-for="formTip" data-event="click focus">
          <IconQuestion />
        </a>
      </div>
      <div>
        <input
          type="text"
          placeholder="https://..."
          value={isLoading ? "" : baseUrl}
          onChange={(event) => setBaseUrl(event.currentTarget.value)}
        />
      </div>
      <br />

      <div>
        <label>Use Browser URL</label>
        <a
          data-tip={useWebBrowserUrlTip}
          data-for="formTip"
          data-event="click focus"
        >
          <IconQuestion />
        </a>
      </div>
      <div>
        <input
          type="checkbox"
          checked={isLoading ? false : useBrowserUrl}
          onChange={(event) => setUseBrowserUrl(event.target.checked)}
        />
      </div>
      <br />

      <p>
        <button
          type="submit"
          className="button__primary"
          disabled={useUpdateAppConfig.isLoading}
        >
          {(useUpdateAppConfig.isIdle || useUpdateAppConfig.isSuccess) && (
            <>Update</>
          )}
          {useUpdateAppConfig.isLoading && <>Updating ...</>}
        </button>
        <span className="status" style={{ padding: "0 10px" }}>
          {useUpdateAppConfig.isSuccess && <>Settings updated successfully.</>}
          {useUpdateAppConfig.isError && <>Ooops ! We have a problem.</>}
        </span>
      </p>

      <ReactTooltip
        id="formTip"
        className="form-label-tip"
        globalEventOff="click"
        html={true}
        getContent={(content) => `${content}`}
      />
    </form>
  );
};

export default AppConfig;
