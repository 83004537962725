import { ErrorListProps } from "@rjsf/utils";
import "./ErrorListTemplate.sass";

// render the list of errors
export const ErrorListTemplate = (props: ErrorListProps) => {
  const { errors } = props;
  return (
    <div className={"error-list-template"}>
      <ul>
        {errors.map((error) => (
          <li key={error.stack} className={"error-list-template-item"}>
            {error.stack}
          </li>
        ))}
      </ul>
    </div>
  );
};
