import { FieldProps } from "@rjsf/utils";
import React from "react";
import { IntegerField } from "./IntegerField";
import { CustomNumberField } from "./CustomNumberField";

export const NumberField: React.FC<FieldProps> = (props) => {
  const { schema } = props;
  if (schema.type === "integer") {
    return <IntegerField {...props} />;
  } else {
    return <CustomNumberField {...props} />;
  }
};
