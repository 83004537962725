import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "antd";
import { take } from "ramda";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useParams } from "react-router-dom";

import "./EditorV2.sass";
import { EditorHeader } from "../EditorHeader/EditorHeader";
import { useYamlDoc, YAMLDocNode } from "../../hooks/useYamlDoc";
import EditorPreview from "../../components/EditorPreview";
import { WidgetPropertyPanel } from "../WidgetPropertyPanel/WidgetPropertyPanel";
import {
  EnsembleArtifactHistoryData,
  EnsembleScreenData,
  KeyValuePair,
} from "../../config/interfaces";
import { SchemaPlatform, useSchemas } from "../../hooks/useSchemas";
import { CST, isPair, isScalar, Pair, Scalar, stringify, visit } from "yaml";
import {
  ChangeHandler,
  CursorPositionChangeHandler,
  MonacoEditor,
} from "../../components/Monaco/MonacoEditor";
import { DiffEditor } from "../../components/Monaco/DiffEditor";
import {
  getArtifactPreviewUrl,
  getDateTime,
  SESSION_FORCED_LOCALE,
} from "../../utils";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import { JavascriptEditor } from "../JavascriptEditor/JavascriptEditor";
import { Modal as ModalDialog } from "../../components/Modal";
import { AppData } from "../../models/model";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
// import ActionTabs from "../../components/ActionTabs";
import DebugPanel from "../DebugPanel";
import { NodeRef } from "../NavigatorPanel/TreeNode";
import { useResetRootScreen } from "../../hooks/useAPIs";
import { NavigatorPanel } from "../NavigatorPanel/NavigatorPanel";
import { configureYamlSchema } from "../../config/monacoYamlConfig";
import { ArtifactType } from "../../pages/EditorPage";
import IconEditor from "../../assets/js-editor.png";
import { useEditor } from "../../hooks/useEditor";
import { FormRefContext } from "../../models/FormRefContext";

const cmd = { type: "reload" };

export type EditorV2Props = {
  isReadOnly: boolean;
  artifactType: ArtifactType;
  app: AppData;
  artifact: EnsembleScreenData;
  onSave: (content: string) => void;
  onScreenCopy: () => void;
};

export const EditorV2: React.FC<EditorV2Props> = ({
  isReadOnly,
  artifact,
  artifactType,
  onSave,
  onScreenCopy,
  app,
}) => {
  const artifactId = artifact.id;
  const {
    doc,
    setDoc,
    selectedNode,
    selectedNodeParent,
    nonEditorChanges,
    updateDocContent,
    updateNode,
    setSelectedNode,
    setIsPropertyPanelOpen,
  } = useYamlDoc();
  const params = useParams();
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const contentWidgetRef = useRef<monaco.editor.IContentWidget[]>([]);

  const [editorContent, setEditorContent] = useState<string>(
    artifact.content ?? "",
  );
  const [selectedHistory, setSelectedHistory] =
    useState<EnsembleArtifactHistoryData>();

  const [isPreviewDisplayed, setIsPreviewDisplayed] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { isSourceView, setIsSourceView } = useEditor();
  const [artifactInputs, setArtifactInputs] = useState<KeyValuePair[]>([]);

  const { instantPreviewDisabled, reactEnabled } = useFeatureFlag();
  const resetRootScreenQuery = useResetRootScreen(app.id, params.screen_id!);

  const { loadSchema } = useSchemas();
  useEffect(() => {
    loadSchema(app.isReact ? SchemaPlatform.web : SchemaPlatform.native);
  }, [app.isReact, loadSchema]);

  const schemaStore = useSchemas();
  useEffect(() => {
    configureYamlSchema(
      artifactType === "widget"
        ? schemaStore.editorWidgetSchema
        : schemaStore.editorScreenSchema,
    );
  }, [
    artifactType,
    schemaStore.editorScreenSchema,
    schemaStore.editorWidgetSchema,
  ]);

  useEffect(() => {
    reloadPreview(editorContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifact?.id]);

  // save the editor content to local storage and trigger Preview refresh
  // Runtime Preview will render the content from local storage
  // TODO: this does not work well in multiple tabs.
  const reloadPreview = useCallback(
    (editorContent: string) => {
      const forcedLocale = sessionStorage.getItem(SESSION_FORCED_LOCALE);
      const content = JSON.stringify(editorContent);
      window.localStorage.setItem("flutter." + artifact?.id, content);
      iframeRef.current?.contentWindow?.postMessage(
        JSON.stringify({
          ...cmd,
          bypassCache: false,
          screen: { id: artifact.id, content },
          ...(forcedLocale && { forcedLocale: forcedLocale }),
        }),
        "*",
      );
    },
    [artifact?.id],
  );

  const docRef = useRef(doc);
  useEffect(() => {
    docRef.current = doc;

    // if changes are outside the Code Editor (e.g. Visual Editor)
    if (nonEditorChanges) {
      const editorContent = stringify(doc);
      setEditorContent(editorContent);

      // trigger Preview refresh on every Visual Editor changes
      reloadPreview(editorContent);
    } else if (doc?.contents?.srcToken) {
      setEditorContent(CST.stringify(doc.contents?.srcToken));
    }
  }, [doc, reloadPreview, nonEditorChanges]);

  // call when the artifact content changes (onLoad, onSave, ...)
  useEffect(() => {
    if (schemaStore.editorScreenSchema) {
      setSelectedNode(undefined, null, []);
      setDoc(artifactType, artifact.content ?? null, schemaStore);
    }
  }, [artifactType, artifact?.content, setDoc, setSelectedNode, schemaStore]);

  // triggered on every keystroke in the editor. Be careful here
  const onEditorChange = useCallback<ChangeHandler>(
    (value) => {
      setEditorContent(value);

      // TODO: this updates the tree on every keystroke. Only do on structural changes (affecting the tree)
      // updateDocContent(artifactType, value, schemaStore);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [artifactType, schemaStore, updateDocContent],
  );

  const selectedNodeRange = useMemo(() => {
    if (isScalar(selectedNode) && selectedNode.range) {
      return take(2, selectedNode.range);
    }
    if (isPair(selectedNode)) {
      // TODO: update range for nodes added in widget tree editor
      if (isScalar(selectedNode.key) && selectedNode.key.range) {
        return take(2, selectedNode.key.range);
      }
    }
  }, [selectedNode]);

  const hasUnsavedChanges = useMemo(() => {
    const normalizedEditorContent = editorContent.trim();
    const normalizedScreenContent = artifact.content?.trim();
    return (
      artifact.content != undefined &&
      normalizedEditorContent !== normalizedScreenContent
    );
  }, [editorContent, artifact.content]);

  // TODO remove this? we probably don't ever want to reload preview on every keystroke
  useEffect(() => {
    if (instantPreviewDisabled) {
      return;
    }
    reloadPreview(editorContent);
  }, [editorContent, reloadPreview, instantPreviewDisabled]);

  const onSaveCallback = useCallback(() => {
    onSave(editorContent);
    reloadPreview(editorContent);
  }, [editorContent, reloadPreview, onSave]);

  const diffValueLabel = selectedHistory
    ? `${
        selectedHistory.label ?? getDateTime(selectedHistory.updatedAt.toDate())
      } ${selectedHistory.updatedBy.name ?? ""}`
    : "Original";

  const [scalarInCursorRange, setScalarInCursorRange] = useState<Scalar | null>(
    null,
  );

  const setScalarCursorRange = useCallback((offset: number) => {
    visit(docRef.current, {
      Scalar: (key, node) => {
        if (node.range) {
          const [start, , nodeEnd] = node.range;
          if (start > offset || nodeEnd < offset) {
            return visit.SKIP;
          }
          if (start <= offset && offset <= nodeEnd) {
            if (node.value === null) {
              node.value = "";
            }
            setScalarInCursorRange(node);
          }
        }
      },
    });
  }, []);

  const onCursorPosChange = useCallback<CursorPositionChangeHandler>(
    (event, model) => {
      const offset = model?.getOffsetAt(event.position);
      if (!offset || !docRef.current) {
        return;
      }
      setScalarCursorRange(offset);
    },
    [setScalarCursorRange],
  );

  const [isJsEditorOpen, setIsJsEditorOpen] = useState(false);
  const openJsEditor = useCallback(() => {
    setIsJsEditorOpen(true);
  }, []);

  const openJsEditorFromWidget = useCallback(
    (
      editorRef: monaco.editor.IStandaloneCodeEditor,
      lineNumber: number,
      column: number,
    ) => {
      const offset = editorRef.getModel()?.getOffsetAt({
        lineNumber: lineNumber,
        column: column,
      });

      if (!offset || !docRef.current) {
        return;
      }
      console.log({
        type: "getContext",
        edl: editorContent,
        line: lineNumber, //this is the line in the yaml that the user is opening the editor on
        screenId: artifact.id,
        screenName: artifact.name,
      });
      iframeRef.current?.contentWindow?.postMessage(
        JSON.stringify({
          type: "getContext",
          edl: editorContent,
          line: lineNumber, //this is the line in the yaml that the user is opening the editor on
          screenId: artifact.id,
          screenName: artifact.name,
        }),
        "*",
      );
      setScalarCursorRange(offset);
      setIsJsEditorOpen(true);
      return;
    },
    [artifact, editorContent, setScalarCursorRange],
  );

  const onJsEditorSave = useCallback(
    (value: string) => {
      setIsJsEditorOpen(false);
      if (scalarInCursorRange) {
        scalarInCursorRange.value = value || stringify("");
        scalarInCursorRange.type = "BLOCK_LITERAL";
        setScalarInCursorRange(null);
        updateNode(scalarInCursorRange);
      }
    },
    [scalarInCursorRange, updateNode],
  );

  const handleArtifactInputs = (updatedInputs?: KeyValuePair[]) => {
    const inputs: KeyValuePair[] = updatedInputs
      ? [...updatedInputs]
      : [...artifactInputs];
    doc
      ?.toJS()
      ?.[params.screen_id ? "View" : "Widget"]?.inputs?.forEach(
        (input: string) => {
          const inputIndex = artifactInputs.findIndex(
            (value) => value.key === input,
          );
          if (inputIndex < 0) inputs.push({ key: input, value: "" });
        },
      );
    if (!inputs.length) inputs.push({ key: "", value: "" });
    setArtifactInputs(inputs);
  };

  const { validateForm, setHasFormChanges } = React.useContext(FormRefContext);

  const onNodeSelected = (ref: NodeRef | null): Promise<boolean> => {
    return new Promise((resolve) => {
      if (validateForm()) {
        setSelectedNode(ref?.node, ref?.parent ?? null, []);
        setIsPropertyPanelOpen(true);
        resolve(true);
      } else {
        // validation issues
        Modal.confirm({
          wrapClassName: "discard-changes-confirmation",
          title: "You have errors that require your attention",
          onOk: () => {
            setSelectedNode(ref?.node, ref?.parent ?? null, []);
            setIsPropertyPanelOpen(true);

            // discard existing changes
            setHasFormChanges(false);
            resolve(true);
          },
          onCancel: () => resolve(false),
          okText: "Discard Changes and Continue",
          cancelText: "I'm fixing the problem",
        });
      }
    });
  };

  // property panel expects a Pair so we are computing it here. Probably
  // should be refactored to take in the NodeRef instead.
  const selectedNodePair: YAMLDocNode | undefined = useMemo(() => {
    if (selectedNode instanceof Pair) {
      return selectedNode;
    }
    // TODO: how do we handling a Scalar e.g. "Text" without even a colon :
    if (selectedNode instanceof Scalar) {
      return selectedNode;
    }
  }, [selectedNode]);

  const setHomeScreen = () => resetRootScreenQuery.mutate();

  const contentWidget = (
    editorRef: monaco.editor.IStandaloneCodeEditor,
    lineNumber?: number,
    column?: number,
  ) => {
    const data: monaco.editor.IContentWidget = {
      getId: function () {
        return "id-" + lineNumber;
      },
      getDomNode: function () {
        const domNode = document.createElement("div");
        domNode.style.marginLeft = "20px";
        domNode.onclick = function () {
          openJsEditorFromWidget(editorRef, lineNumber || 0, column || 0);
        };
        domNode.innerHTML = `<img width="100px" height="20px" src="${IconEditor}" />`;
        return domNode;
      },
      getPosition: () => {
        return {
          position:
            lineNumber && column
              ? {
                  lineNumber,
                  column,
                }
              : null,
          preference: [
            monaco.editor.ContentWidgetPositionPreference.EXACT,
            monaco.editor.ContentWidgetPositionPreference.BELOW,
          ],
        };
      },
    };
    return data;
  };

  const onCopyPreviewLink = () => {
    const previewURL =
      window.location.origin +
      getArtifactPreviewUrl(app.id, artifactId, artifactInputs);
    navigator.clipboard.writeText(previewURL);
  };

  useEffect(() => {
    const allContent = editor?.current?.getModel()?.getLinesContent() || [];
    contentWidgetRef.current.forEach(
      (widget) => editor.current?.removeContentWidget(widget),
    );
    allContent.forEach((f, i) => {
      if (
        allContent[i - 1]?.trim() === "executeCode:" &&
        (f.trim()?.includes("body:") || f.trim()?.includes("onComplete:")) &&
        editor.current
      ) {
        const widget = contentWidget(editor.current, i + 1, f.length + 2);
        contentWidgetRef.current.push(widget);
        editor.current?.addContentWidget(widget);
      }
      if (f.trim()?.includes("Global:") && editor.current) {
        const widget = contentWidget(editor.current, i + 1, f.length + 2);
        contentWidgetRef.current.push(widget);
        editor.current?.addContentWidget(widget);
      }
    });
    return () => {
      // TODO: fix this and remove the eslint disable
      // eslint-disable-next-line react-hooks/exhaustive-deps
      contentWidgetRef.current.forEach(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        (widget) => editor.current?.removeContentWidget(widget),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="editor-page">
        <EditorHeader
          app={app}
          isReadOnly={isReadOnly}
          artifactId={artifactId}
          inputParams={artifactInputs}
          artifactType={artifactType}
          setSelectedHistory={setSelectedHistory}
          selectedHistory={selectedHistory}
          editorContent={editorContent}
          onCopyPreviewLink={onCopyPreviewLink}
          currentArtifactName={artifact?.name ?? ""}
          hasUnsavedChanges={hasUnsavedChanges}
          isPreviewDisplayed={isPreviewDisplayed}
          onSaveClick={onSaveCallback}
          onSetHomeScreen={setHomeScreen}
          createScreenCopy={onScreenCopy}
          isSourceView={isSourceView}
          onToggleSourceView={() => setIsSourceView(!isSourceView)}
          onTogglePreview={() => setIsPreviewDisplayed(!isPreviewDisplayed)}
        />
        <div className={`editor-content ${isSourceView ? "source-view" : ""}`}>
          {/*<ActionTabs />*/}
          <NavigatorPanel
            artifactType={artifactType}
            onNodeSelected={onNodeSelected}
          />
          <div className="editor-container">
            {isSourceView && (
              <MonacoEditor
                editor={editor}
                value={editorContent}
                key={"yaml-editor-v2"}
                onChange={onEditorChange}
                onCursorPosChange={onCursorPosChange}
                cursorOffsetRange={selectedNodeRange}
                onSave={!isReadOnly ? onSaveCallback : undefined}
                actions={[
                  {
                    id: "open-js-editor",
                    label: "Edit as Javascript",
                    run: openJsEditor,
                    contextMenuGroupId: "1_modification",
                  },
                ]}
              />
            )}
            {selectedHistory && isSourceView && (
              <div className="diff-view">
                <DiffEditor
                  labelValue={diffValueLabel}
                  value={editorContent}
                  originalValue={selectedHistory.content}
                  onChange={onEditorChange}
                />
              </div>
            )}
            <DebugPanel />
          </div>
          <div className="preview-container">
            {isPreviewDisplayed && (
              <EditorPreview
                iframeRef={iframeRef}
                appId={app.id}
                screenId={artifactId}
                inputParams={artifactInputs}
                onCopyPreviewLink={onCopyPreviewLink}
                onRefreshPreview={() => reloadPreview(editorContent)}
                handleArtifactInputs={handleArtifactInputs}
                onTogglePreview={() =>
                  setIsPreviewDisplayed(!isPreviewDisplayed)
                }
                useReact={reactEnabled && app.isReact}
              />
            )}
          </div>
          <div className="property-panel-container">
            {!isSourceView && selectedNodePair && (
              <div className={"property-panel-container-wrapper"}>
                <WidgetPropertyPanel
                  node={selectedNodePair}
                  isRoot={true}
                  parentNode={selectedNodeParent}
                />
              </div>
            )}
            <div className={"property-panel-container-placeholder"}>
              <p>Select a widget from the tree to edit</p>
            </div>
          </div>
        </div>
        <ModalDialog
          modalContent={
            <JavascriptEditor
              value={String(scalarInCursorRange?.value)}
              onSave={onJsEditorSave}
              onCancel={() => setIsJsEditorOpen(false)}
            />
          }
          isModalDisplayed={isJsEditorOpen}
          onHide={() => setIsJsEditorOpen(false)}
          headerText={"Edit Javascript"}
        />
      </div>
    </DndProvider>
  );
};
