import React, { useState, createRef, useEffect, useContext } from "react";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { AuthContext } from "../../../App";

// APIs
import { useUpdateWidget } from "../../../hooks/useAPIs";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

import { formatWidgetName } from "../../../utils/functions";

interface WidgetRenameModalProps {
  widget: EnsembleWidgetData;
  onCancel: () => void;
  onSubmit: () => void;
}

const WidgetRenameModal: React.FC<WidgetRenameModalProps> = ({
  widget,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [widgetName, setWidgetName] = useState(widget.name);
  const updateWidgetQuery = useUpdateWidget(currentUser, app.id, widget.id);
  const widgetNameInputRef = createRef<HTMLInputElement>();
  const submitButtonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    widgetNameInputRef.current?.focus();
  }, []);

  const handleInputChange = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    const formattedName = formatWidgetName(event.currentTarget.value);
    setWidgetName(formattedName);
  };

  // submit on Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      submitButtonRef.current?.click();
    }
  };

  // trigger update
  const renameApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    updateWidgetQuery.mutate({
      name: widgetName,
    });
  };

  // update complete
  useEffect(() => {
    if (updateWidgetQuery.isSuccess) onSubmit();
  }, [updateWidgetQuery.status]);

  return (
    <form className="screen-create-modal" onSubmit={renameApp}>
      <input
        type="text"
        ref={widgetNameInputRef}
        value={widgetName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <br />
      <button type="submit" ref={submitButtonRef} className="button__primary">
        {updateWidgetQuery.isIdle && <>Rename widget</>}
        {updateWidgetQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default WidgetRenameModal;
