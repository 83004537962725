import React from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import { EnsembleTranslationData } from "../config/interfaces";
import { useArchiveTranslation } from "../hooks/useFirebase";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

interface LanguageArchiveModalProps {
  language: EnsembleTranslationData; // Adjust the type if necessary
  onCancel: () => void;
  onSubmit: () => void;
}

export const LanguageArchiveModal: React.FC<LanguageArchiveModalProps> = ({
  language,
  onCancel,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const queryClient = useQueryClient();
  const useArchive = useArchiveTranslation(
    app.id,
    language.id,
    () => {
      queryClient.invalidateQueries(["app", app.id]).then();
      onSubmit();
    },
    (error) => {
      onCancel();
      toast.error(error.message, { autoClose: 5000 });
    },
  );

  const archiveLanguage = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    useArchive.mutate();
  };

  return (
    <div className="app-create-modal">
      <button onClick={archiveLanguage} className="button__primary">
        {useArchive.isIdle && <>Archive Language</>}
        {useArchive.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};
