import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../App";
import { useInviteCollaborator } from "../hooks/useAPIs";
import { getUserDetails } from "../hooks/useFirebase";
import { AccessType } from "../models/model";
import { useAppContext } from "../pages/AppPagesWrapper";
import { Icon } from "@iconify/react";

interface CollaboratorCardProps {
  inviteeEmail: string;
  inviteeAccessType: AccessType;
  isAccepted: boolean;
}

const InvitedCollaboratorCard: React.FC<CollaboratorCardProps> = ({
  inviteeEmail,
  inviteeAccessType,
  isAccepted,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);

  const [shouldRender, setShouldRender] = useState(true);

  const inviteCollaborator = useInviteCollaborator(
    app.id,
    app.name,
    currentUser?.id,
    currentUser?.name,
    inviteeAccessType,
    inviteeEmail
  );

  useEffect(() => {
    if (inviteCollaborator.isSuccess)
      toast("Invite has been sent.", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
  }, [inviteCollaborator.isSuccess]);

  // Remove Invited Collaborators for those who are already collaborators
  useEffect(() => {
    app.collaborators?.forEach(async (value, key) => {
      const user = await getUserDetails(key);
      if (user?.email === inviteeEmail || isAccepted) {
        setShouldRender(false);
      }
    });
  }, [app.collaborators, inviteeEmail, isAccepted]);

  return shouldRender ? (
    <div className="invited-collaborator-card">
      <Icon icon="mdi:user-circle" width={30} height={30} />
      <p>Invited Collaborator</p>
      <p>{inviteeEmail}</p>
      <button
        onClick={() => inviteCollaborator.mutate()}
        disabled={inviteCollaborator.isLoading}
      >
        {(inviteCollaborator.isIdle || inviteCollaborator.isSuccess) && (
          <>Resend Invite</>
        )}
        {inviteCollaborator.isLoading && <>Processing...</>}
      </button>
    </div>
  ) : null;
};

export default InvitedCollaboratorCard;
