import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { FieldProps, IdSchema, RJSFSchema, UiSchema } from "@rjsf/utils";
import { RemixIcon } from "../../../components/Widgets";
import { Tooltip } from "antd";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import "./ArrayField.sass";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import { ActionMenu } from "../../../components/VisualEditor/ActionMenu";
import { JSONSchema7 } from "json-schema";
import {
  CustomJSONSchema7,
  getDefaultValue,
} from "../utils/propertyPanelSchemaUtils";

const DRAG_TYPE = "arrayItem";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface DraggableItemProps {
  index: number;
  fieldProps: FieldProps;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  removeItem: (index: number) => void;
  onItemChange: (index: number, newValue: any) => void;
}
const DraggableItem: React.FC<DraggableItemProps> = ({
  index,
  fieldProps,
  moveItem,
  removeItem,
  onItemChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      if (hoverClientY < hoverMiddleY) {
        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAG_TYPE,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // only icon initiates drag
  drag(dragRef);
  // the whole row shows as drag preview
  preview(ref);
  // the whole row is a drop target
  drop(ref);

  const itemContent = useMemo(() => {
    // TODO: handle Action under the main tree, not as a popip
    if (
      fieldProps.schema &&
      (fieldProps.schema as CustomJSONSchema7).uiType === "action" &&
      fieldProps.formData
    ) {
      return <div>{camelCaseToWords(Object.keys(fieldProps.formData)[0])}</div>;
    }
    return (
      <SchemaField
        {...fieldProps}
        onChange={(value) => onItemChange(index, value)}
      />
    );
  }, [fieldProps, index, onItemChange]);

  return (
    <div
      ref={ref}
      className={"array-field-draggable-item"}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className={"array-field-draggable-item-actions"}>
        <span ref={dragRef} className={"array-field-draggable-item-draggable"}>
          <RemixIcon name="draggable" />
        </span>
        <ActionMenu
          anchor={
            <span className={"array-field-draggable-item-remove"}>
              <RemixIcon name={"arrow-drop-down-line"} />
            </span>
          }
          items={[
            {
              iconName: "delete-bin-7-line",
              label: "Delete",
              value: "delete",
            },
          ]}
          onSelect={(value) => {
            if (value === "delete") {
              removeItem(index);
            }
          }}
        />
      </div>

      <div className={"array-field-draggable-item-content"}>{itemContent}</div>
    </div>
  );
};
export const ArrayField: React.FC<FieldProps> = (props) => {
  const { id, formData, formContext, schema, uiSchema, onChange } = props;

  // use to submit the form after changes have propagated
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [formContext, hasPendingChanges]);

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newFormData = [...formData];
      const [draggedItem] = newFormData.splice(dragIndex, 1);
      newFormData.splice(hoverIndex, 0, draggedItem);
      onChange(newFormData);
      setHasPendingChanges(true);
    },
    [formData, onChange],
  );

  const removeItem = useCallback(
    (index: number) => {
      const newFormData = [...formData];
      newFormData.splice(index, 1);
      onChange(newFormData);
      setHasPendingChanges(true);
    },
    [formData, onChange],
  );

  const handleItemChange = useCallback(
    (index: number, newValue: any) => {
      const newFormData = [...formData];
      newFormData[index] = newValue;
      onChange(newFormData);
    },
    [formData, onChange],
  );

  const addItem = useCallback(() => {
    const generateDefaultValue = () => {
      const defaultValue =
        (schema.items as CustomJSONSchema7)?.defaultValue ??
        (schema.items as JSONSchema7)?.default;
      if (defaultValue) return defaultValue;

      // const type = (schema.items as JSONSchema7)?.type;
      // if (type === "string") {
      //   const prefix = props.name ?? "item";
      //   return `${prefix}${(formData?.length ?? 0) + 1}`;
      // } else if (type === "number") return "";
      //
      // return {};

      return getDefaultValue(schema.items as CustomJSONSchema7);
    };

    const newItem = generateDefaultValue();
    onChange([...(formData || []), newItem]);
    // setHasPendingChanges(true);
  }, [formData, onChange, schema.items]);

  const customizeUiSchema = (uiSchema: UiSchema | undefined): UiSchema => ({
    ...uiSchema,
    "ui:options": {
      ...((uiSchema && uiSchema["ui:options"]) || {}),
      // don't show the label for each Array item (since we already show it here)
      label: false,
    },
  });

  // we need to add the index to the idSchema for each item.
  // Note that use the [] notation to remove ambiguity whether it's an index or name
  const generateIdSchema = <T = any,>(
    idSchema: IdSchema<T>,
    index: number,
  ): IdSchema<T> => {
    return {
      ...idSchema,
      $id: `${idSchema.$id}_[${index}]`,
    };
  };

  return (
    <div>
      {/* mimic FieldTemplate to show the label and Add button*/}
      <div className="custom-field-template-content">
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={schema.description}
          placement={"left"}
        >
          <label
            htmlFor={id}
            className={`field-label ${schema.required ? "required" : ""}`}
          >
            {camelCaseToWords(props.name || "")}
          </label>
        </Tooltip>

        <div className={"field-widget"}>
          <button
            type="button"
            className={"array-field-add-button"}
            onClick={addItem}
          >
            <RemixIcon name={"menu-add-line"} />
            <span>Add</span>
          </button>
        </div>
        <div className={"field-expression-icon"}>
          {/* TODO: expression icon */}
        </div>
      </div>

      {/* Now show the items */}
      <div>
        <DndProvider backend={HTML5Backend}>
          {formData?.map((item: any, index: number) => (
            <DraggableItem
              key={index}
              index={index}
              fieldProps={{
                ...props,
                formData: item,
                idSchema: generateIdSchema(props.idSchema, index),
                schema: schema.items as RJSFSchema,
                uiSchema: customizeUiSchema(uiSchema?.items),
              }}
              moveItem={moveItem}
              onItemChange={handleItemChange}
              removeItem={removeItem}
            />
          ))}
        </DndProvider>
      </div>
    </div>
  );
};
