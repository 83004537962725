import React, { useContext, useEffect } from "react";
// Assets
import { ReactComponent as IconFreePlan } from "../../assets/free_plan.svg";
import { ReactComponent as IconProPlan } from "../../assets/pro_plan.svg";
import { ReactComponent as IconTeamPlan } from "../../assets/team_plan.svg";
import TickIcon from "../../assets/tick.svg";
import { AuthContext } from "../../App";
import { useCreateCheckoutSession } from "../../hooks/useAPIs";
import { ISubscriptionData } from "../../config/interfaces";

interface SubscriptionCardProps {
  subscriptionDetails: ISubscriptionData;
}

interface PlanIconProps {
  planName: string;
}

export const PlanIcon: React.FC<PlanIconProps> = ({ planName }) => {
  switch (planName) {
    case "Community":
      return <IconFreePlan className="plan_icon" />;
    case "Pro":
      return <IconProPlan className="plan_icon" />;
    case "Team":
      return <IconTeamPlan className="plan_icon" />;
    default:
      return null;
  }
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscriptionDetails,
}) => {
  const { currentUser } = useContext(AuthContext);
  const isFreePlan = subscriptionDetails.price === "Free";
  const checkoutSessionQuery = useCreateCheckoutSession(
    subscriptionDetails.stripeProductId,
    currentUser?.email ?? "",
    "https://studio.ensembleui.com/account"
  );

  const planName = currentUser?.subscriptionPlan?.planDetails?.name;

  const handleCheckoutPage = () => {
    checkoutSessionQuery.mutate();
  };

  useEffect(() => {
    if (
      checkoutSessionQuery.isSuccess &&
      checkoutSessionQuery.data.sessionUrl
    ) {
      window.open(checkoutSessionQuery.data.sessionUrl, "_blank");
    }
  }, [checkoutSessionQuery.isSuccess, checkoutSessionQuery?.data?.sessionUrl]);

  return (
    <div className="subscription-card">
      <PlanIcon planName={subscriptionDetails.name} />
      <h6 className="title">{subscriptionDetails.name}</h6>
      <div className="price">
        <h6>
          {subscriptionDetails?.currency ?? ""}
          {subscriptionDetails?.price}
        </h6>
        <span>{isFreePlan ? "for ever" : "/ user / month"}</span>
      </div>
      <h5 className="detail">
        {subscriptionDetails.name === "Pro"
          ? "Everything from Community"
          : subscriptionDetails.name === "Team" && "Everything from Pro"}
      </h5>
      <div className="features">
        {subscriptionDetails?.features?.map(
          (feature: string, index: number) => (
            <div className="feature" key={index}>
              <img src={TickIcon} />
              <span>{feature}</span>
            </div>
          )
        )}
      </div>
      <div className="action-button">
        {planName === subscriptionDetails.name && "Your current plan"}
        {planName !== subscriptionDetails.name &&
          subscriptionDetails.name !== "community" && (
            <button onClick={handleCheckoutPage}>
              {checkoutSessionQuery.isLoading
                ? "Loading..."
                : "Start free trial"}
            </button>
          )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
