import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import AppLeaveModal from "./AppLeaveModal";

// Interfaces
import { AppData } from "../models/model";

interface AppLeaveProps {
  app: AppData;
  displayLeaveModal: boolean;
  setLeaveTrigger: (value: boolean) => void;
}

const AppLeave: React.FC<AppLeaveProps> = ({
  app,
  displayLeaveModal,
  setLeaveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setLeaveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setLeaveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayLeaveModal}
      onHide={(event: any) => onCancel(event)}
      headerText={`Leave ${app.name}?`}
      modalContent={
        <AppLeaveModal
          app={app}
          onCancel={(event: any) => onCancel(event)}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default AppLeave;
