import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { head } from "ramda";

import "@szhsin/react-menu/dist/index.css";

// Components
import WidgetRename from "../modals/WidgetRename/WidgetRename";
import WidgetArchive from "../modals/WidgetArchive/WidgetArchive";
import { ReactComponent as IconMenu } from "../../assets/icon_menu.svg";

// Interfaces
import { EnsembleWidgetData } from "../../config/interfaces";
import { useCreateWidget } from "../../hooks/useFirebase";
import { toast } from "react-toastify";
import { AuthContext } from "../../App";
import { toastOptions } from "../../config/constants";

interface ScreenActionsMenuProps {
  widget: EnsembleWidgetData;
  isReadOnly: boolean;
}

const WidgetActionsMenu: React.FC<ScreenActionsMenuProps> = ({
  widget,
  isReadOnly,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [renameTrigger, setRenameTrigger] = useState<boolean>(false);
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);
  const createWidgetQuery = useCreateWidget(currentUser, app.id, {
    id: "",
    name: widget?.name + "Copy",
    content: widget?.content,
    isArchived: false,
    isRoot: false,
  });

  useEffect(() => {
    if (createWidgetQuery.isLoading) return;
    if (createWidgetQuery.isSuccess) {
      const newWidgetId = head(createWidgetQuery.data?.insert_screen?.returning)
        ?.id;

      if (newWidgetId) {
        const newTab = window.open(
          `/app/${app.id}/widget/${newWidgetId}`,
          "_blank",
        );
        if (newTab) newTab.focus();
      } else
        toast.error("Something went wrong. Please try again.", toastOptions);
    }
  }, [createWidgetQuery.status]);

  const handleRename = (event: any) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setRenameTrigger(true);
  };

  const handleArchive = (event: any) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const createWidgetCopy = (event: any) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();

    createWidgetQuery.mutate();
  };

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="top"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {!isReadOnly && (
          <>
            <MenuItem onClick={handleRename}>Rename</MenuItem>
            <MenuItem onClick={createWidgetCopy}>Make a Copy</MenuItem>
            <MenuDivider />
            <MenuItem
              className="actions-menu__delete-item"
              onClick={handleArchive}
            >
              Archive
            </MenuItem>
          </>
        )}
      </Menu>

      <WidgetRename
        widget={widget}
        displayRenameModal={renameTrigger}
        setRenameTrigger={setRenameTrigger}
      />

      <WidgetArchive
        widget={widget}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />
    </>
  );
};

export default WidgetActionsMenu;
