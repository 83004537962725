import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import AppCloneModal from "./AppCloneModal";
import { AppData } from "../models/model";

interface AppCloneProps {
  app: AppData;
  displayCloneModal: boolean;
  setCloneTrigger: (value: boolean) => void;
}

const AppClone: React.FC<AppCloneProps> = ({
  app,
  displayCloneModal,
  setCloneTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setCloneTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayCloneModal}
      onHide={onCancel}
      headerText={`Clone app`}
      modalContent={<AppCloneModal app={app} />}
    />
  );
};

export default AppClone;
