import React from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import { useSetDefaultTranslationLanguage } from "../hooks/useFirebase";
import { useQueryClient } from "react-query";
import { LanguageActionModalProps } from "./LanguageActionMenu";
import { getLanguageCode } from "../utils/Util";

export const LanguageSetDefaultModal: React.FC<LanguageActionModalProps> = ({
  language,
  onCancel,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const queryClient = useQueryClient();
  const useSetDefault = useSetDefaultTranslationLanguage(
    app.id,
    getLanguageCode(language),
    () => {
      queryClient.invalidateQueries(["app", app.id]).then();
      onSubmit();
    },
  );

  const setDefaultLanguage = (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    useSetDefault.mutate();
  };

  return (
    <div className="app-create-modal">
      <button onClick={setDefaultLanguage} className="button__primary">
        {useSetDefault.isIdle && <>Set as default</>}
        {useSetDefault.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};
