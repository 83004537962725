import React, { useState, createContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isNil } from "ramda";

// Pages
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import AppPagesWrapper from "./pages/AppPagesWrapper";
import AppScreens from "./pages/AppScreens";
import WidgetScreens from "./pages/WidgetScreens";
import AppTheme from "./pages/AppTheme";
import AppSettings from "./pages/AppSettings";
import Account from "./pages/Account";
import E404 from "./pages/404";
import Resources from "./pages/Resources";
import Invite from "./pages/Invite";

// components
import Nav from "./components/Nav";
import AuthCheck from "./components/AuthCheck";
import AppendScript from "./utils/AppendScript";
import { HOTJAR_EXCLUDE_LIST } from "./common";

// interfaces
import {
  IEnsembleUserData,
  IAuthContext,
  IAppDataContext,
  IsUpdateScreenContext,
} from "./config/interfaces";

// Styles
import "./styles/app.sass";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { AppData } from "./models/model";
import AppCollaborators from "./pages/AppCollaborators";
import Register from "./pages/Register";
import { FeatureFlagProvider } from "./hooks/useFeatureFlag";
import { EditorPage } from "./pages/EditorPage";
import CreateAppScreen from "./pages/CreateAppScreen";
import AppAssets from "./pages/AppAssets";
import ScriptScreens from "./pages/ScriptScreens";
import AppScript from "./components/AppScript";
import PropTypes from "prop-types";
import { Translations } from "./pages/Translations";
import { TranslationEditor } from "./pages/TranslationEditor";

// Context for sharing user data and current app
export const AuthContext = createContext<IAuthContext>({ currentUser: null });
export const AppDataContext = createContext<IAppDataContext>({
  app: null,
  isAppReadOnly: false,
  isDemoApp: false,
  isPermissionChecked: false,
});
export const UpdateScreenLoadingContext = createContext<IsUpdateScreenContext>({
  isUpdateScreenLoading: false,
});

const App: React.FunctionComponent = () => {
  const [currentUser, setCurrentUser] = useState<IEnsembleUserData | null>(
    null,
  );
  const [app, setAppData] = useState<AppData | null>(null);
  const [isAppReadOnly, setIsAppReadOnly] = useState(false);
  const [isUpdateScreenLoading, setIsUpdateScreenLoading] = useState(false);
  const [isDemoApp, setIsDemoApp] = useState(false);
  const [isPermissionChecked, setIsPermissionChecked] = useState(false);

  const resetAppData = () => {
    setAppData(null);
    setIsAppReadOnly(false);
    setIsDemoApp(false);
    setIsPermissionChecked(false);
  };

  useEffect(() => {
    if (!isNil(currentUser) && currentUser.email) {
      // record for external users by filtering out internal emails
      if (!HOTJAR_EXCLUDE_LIST.includes(currentUser.email)) {
        AppendScript();
      }
    }
  }, [currentUser]);

  const ExternalRedirect = ({ url }: { url: string }) => {
    useEffect(() => {
      window.location.href = url;
    }, [url]);
    return null;
  };
  ExternalRedirect.propTypes = {
    url: PropTypes.string.isRequired,
  };

  return (
    <div className="App">
      <BrowserRouter>
        <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
          <FeatureFlagProvider>
            <AppDataContext.Provider
              value={{
                app,
                setAppData,
                isPermissionChecked,
                setIsPermissionChecked,
                isDemoApp,
                setIsDemoApp,
                isAppReadOnly,
                setIsAppReadOnly,
                resetAppData,
              }}
            >
              <UpdateScreenLoadingContext.Provider
                value={{ isUpdateScreenLoading, setIsUpdateScreenLoading }}
              >
                <Nav appData={app} />
                <Routes>
                  <Route path="sign-in" element={<SignIn />} />
                  <Route path="register" element={<Register />} />
                  <Route path="resources" element={<Resources />} />
                  <Route
                    path="/"
                    element={
                      <AuthCheck>
                        <Home />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="account"
                    element={
                      <AuthCheck>
                        <Account />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="invite/:ref"
                    element={
                      <AuthCheck>
                        <Invite />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="resources"
                    element={
                      <AuthCheck>
                        <Resources />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="app/:app_id"
                    element={
                      <AuthCheck>
                        <AppPagesWrapper />
                      </AuthCheck>
                    }
                  >
                    <Route path="screens" element={<AppScreens />} />
                    <Route
                      path="screens/create"
                      element={<CreateAppScreen />}
                    />
                    <Route path="screen/:screen_id" element={<EditorPage />} />
                    <Route path="scripts" element={<ScriptScreens />} />
                    <Route path="script/:script_id" element={<AppScript />} />
                    <Route path="widgets" element={<WidgetScreens />} />
                    <Route path="widget/:widget_id" element={<EditorPage />} />
                    <Route path="theme" element={<AppTheme />} />
                    <Route path="translations" element={<Translations />} />
                    <Route
                      path="translations/:languageCode"
                      element={<TranslationEditor />}
                    />
                    <Route path="assets" element={<AppAssets />} />
                    <Route path="settings" element={<AppSettings />} />
                    <Route
                      path="collaborators"
                      element={<AppCollaborators />}
                    />
                    <Route path="*" element={<E404 />} />
                  </Route>
                  {/* Use for picky Monaco Editor to link to documentation */}
                  <Route
                    path="/Documentation"
                    element={
                      <ExternalRedirect url="https://docs.ensembleui.com" />
                    }
                  />
                  <Route path="*" element={<E404 />} />
                </Routes>
                <ToastContainer />
              </UpdateScreenLoadingContext.Provider>
            </AppDataContext.Provider>
          </FeatureFlagProvider>
        </AuthContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
