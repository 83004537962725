import React, { useEffect, useContext } from "react";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { AuthContext } from "../../../App";

// APIs
import { useUpdateWidget } from "../../../hooks/useAPIs";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface WidgetArchiveModalProps {
  widget: EnsembleWidgetData;
  onCancel: () => void;
  onSubmit: () => void;
}

const WidgetArchiveModal: React.FC<WidgetArchiveModalProps> = ({
  widget,
  onCancel,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const widgetChanges = { isArchived: true };
  const updateWidgetQuery = useUpdateWidget(currentUser, app.id, widget.id);

  // trigger update
  const archiveWidget = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateWidgetQuery.mutate(widgetChanges);
  };

  // update complete
  useEffect(() => {
    if (updateWidgetQuery.isSuccess) onSubmit();
  }, [updateWidgetQuery.status]);

  return (
    <div className="app-create-modal">
      <button onClick={archiveWidget} className="button__primary">
        {updateWidgetQuery.isIdle && <>Archive widget</>}
        {updateWidgetQuery.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default WidgetArchiveModal;
