import React, { useState } from "react";
import { Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import { CompositeObjectFieldTemplateProps } from "./ObjectFieldTemplate";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import "./CardFieldTemplate.sass";

// This Template display a group of fields as a deck of card
// vs the default grouping of ObjectFieldTemplate
export const CardFieldTemplate: React.FC<CompositeObjectFieldTemplateProps> = ({
  props,
  groupName,
}) => {
  const { schema } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <div
        className={`card-field-template-header ${
          !isExpanded ? "card-field-template-header-collapsed" : ""
        }`}
        onClick={toggleExpand}
      >
        <div className={"card-field-template-header-title"}>
          <span>{camelCaseToWords(groupName ?? "")}</span>
          {schema.description && (
            <Tooltip
              overlayClassName={"property-panel-tooltip"}
              title={schema.description}
            >
              <span>
                <RemixIcon name={"information-line"} />
              </span>
            </Tooltip>
          )}
        </div>
        <RemixIcon
          name={isExpanded ? "arrow-down-s-line" : "arrow-right-s-line"}
        />
      </div>
      <div
        className={"composite-field-body"}
        style={{ display: isExpanded ? "block" : "none" }}
      >
        {props.properties.map((element) => element.content)}
      </div>
    </div>
  );
};
