import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import ScreenArchiveModal from "./ScreenArchiveModal";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

interface ScreenArchiveProps {
  screen: EnsembleScreenData;
  displayArchiveModal: boolean;
  setArchiveTrigger: any;
}

const ScreenArchive: React.FC<ScreenArchiveProps> = ({
  screen,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={onCancel}
      headerText={`Archive ${screen.name}?`}
      modalContent={
        <ScreenArchiveModal
          screen={screen}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScreenArchive;
