import { useEffect, useState } from "react";
import "../styles/app-collaborators.sass";
import { useAppContext } from "./AppPagesWrapper";
import { AccessType, CollaboratorData } from "../models/model";
import { useGetInvitedCollaborators } from "../hooks/useAPIs";
import CollaboratorCard from "../components/CollaboratorCard";
import InvitedCollaboratorCard from "../components/InvitedCollaboratorCard";
import InviteCollaborator from "../components/InviteCollaborator";
import { Link } from "react-router-dom";
import { DocumentData } from "firebase/firestore";

const AppCollaborators = () => {
  const { app } = useAppContext();
  const [collaborators, setCollaborators] = useState<CollaboratorData[]>([]);
  const invitedCollaboratorsData = useGetInvitedCollaborators(app.id);

  const emailSet = new Set<string>();
  const invitedCollaborators: DocumentData[] = [];

  // Remove Invited Collaborators for those who are already collaborators
  invitedCollaboratorsData?.data?.forEach((item) => {
    const email = item.data.to;
    if (collaborators.some((i) => i.email === email)) {
      invitedCollaboratorsData.data.splice(
        invitedCollaboratorsData.data.indexOf(item),
        1,
      );
    }

    // Remove duplicate invited collaborators
    if (!emailSet.has(email)) {
      emailSet.add(email);
      invitedCollaborators.push(item.data);
    }

    // Remove if the email is empty
    if (email === "") {
      invitedCollaboratorsData.data.splice(
        invitedCollaboratorsData.data.indexOf(item),
        1,
      );
    }
  });

  useEffect(() => {
    const collaboratorsArray: CollaboratorData[] = [];

    app.collaborators?.forEach((value: AccessType, key: string) => {
      const thisCollborator = new CollaboratorData({
        id: key,
        accessType: value,
      });
      collaboratorsArray.push(thisCollborator);
    });
    setCollaborators(collaboratorsArray);
  }, [app.collaborators]);

  return (
    <div className="app-collaborators-content">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <h1>Collaborators</h1>
      </div>
      {app.collaborators && (
        <div className="collaborator-card-ct">
          {/* Invite Collaborator */}
          <InviteCollaborator collaboratorAccessType={app.accessType} />

          {collaborators.map((i) => {
            return (
              <CollaboratorCard
                key={i.id}
                userId={i.id}
                appId={app.id}
                accessType={i.accessType}
                currentUserAccessType={app.accessType}
              />
            );
          })}

          {/* Invited Collaborators */}
          {invitedCollaborators && (
            <>
              {invitedCollaborators?.map((i, index) => {
                return (
                  <InvitedCollaboratorCard
                    key={index}
                    inviteeEmail={i.to}
                    inviteeAccessType={i.template.data.inviteeAccessType}
                    isAccepted={i.isAccepted}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AppCollaborators;
