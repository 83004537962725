import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import ScreenRenameModal from "./ScreenRenameModal";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

interface ScreenRenameProps {
  screen: EnsembleScreenData;
  displayRenameModal: boolean;
  setRenameTrigger: any;
}

const ScreenRename: React.FC<ScreenRenameProps> = ({
  screen,
  displayRenameModal,
  setRenameTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setRenameTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    toggleModal();
    setRenameTrigger(false);
  };

  return (
    <Modal
      isModalDisplayed={displayRenameModal}
      onHide={onCancel}
      headerText="Rename screen"
      modalContent={
        <ScreenRenameModal
          screen={screen}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScreenRename;
