import React, { ReactNode, useEffect, useMemo } from "react";
import { Tree } from "antd";
import { NodeRef, TreeNode } from "./TreeNode";
import { getTreeIcon } from "../../utils/treeUtils";
import { WidgetPicker } from "../../components/VisualEditor/WidgetPicker";
import { YAMLMap } from "yaml";
import { NodeAction } from "../../hooks/useYamlDoc";
import { TreeItemPayload, TreeItemWidgetPayload } from "./TreeNode";

interface TreeViewProps {
  treeData: TreeNode[] | null | undefined;
  selectedKey: string | null;
  onSelect: (key: string | null, ref: NodeRef | null) => void;
  onWidgetSelectedAction?: (
    parentNode: YAMLMap,
    widgetKey: string,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) => void;
  expandFirstNode?: boolean;
  autoSelect?: boolean; // select first node if none is selected
}

// render a Tree with its associated header
export const TreeView: React.FC<TreeViewProps> = ({
  treeData,
  selectedKey,
  onSelect,
  onWidgetSelectedAction,
  expandFirstNode,
  autoSelect = false,
}) => {
  const onTreeSelect = (
    selectedKeys: React.Key[],
    info: { node: TreeNode },
  ) => {
    if (selectedKeys.length > 0) {
      onSelect(selectedKeys[0].toString(), info.node.ref);
    } else {
      onSelect(null, null);
    }
  };

  // auto select if enabled
  useEffect(() => {
    if (autoSelect && !selectedKey && treeData && treeData.length > 0) {
      onSelect(String(treeData[0].key), treeData[0].ref);
    }
  }, [autoSelect, onSelect, selectedKey, treeData]);

  const expandedKeys = useMemo(() => {
    if (expandFirstNode && treeData && treeData.length > 0) {
      return [treeData[0].key];
    }
  }, [expandFirstNode, treeData]);

  // different renderers for the Tree's title. Note that NodeAction.setScreenBody
  // is not rendered inside the tree so we don't need to handle it here
  const treeItemRenderer = (treeNode: TreeNode) => {
    const action = treeNode.ref.action;
    if (action === NodeAction.SetWidgetBody) {
      return (
        <WidgetPicker
          trigger={<div className={"set-widget-body"}>Set Body Widget</div>}
          onWidgetSelect={(widgetKey) =>
            onWidgetSelectedAction
              ? onWidgetSelectedAction(treeNode.ref.parent!, widgetKey, action)
              : null
          }
        />
      );
    } else if (action === NodeAction.AddChild) {
      return (
        <WidgetPicker
          trigger={
            <div className={"tree-node-add"}>
              {getTreeIcon("iconadd")}
              <span>add child widget</span>
            </div>
          }
          onWidgetSelect={(widgetKey) =>
            onWidgetSelectedAction
              ? onWidgetSelectedAction(treeNode.ref.parent!, widgetKey, action)
              : null
          }
        />
      );
    } else if (action === NodeAction.SetTreeItemWidget) {
      if (treeNode.payload instanceof TreeItemWidgetPayload) {
        return (
          <WidgetPicker
            trigger={
              <div className={"tree-node-add"}>
                {getTreeIcon("iconadd")}
                <span>{treeNode.payload.label ?? "set widget"}</span>
              </div>
            }
            onWidgetSelect={(widgetKey) =>
              onWidgetSelectedAction
                ? onWidgetSelectedAction(
                    treeNode.ref.parent!,
                    widgetKey,
                    action,
                    treeNode.payload,
                  )
                : null
            }
          />
        );
      }
    }
    return (
      <div className={"tree-node-title"}>
        <div>{treeNode.title as ReactNode}</div>
        {/*<div className={"tree-node-icon"}>...</div>*/}
      </div>
    );
  };

  return treeData ? (
    <Tree
      titleRender={treeItemRenderer}
      treeData={treeData}
      defaultExpandedKeys={expandedKeys}
      onSelect={onTreeSelect}
      selectedKeys={selectedKey ? [selectedKey] : undefined}
      showIcon={true}
      blockNode={true}
    />
  ) : (
    <div></div>
  );
};
