import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import * as monaco from "monaco-editor";

import { useAppContext } from "../pages/AppPagesWrapper";

// Interfaces
import {
  EnsembleArtifactHistoryData,
  EnsembleScreenData,
} from "../config/interfaces";
import { getDateTime } from "../utils";
import { ArtifactHistoryDropdown } from "../containers/ArtifactHistoryDropdown";
import { ReactComponent as IconAlert } from "../assets/icon_alert.svg";

interface IScriptEditorProps {
  screen: EnsembleScreenData;
  disableButton: boolean;
  onSave: (content: string) => void;
}

const editorConfig: monaco.editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  theme: "vs-dark",
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
  },
  wordWrap: "off",
  fontSize: 14,
};

const ScriptEditor: React.FC<IScriptEditorProps> = ({
  screen,
  onSave,
  disableButton,
}) => {
  const { app, isAppReadOnly } = useAppContext();
  const containerElement = useRef<HTMLDivElement>(null);
  const diffEditorRef = useRef<HTMLDivElement>(null);
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const [selectedHistory, setSelectedHistory] =
    useState<EnsembleArtifactHistoryData>();
  const [diffEditorInstance, setDiffEditorInstance] =
    useState<monaco.editor.IStandaloneDiffEditor | null>(null);
  const [scriptContent, setScriptContent] = useState(screen.content || "");

  const hasUnsavedChanges = useMemo(() => {
    const normalizedEditorContent = scriptContent.trim();
    const normalizedScriptContent = screen.content?.trim();
    return (
      screen.content != undefined &&
      normalizedEditorContent !== normalizedScriptContent
    );
  }, [scriptContent, screen.content]);

  const isSaveDisabled =
    (!hasUnsavedChanges || disableButton) && !isAppReadOnly;

  useEffect(() => {
    if (selectedHistory) {
      if (!diffEditorInstance && diffEditorRef.current) {
        const diffEditor = monaco.editor.createDiffEditor(
          diffEditorRef.current,
          editorConfig,
        );
        setDiffEditorInstance(diffEditor);

        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          scriptContent ?? screen.content,
        );
        modifiedModel.onDidChangeContent(() => {
          setScriptContent(modifiedModel.getValue());
        });
        diffEditor.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      } else {
        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          scriptContent ?? screen.content,
        );
        diffEditorInstance?.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      }
    } else {
      setDiffEditorInstance(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory]);

  const saveScript = useCallback(() => {
    onSave(scriptContent);
  }, [onSave, scriptContent]);

  useEffect(() => {
    if (!selectedHistory && containerElement.current && !editor.current) {
      const yamlInstance = monaco.editor.create(containerElement.current, {
        ...editorConfig,
        language: "javascript",
        value: scriptContent ?? screen.content,
      });
      editor.current = yamlInstance;
      yamlInstance.onDidChangeModelContent(() => {
        setScriptContent(yamlInstance.getValue());
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory, screen.content]);

  useEffect(() => {
    if (editor.current && saveScript) {
      editor.current.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        saveScript,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.current, saveScript]);

  const handleCloseDiffEditor = () => {
    setSelectedHistory(undefined);
  };

  const handleRestoreVersion = () => {
    setScriptContent(selectedHistory?.content ?? scriptContent);
    setSelectedHistory(undefined);
  };

  return (
    <div key={screen.id}>
      <div className="">
        {isAppReadOnly && (
          <p className="readOnlyNote">
            This app is read-only, and your changes will be lost.
          </p>
        )}
        {selectedHistory && (
          <div className="title-bar">
            <span className="title">
              {`${
                selectedHistory.label ??
                getDateTime(selectedHistory.updatedAt.toDate())
              } ${
                selectedHistory.updatedBy?.name
                  ? `(${selectedHistory.updatedBy?.name})`
                  : ""
              }`}
            </span>
            <span className="title current-title">Current</span>
          </div>
        )}

        {selectedHistory ? (
          <div
            key={`diff-script`}
            ref={diffEditorRef}
            style={{
              height: window.innerHeight - 150 + "px",
              width: window.innerWidth - 150 + "px",
            }}
          />
        ) : (
          <div
            key={selectedHistory}
            ref={containerElement}
            style={{
              height: window.innerHeight - 150 + "px",
              width: window.innerWidth - 150 + "px",
            }}
          />
        )}

        <div className="action-menus">
          {!isAppReadOnly && (
            <>
              {selectedHistory ? (
                <div className="selected-history-options">
                  <span
                    className="close-diff-text"
                    onClick={handleCloseDiffEditor}
                  >
                    Close diff editor
                  </span>
                  <span
                    className="close-diff-text"
                    onClick={handleRestoreVersion}
                  >
                    Restore version
                  </span>
                </div>
              ) : null}
              {hasUnsavedChanges && (
                <div className="unsaved-changes">
                  <IconAlert className="unsaved-changes-icon" />
                  <p>You have unsaved changes</p>
                </div>
              )}
              <ArtifactHistoryDropdown
                appId={app.id}
                artifactId={screen.id}
                onSelectHistory={setSelectedHistory}
                selectedHistory={selectedHistory}
              />
              <div className="save-script">
                <button
                  disabled={isSaveDisabled}
                  className={`checkin-button ${
                    isSaveDisabled ? "disable-button" : ""
                  }`}
                  onClick={() => onSave(scriptContent)}
                >
                  {"Save"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScriptEditor;
