import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./LanguageCard.sass";

import { EnsembleTranslationData } from "../../config/interfaces";
import {
  getTranslationLanguageFlag,
  getTranslationLanguageName,
} from "../../utils/Util";
import { LanguageActionsMenu } from "../LanguageActionMenu";
import { Tooltip } from "antd";

interface LanguageCardProps {
  language: EnsembleTranslationData;
  app_id: string;
  isReadOnly: boolean;
}

export const LanguageCard: React.FC<LanguageCardProps> = ({
  language,
  app_id,
  isReadOnly,
}) => {
  const languageCode = language.id.replace("i18n_", "");

  if (languageCode.length !== 2) {
    // ignore language code that is not 2 characters long
    return (
      <div className="script-screen-card">
        <div className="script-screen-card__content space-between">
          <h4>Unknown Language</h4>
        </div>
      </div>
    );
  }

  return (
    <Link to={`/app/${app_id}/translations/${languageCode}`}>
      <div className="language-card">
        <div className={"language-card-flag"}>
          {getTranslationLanguageFlag(languageCode)}
        </div>
        <div className={"language-card-content"}>
          <div className={"language-card-text"}>
            <h4>{getTranslationLanguageName(languageCode)}</h4>
            {language.defaultLocale && (
              <Tooltip
                title="Default to this language if the system's language is not supported"
                placement={"bottom"}
              >
                <span className={"language-card-default"}>
                  Default language
                </span>
              </Tooltip>
            )}
          </div>
          {!isReadOnly && (
            <LanguageActionsMenu language={language} isReadOnly={isReadOnly} />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};
