import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactTooltip from "react-tooltip";

import ScreenActionsMenu from "./ScreenActionsMenu";

import {
  EnsembleCategoriesData,
  EnsembleScreenData,
} from "../config/interfaces";

import { Link } from "react-router-dom";

interface ScreenCardProps {
  screen: EnsembleScreenData;
  categories: EnsembleCategoriesData[] | undefined;
  app_id: string;
  isReadOnly: boolean;
  isTemplate: boolean;
}

const ScreenCard: React.FC<ScreenCardProps> = ({
  screen,
  categories,
  app_id,
  isReadOnly,
  isTemplate,
}) => {
  const [image, setImage] = useState("");
  const storage = getStorage();

  useEffect(() => {
    getDownloadURL(ref(storage, `screens/${screen.id}.jpg`))
      .then((url) => {
        setImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link to={`/app/${app_id}/screen/${screen.id}`} reloadDocument>
      <div className="screen-card">
        {image ? (
          <div
            className="screen-card__image"
            style={{ backgroundImage: `url(${image}` }}
          ></div>
        ) : (
          <div className="screen-card__code">
            <pre data-text={`${screen.content}`} />
          </div>
        )}

        <div className="screen-card__content">
          <h4>{screen.name}</h4>

          {/* screen details: home? */}
          <div className="screen-card__content-info">
            {screen.isRoot === true && <span>Home screen</span>}
            {isTemplate === true && (
              <span>
                {categories?.find((c) => c.id === screen.category)?.name}
              </span>
            )}
          </div>

          {/* screen actions */}
          {!isReadOnly && (
            <ScreenActionsMenu
              screen={screen}
              categories={categories}
              isTemplate={isTemplate}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default ScreenCard;
