import React from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import MobileStoreButton from "./MobileStoreButton";
import QRCode from "react-qr-code";

const DevicePreviewModal: React.FC = () => {
  const { app } = useAppContext();

  return (
    <div className="app-preview-modal">
      <div className="row">
        <div className="col para">
          <h3>Step 1</h3>
          <span>Download Ensemble Preview</span>
        </div>
        <div className="col">
          <MobileStoreButton
            store={"Android"}
            url={
              "https://play.google.com/store/apps/details?id=com.ensembleui.preview"
            }
          />
          <MobileStoreButton
            store={"Ios"}
            url={"https://testflight.apple.com/join/yFKnLQ1S"}
          />
        </div>
      </div>

      <div className="row">
        <div className="col para">
          <h3>Step 2</h3>
          <span>
            Open app and Scan QR to view <br />
            <span>App ID: {app.id}</span>
          </span>
        </div>
        <div className="col">
          <div
            style={{
              background: "white",
              padding: "2px",
              display: "inline-block",
            }}
          >
            <QRCode
              size={212}
              value={app?.id}
              style={{ display: "inline-block" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicePreviewModal;
