import React, { Key, useEffect, useMemo, useState } from "react";
import { ActionBuilderModalProps } from "../WidgetPropertyPanel/fields/ActionBuilderModal";
import { RootWidgetContext } from "../WidgetPropertyPanel/fields/RootWidgetContext";
import { useSchemas } from "../../hooks/useSchemas";
import { Dropdown, Tree } from "antd";
import {
  ActionTreeBuilder,
  ActionTreeNode,
  ActionTreePlaceholderBuilder,
  EventNode,
  TreeItemAction,
} from "./ActionTreeBuilder";
import "./ActionTree.sass";
import { isMap, isScalar, Pair, Scalar, YAMLMap } from "yaml";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import { RemixIcon } from "../../components/Widgets";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { addValuePair, getPairKeyText } from "../../utils/docUtils";
import {
  camelCaseToWords,
  camelCaseToWordsExceptFirstLetter,
} from "../WidgetPropertyPanel/utils/propertyPanelUtils";

type ActionTreeProps = ActionBuilderModalProps & {
  treeDataChanges: number;
  setTreeDataChanges: (value: React.SetStateAction<number>) => void;
  onItemSelect: (ref: EventNode) => void;
};

export const ActionTree: React.FC<ActionTreeProps> = ({
  idSchema,
  formContext,
  onItemSelect,
  treeDataChanges,
  setTreeDataChanges,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const { actionSchemaMap } = useSchemas();
  const { doc, dispatchVisualEditorChanges } = useYamlDoc();

  const treeData = useMemo(() => {
    // from the main tree's selected node, we need to get to the Action node
    // which can be deeply nested or not even exists yet.
    const nodeContext = new RootWidgetContext(formContext, idSchema.$id);

    // find the root eventPair e.g. onTap -> invokeAPI
    const eventPair = nodeContext.getLeafNodePair();

    // if the root event is not yet created, we'll show a placeholder
    // for them to add using the nodeContext, which has the entire paths
    // to construct the Action no matter how nested it is
    if (!eventPair) {
      return new ActionTreePlaceholderBuilder(nodeContext).build();
    }
    // build the Action tree
    return new ActionTreeBuilder(actionSchemaMap).build(eventPair);
    // eslint-disable-next-line
  }, [
    actionSchemaMap,
    formContext.activeCategory,
    formContext.node,
    idSchema.$id,
    // we need to listen to this to re-build the tree
    treeDataChanges,
  ]);

  // select the first item if none is selected
  useEffect(() => {
    if (selectedKeys.length === 0 && treeData && treeData.length > 0) {
      setSelectedKeys([treeData[0].key]);
      onItemSelect(treeData[0].ref);
    }
  }, [onItemSelect, selectedKeys, treeData]);

  const titleRenderer = (treeNode: ActionTreeNode) => {
    const menuItems: ItemType[] =
      treeNode.actions?.map((action) => {
        return {
          key: `${action.type}-${action.value}`,
          label: `Add '${action.value}'`,
          onClick: (info) => {
            info.domEvent.stopPropagation();
            handleMenuAction(treeNode, action);
          },
        };
      }) ?? [];

    return (
      <div className={"action-item-title-container"}>
        <div className={"action-item-subtitle"}>
          {camelCaseToWordsExceptFirstLetter(treeNode.subtitle ?? "")}
        </div>
        <div className={"action-item-title-group"}>
          <div className={"action-item-title"}>
            {camelCaseToWords(treeNode.title as string)}
          </div>
          {treeNode.actions && (
            <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
              <div
                className={"action-item-title-icon"}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <RemixIcon name={"more-fill"} />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    );
  };

  const getActionName = (eventPair: Pair<Scalar, Scalar | YAMLMap>) => {
    if (isScalar(eventPair.value)) {
      return eventPair.value.value as string;
    } else if (isMap(eventPair.value) && eventPair.value.items.length === 1) {
      return getPairKeyText((eventPair.value as YAMLMap).items[0]);
    }
  };

  // handle the new child Event added to our Action
  const handleMenuAction = (
    treeNode: ActionTreeNode,
    action: TreeItemAction,
  ) => {
    const eventPair = treeNode.ref.eventPair;
    if (action.type === "add" && action.value) {
      // ensure this event is actually valid under the action
      const actionName = getActionName(eventPair);
      if (
        !actionName ||
        !actionSchemaMap.get(actionName)?.childEvents?.has(action.value)
      ) {
        return;
      }

      // convert the pair's value from Scalar to YAMLMap
      if (isScalar(eventPair.value)) {
        eventPair.value = doc!.createNode({
          [eventPair.value.value as string]: {},
        });
      }

      // now add the empty event name under the YAMLMap
      if (isMap(eventPair.value) && eventPair.value.items.length === 1) {
        const actionPair = eventPair.value.items[0];
        const newEventPair = doc!.createPair(
          action.value,
          new Scalar(null),
        ) as Pair<Scalar, Scalar>;
        addValuePair(actionPair, newEventPair);
        dispatchVisualEditorChanges();

        // tell the Tree to re-render
        setTreeDataChanges((prev) => prev + 1);

        // select the newly added node
        const newNodeKey = `${treeNode.key}-${action.value}`;
        setSelectedKeys([newNodeKey]);
        onItemSelect({
          eventPair: newEventPair,
          parent: actionPair.value as YAMLMap,
        });
      }
    }
  };

  const handleSelect = (keys: Key[], event: { node: { key: Key } }) => {
    // disallow un-select
    if (selectedKeys.includes(event.node.key)) {
      return;
    }
    setSelectedKeys(keys);
    onItemSelect((event.node as ActionTreeNode).ref);
  };

  return (
    <div className={"action-tree"}>
      {treeData ? (
        <Tree
          treeData={treeData}
          titleRender={titleRenderer}
          defaultSelectedKeys={treeData.length > 0 ? [treeData[0].key] : []}
          defaultExpandAll={true}
          selectedKeys={selectedKeys}
          onSelect={handleSelect}
          autoExpandParent={true}
        />
      ) : (
        <div>no Action added</div>
      )}
    </div>
  );
};
