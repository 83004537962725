import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";

// Components
import ThemeEditor from "../components/ThemeEditor";

// APIs
import { useCreateTheme } from "../hooks/useAPIs";
import { AuthContext } from "../App";
import { useGetThemeSchema } from "../hooks/useThemeSchema";

const AppTheme: React.FunctionComponent = () => {
  const { app } = useAppContext();
  const [, setThemeId] = useState("");
  const schema = useGetThemeSchema();
  const { currentUser } = useContext(AuthContext);

  const createTheme = useCreateTheme(currentUser, app.id);

  useEffect(() => {
    document.title = app.name + " theme";
  });

  useEffect(() => {
    if (!app.theme && createTheme.isIdle) {
      createTheme.mutate();
    } else if (createTheme.isSuccess) {
      setThemeId(createTheme.data.insert_theme_one.id);
    }
  }, [app, createTheme, createTheme.status]);

  return (
    <div className="app-settings-content">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <h1>Theme</h1>
      </div>

      {!app.theme && <p>loading...</p>}

      {app.theme && <ThemeEditor theme={app.theme} schema={schema} />}
    </div>
  );
};
export default AppTheme;
