import React, { MouseEvent } from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import AppVisibilityModal from "./AppVisibilityModal";

// Interfaces
import { AppData } from "../models/model";

interface AppVisibilityProps {
  app: AppData;
  displayAppVisibilityModal: boolean;
  setAppVisibilityTrigger: (value: boolean) => void;
}

type EventType = MouseEvent | KeyboardEvent | undefined;

const AppVisibility: React.FC<AppVisibilityProps> = ({
  app,
  displayAppVisibilityModal,
  setAppVisibilityTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = (event: EventType) => {
    event?.stopPropagation();
    setAppVisibilityTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setAppVisibilityTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayAppVisibilityModal}
      onHide={(event: EventType) => onCancel(event)}
      headerText={`Make ${app.name} ${app.isPublic ? "private" : "public"}?`}
      modalContent={
        <AppVisibilityModal
          app={app}
          onCancel={(event: EventType) => onCancel(event)}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default AppVisibility;
