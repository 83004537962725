import React, { useState, useEffect, useContext } from "react";

import { useGetUserDetails } from "../hooks/useAPIs";
import Dropdown from "./Dropdown";

// Interfaces
import { AccessType } from "../models/model";

import { AuthContext } from "../App";
import Modal from "./Modal";
import { useModal } from "../hooks/useModal";
import ChangeAccessModal from "./ChangeAccessModal";
import config from "../config/config";

interface CollaboratorCardProps {
  appId: string;
  userId: string;
  accessType: AccessType;
  currentUserAccessType: AccessType | undefined;
}

interface AccessDropDownListProps {
  id: string;
  text: string;
  clickHandler: () => void;
}

const CollaboratorCard: React.FC<CollaboratorCardProps> = ({
  appId,
  userId,
  accessType,
  currentUserAccessType,
}) => {
  const [selectedAccessType, setSelectedAccessType] = useState(accessType);
  const [allowEdit, setAllowEdit] = useState(false);
  const getUserDetails = useGetUserDetails(userId);
  const { currentUser } = useContext(AuthContext);

  // revoke access modal
  const { isModalDisplayed, toggleModal } = useModal();
  const onCancel = () => toggleModal();

  // Change user access modal
  const {
    isModalDisplayed: isChangeAccessModalDisplayed,
    toggleModal: toggleChangeAccessModal,
  } = useModal();
  const onCancelChangeAccess = () => toggleChangeAccessModal();

  const accessTypeLabels = {
    owner: "Owner",
    write: "Editor",
    read: "Viewer",
  };

  const handleClick = (accessType: AccessType) => {
    setSelectedAccessType(accessType);
    toggleChangeAccessModal();
  };

  const accessDropDownList: AccessDropDownListProps[] = [
    {
      id: AccessType.owner,
      text: accessTypeLabels[AccessType.owner],
      clickHandler: () => handleClick(AccessType.owner),
    },
    {
      id: AccessType.write,
      text: accessTypeLabels[AccessType.write],
      clickHandler: () => handleClick(AccessType.write),
    },
    {
      id: AccessType.read,
      text: accessTypeLabels[AccessType.read],
      clickHandler: () => handleClick(AccessType.read),
    },
  ];

  useEffect(() => {
    if (userId === config.collaboratorId) {
      setAllowEdit(false);
    } else if (
      currentUserAccessType === AccessType.owner ||
      (currentUserAccessType === AccessType.write &&
        accessType !== AccessType.owner)
    ) {
      setAllowEdit(true);
    }
  }, [currentUserAccessType, userId, accessType]);

  const accessFilter = (access: AccessDropDownListProps) => {
    if (currentUserAccessType === AccessType.owner) {
      return true;
    }
    if (
      currentUserAccessType === AccessType.write &&
      access.id !== AccessType.owner
    ) {
      return true;
    }
  };

  return (
    <div className="collaborator-card">
      {getUserDetails.isSuccess && (
        <>
          {currentUser?.email === getUserDetails?.data?.user?.email ? (
            <>
              {currentUser?.avatar && (
                <img
                  className="collaborator-avatar"
                  src={currentUser?.avatar}
                  alt="avatar"
                />
              )}
            </>
          ) : (
            <>
              {getUserDetails.data.user?.avatar ? (
                <img
                  className="collaborator-avatar"
                  src={getUserDetails.data.user?.avatar}
                  alt="avatar"
                />
              ) : (
                <div className="avatar-placeholder">
                  <p className="avatar-letter">
                    {getUserDetails.data.user?.name[0]}
                  </p>
                </div>
              )}
            </>
          )}
          <p>{getUserDetails.data.user?.name}</p>
          <p>{getUserDetails.data.user?.email}</p>
          {currentUser?.id !== userId && allowEdit && (
            <button className="revoke-btn" onClick={toggleModal}>
              Revoke access
            </button>
          )}
          {allowEdit && (
            <Dropdown
              activatorText={accessTypeLabels[accessType]}
              items={accessDropDownList.filter(accessFilter)}
            />
          )}
          {!allowEdit && <p>{accessTypeLabels[accessType]}</p>}
        </>
      )}
      {/* Revoke access modal */}
      {userId && allowEdit && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Revoke access"
          modalContent={
            <ChangeAccessModal
              type="revoke"
              appId={appId}
              userId={userId}
              onCancel={onCancel}
            />
          }
        />
      )}
      {/* Change access modal */}
      {userId && (
        <Modal
          isModalDisplayed={isChangeAccessModalDisplayed}
          onHide={toggleChangeAccessModal}
          headerText="Change access"
          modalContent={
            <ChangeAccessModal
              type="change"
              selectedAccessType={selectedAccessType}
              appId={appId}
              userId={userId}
              onCancel={onCancelChangeAccess}
            />
          }
        />
      )}
    </div>
  );
};

export default CollaboratorCard;
