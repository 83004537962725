import React from "react";

interface IAssetActionButtonsProps {
  handleAssetsUpload: () => void;
}
const AssetsActionButtons: React.FC<IAssetActionButtonsProps> = ({
  handleAssetsUpload,
}) => {
  return (
    <div className="assets-actions__buttons">
      {/* <button className="button__secondary">Add font</button> */}
      <button className="button__primary" onClick={handleAssetsUpload}>
        Add assets
      </button>
    </div>
  );
};

export default AssetsActionButtons;
