import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";
import { getTranslation, updateTranslation } from "../hooks/useFirebase";
import { EnsembleTranslationData } from "../config/interfaces";
import { YamlEditor } from "./YamlEditor";
import { AuthContext } from "../App";
import "./TranslationEditor.sass";
import {
  getTranslationLanguageFlag,
  getTranslationLanguageName,
} from "../utils/Util";
import { useQueryClient } from "react-query";

export const TranslationEditor: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const { app, isAppReadOnly } = useAppContext();
  const { languageCode } = useParams();
  const queryClient = useQueryClient();
  const [isPreviouslyArchived, setIsPreviouslyArchived] =
    useState<boolean>(false);

  const [initialLoadCompleted, setInitialLoadCompleted] =
    useState<boolean>(false);

  const [editorContent, setEditorContent] = useState<string | undefined>(
    undefined,
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (languageCode) {
      getTranslation(app.id, languageCode).then(
        (translation: EnsembleTranslationData | undefined) => {
          setEditorContent(translation?.content ?? "");
          setInitialLoadCompleted(true);

          // UI shows special message if language was previously archived
          if (translation?.isArchived) {
            setIsPreviouslyArchived(true);
          }
        },
      );
    }
  }, [app.id, languageCode]);

  useEffect(() => {
    if (initialLoadCompleted && editorContent != undefined) {
      setHasChanges(true);
    }
    // below is intentional to distinguish between initial load and user changes
  }, [editorContent]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveContent = useCallback(() => {
    if (editorContent && currentUser && languageCode) {
      updateTranslation(currentUser, app.id, languageCode, editorContent).then(
        (result) => {
          if (result) {
            setHasChanges(false);
            queryClient.invalidateQueries(["app", app.id]).then();
          }
        },
      );
    }
  }, [app.id, currentUser, editorContent, languageCode, queryClient]);

  if (!currentUser || !languageCode || languageCode.length !== 2) {
    return <div>No language found</div>;
  }

  return (
    <div className="screen-content" key={app.id}>
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/translations`}>Translations</Link>
      </div>

      <div className="page-header">
        <div>
          <div className={"translation-header"}>
            <span style={{ fontSize: "40px" }}>
              {getTranslationLanguageFlag(languageCode)}
            </span>
            <h1>{getTranslationLanguageName(languageCode)}</h1>
          </div>
          {isPreviouslyArchived && (
            <p className="translation-header-memo">
              This language was previously archived with the content shown
              below. It will be restored when the content is updated.
            </p>
          )}
          <p className="title"></p>
        </div>

        <div className="page-actions">
          {!isAppReadOnly && (
            <button
              className="button__primary"
              onClick={saveContent}
              disabled={!hasChanges}
            >
              Save
            </button>
          )}
        </div>
      </div>

      {editorContent != undefined && (
        <YamlEditor
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          onSaveRequest={saveContent}
        />
      )}
    </div>
  );
};
