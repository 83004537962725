import React, { useContext } from "react";
import { useGetUserDetails } from "../hooks/useFirebase";
import { AuthContext } from "../App";

type Props = {
  collaboratorId: string;
};

const CollaboratorAvatar = ({ collaboratorId }: Props) => {
  const getUserDetails = useGetUserDetails(collaboratorId);
  const { currentUser } = useContext(AuthContext);

  const collaboratorName = getUserDetails?.data?.user?.name;
  const collaboratorEmail = getUserDetails?.data?.user?.email;

  return (
    <div>
      {currentUser?.email === collaboratorEmail ? (
        <>
          {currentUser?.avatar && (
            <img
              className="collaborator-avatar"
              src={currentUser?.avatar}
              alt="avatar"
              title={`${currentUser?.name} (${currentUser?.email})`}
            />
          )}
        </>
      ) : (
        <>
          {getUserDetails?.data?.user && (
            <>
              {getUserDetails?.data.user?.avatar ? (
                <img
                  className="collaborator-avatar"
                  src={getUserDetails.data.user?.avatar}
                  alt="avatar"
                  title={`${collaboratorName} (${collaboratorEmail})`}
                />
              ) : (
                <div
                  className="avatar-placeholder"
                  title={`${collaboratorName} (${collaboratorEmail})`}
                >
                  <p className="avatar-letter">
                    {collaboratorName && collaboratorName[0]}
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CollaboratorAvatar;
