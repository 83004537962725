import React from "react";

// APIs
import { useModal } from "../../../hooks/useModal";

// Components
import Modal from "../../Modal";
import ScriptArchiveModal from "./ScriptArchiveModal";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface ScriptArchiveProps {
  script: EnsembleWidgetData;
  displayArchiveModal: boolean;
  setArchiveTrigger: (val: boolean) => void;
}

const ScriptArchive: React.FC<ScriptArchiveProps> = ({
  script,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={onCancel}
      headerText={`Archive ${script.name}?`}
      modalContent={
        <ScriptArchiveModal
          script={script}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScriptArchive;
