import React, { useMemo, useState } from "react";
import { Menu, Dropdown, Tooltip } from "antd";
import {
  getLanguageCode,
  getTranslationLanguageFlag,
  languageMap,
} from "../utils/Util";
import { RemixIcon } from "./Widgets";
import { useAppContext } from "../pages/AppPagesWrapper";
import "./LanguageSelector.sass";
import noLanguage from "../assets/graphics/no_language.png";
import { SESSION_FORCED_LOCALE } from "../utils";

interface LanguageSelectorProps {
  onLanguageSelected: (languageCode: string | null) => void;
}

// Enable selecting a language in the Preview
export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onLanguageSelected,
}) => {
  const { app } = useAppContext();
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    sessionStorage.getItem(SESSION_FORCED_LOCALE),
  );

  const selectLanguage = (selectedEntry: { key: string }) => {
    const languageCode = selectedEntry.key == "" ? null : selectedEntry.key;
    setSelectedLanguage(languageCode);
    onLanguageSelected(languageCode);
  };

  const overlayContent = useMemo(() => {
    if (app.translations && app.translations.length > 0) {
      const menuItems = app.translations?.map((language) => {
        const languageCode = getLanguageCode(language);
        return (
          <Menu.Item key={languageCode}>
            <div className={"translation-add-language-menu-item"}>
              <span style={{ fontSize: "26px" }}>
                {getTranslationLanguageFlag(languageCode)}{" "}
              </span>
              <span>{languageMap[languageCode].name}</span>
            </div>
          </Menu.Item>
        );
      });
      // add the default behavior of using the user's locale
      menuItems?.unshift(
        <Menu.Item key={""}>
          <div className={"translation-add-language-menu-item"}>
            <span style={{ fontSize: "22px" }}>
              <RemixIcon name={"earth-line"} />
            </span>
            <span>Automatic</span>
          </div>
        </Menu.Item>,
      );
      return (
        <Menu
          className={"translation-add-language-menu"}
          onClick={selectLanguage}
        >
          {menuItems}
        </Menu>
      );
    } else {
      return (
        <div className={"language-selector-dropdown-no-language"}>
          <img src={noLanguage} alt={"no language added"} />
          <div>Get started by adding a language</div>
          <div className={"no-language-action"}>
            <a
              href={`/app/${app?.id}/translations`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Add Translation </span>
              <RemixIcon
                name={"external-link-line"}
                style={{ fontSize: "18px" }}
              />
            </a>
          </div>
        </div>
      );
    }
  }, [app?.id, app.translations]); // eslint-disable-line

  return (
    <Dropdown overlay={overlayContent} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Tooltip title="Select a language" placement={"top"}>
          <div className={"language-selector-anchor"}>
            {selectedLanguage ? (
              <div className={"language-selector-anchor-language"}>
                <span>{getTranslationLanguageFlag(selectedLanguage)}</span>
              </div>
            ) : (
              <div className={"language-selector-anchor-default"}>
                <RemixIcon name={"earth-line"} />
              </div>
            )}
          </div>
        </Tooltip>
      </a>
    </Dropdown>
  );
};
