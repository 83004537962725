import React from 'react';

const DemoAppBadge: React.FC = ()  => {
  return (
    <span className='demo-app-badge'>
      Demo app
    </span>
  );
}

export default DemoAppBadge;
