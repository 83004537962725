import React from "react";

// APIs
import { useModal } from "../hooks/useModal"; // Adjust the import path as needed

// Components
import Modal from "./Modal";
import AppGetPrepublishReportModal from "./AppGetPrepublishReportModal"; // Adjust the import path as needed

// Interfaces
import { AppData } from "../models/model"; // Adjust the import path as needed

interface AppGetPrepublishReportProps {
  app: AppData;
  displayReportModal: boolean;
  setReportTrigger: (value: boolean) => void;
}

const AppGetPrepublishReport: React.FC<AppGetPrepublishReportProps> = ({
  app,
  displayReportModal,
  setReportTrigger,
}) => {
  const { toggleModal } = useModal();

  const closeModal = () => {
    setReportTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayReportModal}
      onHide={closeModal}
      headerText={``}
      cancellable={false}
      modalContent={
        <AppGetPrepublishReportModal app={app} closeModal={closeModal} />
      }
    />
  );
};

export default AppGetPrepublishReport;
