import React, { useState, createRef, useEffect, useContext } from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import { AuthContext } from "../App";

// APIs
import { useUpdateScreen } from "../hooks/useAPIs";

// Interfaces
import {
  EnsembleCategoriesData,
  EnsembleScreenData,
} from "../config/interfaces";

interface ScreenCategoryModalProps {
  screen: EnsembleScreenData;
  categories: EnsembleCategoriesData[] | undefined;
  onCancel: () => void;
  onSubmit: () => void;
}

const ScreenCategoryModal: React.FC<ScreenCategoryModalProps> = ({
  screen,
  categories,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [categoryName, setCategoryName] = useState(screen.category);
  const updateScreenQuery = useUpdateScreen(currentUser, app.id, screen.id);
  const categoryNameInputRef = createRef<HTMLInputElement>();
  const submitButtonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    categoryNameInputRef.current?.focus();
  }, [categoryNameInputRef]);

  const handleInputChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ): void => {
    setCategoryName(event.currentTarget.value);
  };

  // trigger update
  const renameCategory = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    updateScreenQuery.mutate({
      category: categoryName,
    });
  };

  // update complete
  useEffect(() => {
    if (updateScreenQuery.isSuccess) onSubmit();
  }, [onSubmit, updateScreenQuery.isSuccess]);

  return (
    <form className="app-create-modal" onSubmit={renameCategory}>
      <select value={categoryName} onChange={handleInputChange}>
        <option value={``}>{``}</option>
        {categories &&
          categories.map((c) => (
            <option value={c.id} key={c.id}>
              {c.name}
            </option>
          ))}
      </select>
      <br />
      <button type="submit" ref={submitButtonRef} className="button__primary">
        {updateScreenQuery.isIdle && <>Save</>}
        {updateScreenQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default ScreenCategoryModal;
