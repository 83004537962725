import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../App";

// Assets
import { ReactComponent as IconHome } from "../assets/nav_home.svg";
import { ReactComponent as IconScreens } from "../assets/nav_screens.svg";
import { ReactComponent as IconWidgets } from "../assets/widget/custom.svg";
import { ReactComponent as IconTheme } from "../assets/nav_theme.svg";
import { ReactComponent as IconAsset } from "../assets/nav_asset.svg";
import { ReactComponent as IconSettings } from "../assets/nav_settings.svg";
import { ReactComponent as IconCollaborators } from "../assets/icon_collaborators.svg";
import { ReactComponent as IconScript } from "../assets/icon_script.svg";
import { ReactComponent as IconDocs } from "../assets/nav_docs.svg";
import { ReactComponent as IconDiscord } from "../assets/nav_discord.svg";
import { ReactComponent as Logo } from "../assets/nav_logo.svg";
import { ReactComponent as NavOpen } from "../assets/nav_open.svg";
import { ReactComponent as NavClose } from "../assets/nav_close.svg";
import { AppData } from "../models/model";

//Modal
import SubscriptionPlansModal from "../components/modals/SubscriptionPlans/SubscriptionPlansModal";
import { PlanIcon } from "../components/cards/SubscriptionCard";

//Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";
import { RemixIcon } from "./Widgets";

const Nav: React.FC<{ appData: AppData | null }> = ({ appData }) => {
  const { currentUser } = useContext(AuthContext);
  const showSubscribtion = false; // hide subscription for now
  const matches = useMediaQuery("(min-width: 768px)");

  const [subscriptionPlanTrigger, setSubscriptionPlanTrigger] =
    useState<boolean>(false);

  const handleNavbarCollapse = useCallback(() => {
    let isOpen = true;
    const navStorage = localStorage.getItem("nav") as string;
    const previous: Record<string, unknown> = JSON.parse(navStorage);
    if (!matches) isOpen = matches;
    else if (currentUser) {
      if (previous?.id === currentUser?.id) isOpen = !!previous?.open;
      else {
        localStorage.setItem(
          "nav",
          JSON.stringify({
            id: currentUser.id,
            open: previous?.open === undefined ? true : !!previous?.open,
          }),
        );
        isOpen = !!previous?.open;
      }
    } else isOpen = !!previous?.open;
    return isOpen;
  }, [currentUser, matches]);

  const [open, setOpen] = useState<boolean>(handleNavbarCollapse());
  const location = useLocation();
  /*checks if user's on the editor page*/
  const isEditorScreen = /\/screen\/[^\/]+$/; // eslint-disable-line
  const isWidgetScreen = /\/widget\/[^\/]+$/; // eslint-disable-line

  useLayoutEffect(() => {
    setOpen(handleNavbarCollapse());
  }, [currentUser, matches, handleNavbarCollapse]);

  const handleIsOpen = () => {
    if (currentUser)
      localStorage.setItem(
        "nav",
        JSON.stringify({ id: currentUser.id, open: !open }),
      );
    setOpen(!open);
  };

  const activeClassName = "active";
  return isEditorScreen.test(location.pathname) ||
    isWidgetScreen.test(location.pathname) ? null : (
    <nav className={open ? "nav_open" : undefined}>
      <ul className="nav_main">
        <li className={"nav_menu_header"}>
          <NavLink to="/">
            <Logo />
            <span className={"nav_menu_label"}>Ensemble</span>
          </NavLink>
        </li>
        {currentUser && (
          <>
            <li className={"nav_menu_item"}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                <IconHome />
                <span className={"nav_menu_label"}>Home</span>
                <span className={"nav_tooltip"}>Home</span>
              </NavLink>
            </li>

            {/* app navigation */}
            {appData && (
              <>
                <li className="nav_divider"></li>
                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/screens`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                    end
                  >
                    <IconScreens />
                    <span className={"nav_menu_label"}>Screens</span>
                    <span className={"nav_tooltip"}>Screens</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/widgets`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                    end
                  >
                    <IconWidgets />
                    <span className={"nav_menu_label"}>Widgets</span>
                    <span className={"nav_tooltip"}>Widgets</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/theme`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <IconTheme />
                    <span className={"nav_menu_label"}>Theme</span>
                    <span className={"nav_tooltip"}>Theme</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/assets`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <IconAsset />
                    <span className={"nav_menu_label"}>Assets</span>
                    <span className={"nav_tooltip"}>Assets</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/scripts`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <IconScript />
                    <span className={"nav_menu_label"}>Scripts</span>
                    <span className={"nav_tooltip"}>Scripts</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/translations`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <RemixIcon name="earth-line" style={{ fontSize: 24 }} />
                    <span className={"nav_menu_label"}>Translations</span>
                    <span className={"nav_tooltip"}>Translations</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/collaborators`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <IconCollaborators />
                    <span className={"nav_menu_label"}>Collaborators</span>
                    <span className={"nav_tooltip"}>Collaborators</span>
                  </NavLink>
                </li>

                <li className={"nav_menu_item"}>
                  <NavLink
                    to={`/app/${appData.id}/settings`}
                    className={({ isActive }) =>
                      isActive ? activeClassName : undefined
                    }
                  >
                    <IconSettings />
                    <span className={"nav_menu_label"}>Settings</span>
                    <span className={"nav_tooltip"}>Settings</span>
                  </NavLink>
                </li>
                <li className="nav_divider"></li>
              </>
            )}
          </>
        )}
        <li className={"nav_menu_item"}>
          <NavLink
            to="/resources"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            <IconDocs />
            <span className={"nav_menu_label"}>Docs</span>
            <span className={"nav_tooltip"}>Docs</span>
          </NavLink>
        </li>
        <li className={"nav_menu_item"}>
          <a
            href="https://discord.gg/cEHkJTmn75"
            target="_blank"
            rel="noreferrer"
          >
            <IconDiscord />
            <span className={"nav_menu_label"}>Community</span>
            <span className={"nav_tooltip"}>Community</span>
          </a>
        </li>
      </ul>

      <ul className="nav_footer">
        {showSubscribtion &&
          currentUser?.subscriptionPlan &&
          (open ? (
            <div className="subscription_plan">
              <div className="icon_section">
                <PlanIcon
                  planName={currentUser?.subscriptionPlan?.planDetails?.name}
                />
                <span>{currentUser?.subscriptionPlan?.planDetails?.name}</span>
              </div>
              {
                <h6 onClick={() => setSubscriptionPlanTrigger(true)}>
                  View plans & upgrade
                </h6>
              }
            </div>
          ) : (
            <div
              className="nav_collapsable"
              onClick={() => setSubscriptionPlanTrigger(true)}
            >
              <PlanIcon
                planName={currentUser?.subscriptionPlan?.planDetails?.name}
              />
            </div>
          ))}
        {currentUser && currentUser.avatar ? (
          <li className={"nav_menu_item"}>
            <NavLink
              style={{ padding: 0 }}
              to="/account"
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              <img
                alt={"user"}
                className={"profile-avatar"}
                src={
                  currentUser?.avatar ??
                  "https://via.placeholder.com/300/000.png/000"
                }
              />
              <span
                className={"nav_menu_label"}
                style={{ marginLeft: "0.5rem" }}
              >
                Account
              </span>
              <span className={"nav_tooltip"}>Account</span>
            </NavLink>
          </li>
        ) : null}
        <li className="nav_divider"></li>
        <li className={"nav_collapsable"} onClick={handleIsOpen}>
          {open ? <NavClose /> : <NavOpen />}
          <span className={"nav_tooltip"}>Expand</span>
        </li>
      </ul>
      <SubscriptionPlansModal
        displayModal={subscriptionPlanTrigger}
        handleSubscriptionPlanTrigger={() => setSubscriptionPlanTrigger(false)}
      />
    </nav>
  );
};

export default Nav;
