import React from "react";
import MobileStoreButton from "./MobileStoreButton";
import qr from "../assets/download-qr.png";

interface IProps {
  containerStyle?: object;
}

const DownloadStore: React.FC<IProps> = ({ containerStyle }: IProps) => {
  return (
    <div style={containerStyle} className={"download_store_container"}>
      <div className={"download_store_item"}>
        <h3>Try Ensemble apps on your device</h3>
        <p>
          Download Ensemble Preview to check out demo apps or any app you build
          with Ensemble
        </p>
        <div style={{ display: "flex" }}>
          <MobileStoreButton
            store={"Ios"}
            url={"https://testflight.apple.com/join/yFKnLQ1S"}
          />
          <MobileStoreButton
            store={"Android"}
            url={
              "https://play.google.com/store/apps/details?id=com.ensembleui.preview"
            }
          />
        </div>
      </div>
      <div className={"download_store_item_qr"}>
        <img src={qr} />
      </div>
    </div>
  );
};

export default DownloadStore;
