import React from 'react';

// Components
import YoutubeEmbed from '../components/YouTubeEmbed';


import { ReactComponent as IconForum } from '../assets/icon_forum.svg';
import { ReactComponent as IconDocs } from '../assets/icon_documentation.svg';
import { ReactComponent as IconBug } from '../assets/icon_bug.svg';
import { ReactComponent as IconSupport } from '../assets/icon_support.svg';


const Resources: React.FunctionComponent = props => {

  return (
    <div className='page-ct'>
      <div className='resources-content'>
        <h1>Resources</h1>
        <div className='resource-cards'>
          <div>
            <div>
            <div className='icon'><IconDocs /></div>
              <h4>Documentation</h4>
              <p>Learn the basics, see FAQs, and more.</p>
            </div>
            <a href="https://docs.ensembleui.com/" target="_blank">Go to docs</a>
          </div>
          <div>
            <div>
            <div className='icon'><IconForum /></div>
              <h4>Discussion forum</h4>
              <p>Share ideas or ask questions on GitHub.</p>
            </div>
            <a href="https://github.com/EnsembleUI/ensemble/discussions" target="_blank">Visit GitHub</a>
          </div>
          <div>
            <div>
            <div className='icon'><IconBug /></div>
              <h4>Bug or feature requests</h4>
              <p>For any bugs or missing functionality, open an issue on GitHub.</p>
              </div>
            <a href="https://github.com/EnsembleUI/ensemble/issues/new" target="_blank">Open issue</a>
          </div>

        </div>
        <hr />
        <h4>New to Ensemble? Checkout this short intro video</h4>
        <br />
        <YoutubeEmbed embedId="iH9RQQtRkLI" />
      </div>
    </div>
  );
}

export default Resources;
