import React, { useState, useContext, useMemo, useEffect } from "react";
import { AuthContext } from "../App";
import { useGetPrepublishReport, useMergeApps } from "../hooks/useAPIs"; // Adjust the import path as needed
import PublishingReport, { InputJson, Changeset } from "./PublishingReport"; // Adjust the import path as needed
import { ReactComponent as IconChecboxFill } from "../assets/widget/checkbox-circle-fill.svg";

// Interfaces
import { AppData } from "../models/model"; // Adjust the import path as needed
import { useQueryClient } from "react-query";
import PublishHeader from "../containers/PublishHeader/PublishHeader";
import { Link } from "react-router-dom";

export interface Publishedset {
  destinationAppId: string;
  sourceAppId: string;
  updated: string[];
  added: string[];
  archived: string[];
}
interface AppGetPrepublishReportModalProps {
  app: AppData;
  closeModal: () => void;
}

const AppGetPrepublishReportModal: React.FC<
  AppGetPrepublishReportModalProps
> = ({ app, closeModal }) => {
  const queryClient = useQueryClient();
  const allApps: AppData[] | undefined = queryClient.getQueryData("apps");
  const { currentUser } = useContext(AuthContext);
  const [destinationAppId, setDestinationAppId] = useState("");
  const [report, setReport] = useState<InputJson | null>(null);
  const [isAppPublished, setIsAppPublished] = useState<boolean>(false);

  const appsOwned = useMemo(() => {
    return allApps?.filter((app) => app.accessType === "owner");
  }, [allApps]);

  const setCheckedInReport = (report: InputJson): InputJson => {
    Object.keys(report.data.changeset).forEach((categoryKey) => {
      const category = categoryKey as keyof Changeset;
      report.data.changeset[category] = report.data.changeset[category]!.map(
        (artifact) => ({
          ...artifact,
          isChecked: true, // Set isChecked to true for all artifacts
        }),
      );
    });
    return report;
  };

  const getPrepublishReport = useGetPrepublishReport(
    app?.id as string,
    destinationAppId,
    currentUser!.id,
  );

  const filteredChangeSet = () => {
    let data: Changeset = {
      add: [],
      archive: [],
      update: [],
    };
    Object.entries((report as InputJson)?.data?.changeset || {}).forEach(
      ([key, value]) => {
        data = {
          ...data,
          [key]: value.filter((val: any) => val.isChecked),
        };
      },
    );

    return data;
  };

  const modifyAsPerPublishedInfo = (data: Publishedset) => {
    if (report) {
      report.data.changeset = {
        update: report.data.changeset.update.filter((f) =>
          data.updated.findIndex((d) => f.path === d),
        ),
        add: report.data.changeset.add.filter((f) =>
          data.added.findIndex((d) => f.path === d),
        ),
        archive: report.data.changeset.archive.filter((f) =>
          data.archived.findIndex((d) => f.path === d),
        ),
      };
    }
    return report;
  };

  const handleCheckboxChange = (
    category: keyof Changeset,
    path: string,
    isChecked: boolean,
  ) => {
    const inputJson = report as InputJson;
    const index = inputJson.data.changeset[category]!.findIndex(
      (artifact) => artifact.path === path,
    );
    inputJson.data.changeset[category]![index].isChecked = isChecked;
    setReport({ ...inputJson });
  };

  const mergeApps = useMergeApps(
    app?.id as string,
    destinationAppId,
    currentUser!.id,
    filteredChangeSet(),
  );
  const handlePublish = () => {
    mergeApps.mutate();
  };

  useEffect(() => {
    if (getPrepublishReport.isSuccess) {
      const data = getPrepublishReport.data as InputJson;
      const checkedInReport = setCheckedInReport(data);
      setReport({
        ...checkedInReport,
      });
      setIsAppPublished(false);
    }
  }, [getPrepublishReport.isSuccess, getPrepublishReport.data]);

  useEffect(() => {
    if (mergeApps.isSuccess) {
      if (mergeApps.data.data) {
        setIsAppPublished(true);
        setReport(
          modifyAsPerPublishedInfo(mergeApps.data.data as Publishedset),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeApps.isSuccess, closeModal]);

  const handleGetReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setReport(null);
    setIsAppPublished(false);
    getPrepublishReport.mutate();
  };
  const disablePublishBtn = useMemo(
    () =>
      !report || !report.data || !report.data.changeset || mergeApps.isLoading,
    [report, mergeApps.isLoading],
  );

  return (
    <div className="editor-page">
      <PublishHeader app={app as AppData} onClick={closeModal} />
      <div className="app-publish-modal">
        <form onSubmit={handleGetReport}>
          <div>
            <label>Source app</label>
            <input
              type="text"
              value={app?.name}
              disabled
              placeholder="Source App"
            />
          </div>
          <div>
            <label>Target app</label>
            <select
              placeholder="Destination App"
              value={destinationAppId}
              onChange={(e) => setDestinationAppId(e.target.value)}
            >
              <option value={""}>Please select</option>
              {appsOwned?.map((app) => (
                <option key={app.id} value={app.id}>
                  {app.name}
                </option>
              ))}
            </select>
          </div>
          {!isAppPublished && (
            <>
              <button
                disabled={!destinationAppId}
                type="submit"
                className={`button__primary ${
                  !destinationAppId ? "disable-button" : ""
                }`}
              >
                {!getPrepublishReport.isLoading && <>Compare</>}
                {getPrepublishReport.isLoading && <>Comparing...</>}
              </button>
              <button
                type="button"
                disabled={disablePublishBtn}
                className={`button__primary ${
                  disablePublishBtn ? "disable-button" : ""
                }`}
                onClick={handlePublish}
              >
                {!mergeApps.isLoading && <>Publish</>}
                {mergeApps.isLoading && <>Publishing...</>}
              </button>
            </>
          )}
          {isAppPublished && (
            <Link
              type="button"
              to={`/app/${report?.data.destinationAppId}/screens`}
              className={`button__link_app button__primary`}
            >
              Go to published app
            </Link>
          )}
        </form>
        {!destinationAppId && <h4>Choose target app to view differences</h4>}
        {getPrepublishReport.isLoading && (
          <>
            <h4>Preparing pre-publish report</h4>
          </>
        )}
        {report && report.data && report.data.changeset ? (
          <>
            {!isAppPublished && (
              <>
                <h4>Your app is ready to be published.</h4>
                <h2>Pre-publish report</h2>
              </>
            )}
            {isAppPublished && (
              <div className="icon_container">
                <IconChecboxFill />
                <span>App published successfully.</span>
              </div>
            )}
            <div className="publish-report-container">
              <PublishingReport
                isAppPublished={isAppPublished}
                inputJson={report as InputJson}
                handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AppGetPrepublishReportModal;
