import React from "react";

// APIs
import { useModal } from "../../../hooks/useModal";

// Components
import Modal from "../../Modal";
import WidgetRenameModal from "./WidgetRenameModal";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface WidgetRenameProps {
  widget: EnsembleWidgetData;
  displayRenameModal: boolean;
  setRenameTrigger: any;
}

const WidgetRename: React.FC<WidgetRenameProps> = ({
  widget,
  displayRenameModal,
  setRenameTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setRenameTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    toggleModal();
    setRenameTrigger(false);
  };

  return (
    <Modal
      isModalDisplayed={displayRenameModal}
      onHide={onCancel}
      headerText="Rename widget"
      modalContent={
        <WidgetRenameModal
          widget={widget}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default WidgetRename;
