import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { OptionItem } from "../utils/rendererUtils";
import { DropdownWidget } from "./DropdownWidget";
import { IconSelectWidget } from "./IconSelectWidget";

export interface IconOptionItem extends OptionItem {
  icon: string;
}

export const SelectWidget: React.FC<WidgetProps> = (props) => {
  const { options } = props;

  const items = options.enumOptions
    ?.filter((option) => option.schema?.icon)
    .map((option) => ({
      value: option.value,
      icon: option.schema!.icon,
      description: option.schema!.description,
    }));

  if (items && items.length > 0) {
    return <IconSelectWidget {...props} iconOptions={items} />;
  }
  return <DropdownWidget {...props} />;
};
