import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { useAppContext } from "./AppPagesWrapper";

// Components
import AppConfig from "../components/AppConfig";
import { useQuery } from "react-query";
import { getAppConfig, getSecrets } from "../hooks/useAPIs";
import Environment from "../components/Environment";
import { Secrets } from "../containers/Secrets";

export const APP_CONFIG_QUERY_ID = "appConfig";
export const SECRETS_QUERY_ID = "secretsQuery";

const AppSettings: React.FunctionComponent = () => {
  const { app } = useAppContext();

  const appConfigQuery = useQuery(APP_CONFIG_QUERY_ID, () =>
    getAppConfig(app.id),
  );

  const secretsQuery = useQuery(SECRETS_QUERY_ID, () => getSecrets(app.id));

  useEffect(() => {
    document.title = app.name + " settings";
  }, [app.name]);

  return (
    <div className="app-settings-content">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <h1>Settings</h1>
      </div>

      <h3>App ID</h3>
      <p className="small">{app.id}</p>

      <div
        style={{ background: "white", padding: "2px", display: "inline-block" }}
      >
        <QRCode
          size={120}
          value={app?.id}
          style={{ display: "inline-block" }}
        />
      </div>
      <hr />

      <h3>App Settings</h3>
      {appConfigQuery.isSuccess && (
        <>
          <AppConfig
            appId={app.id}
            appConfig={appConfigQuery.data || undefined}
            isLoading={appConfigQuery.isFetching || appConfigQuery.isLoading}
          />
        </>
      )}
      <hr />

      <h3>Environment Variables</h3>
      {appConfigQuery.isSuccess && (
        <Environment
          appId={app.id}
          envVariables={appConfigQuery.data?.envVariables}
          isLoading={appConfigQuery.isFetching || appConfigQuery.isLoading}
        />
      )}
      <hr />

      <h3>Secrets</h3>
      {secretsQuery.isSuccess && (
        <Secrets
          appId={app.id}
          secrets={secretsQuery.data?.secrets}
          isLoading={appConfigQuery.isFetching || appConfigQuery.isLoading}
        />
      )}
      <hr />
    </div>
  );
};
export default AppSettings;
