import React, { useEffect, MouseEvent, useCallback } from "react";
import ReactDOM from "react-dom";

import close_icon from "../assets/icon_close.svg";

export interface ModalProps {
  isModalDisplayed: boolean;
  onHide: (event?: MouseEvent | KeyboardEvent) => void;
  modalContent: JSX.Element;
  headerText: string;
  cancellable?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isModalDisplayed,
  onHide,
  modalContent,
  headerText,
  cancellable = true,
}) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isModalDisplayed) {
        event.stopPropagation();
        onHide(event);
      }
    },
    [onHide, isModalDisplayed],
  );

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onHide(event);
    },
    [onHide],
  );

  const modalStopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    isModalDisplayed
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isModalDisplayed, onKeyDown]);

  const modal = (
    <div className="modal" onClick={modalStopPropagation}>
      <div className="modal-backdrop" onClick={onClick} />
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="modal-content__header">
            <h3>{headerText}</h3>
            {cancellable ? (
              <button className="button__icon" onClick={onClick}>
                <img src={close_icon} alt="Close" />
              </button>
            ) : null}
          </div>
          <div className="modal-content__body">{modalContent}</div>
        </div>
      </div>
    </div>
  );

  return isModalDisplayed ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
