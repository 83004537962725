import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import config from "./config";
import { getFunctions, httpsCallable } from "firebase/functions";

export const firebaseApp = firebase.initializeApp(config.firebase);
export const auth = getAuth(firebaseApp);
export const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

export const cloudFunctions = {
  studio_get_assets: httpsCallable<unknown, any>(
    getFunctions(),
    "studio-getAssets",
  ),
  studio_upload_asset: httpsCallable(getFunctions(), "studio-uploadAsset"),
  studio_delete_asset: httpsCallable(getFunctions(), "studio-deleteAsset"),
};
