import { CompositeObjectFieldTemplateProps } from "./ObjectFieldTemplate";
import React from "react";
import { Tooltip } from "antd";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import { RemixIcon } from "../../../components/Widgets";

// variant of ObjectFieldTemplate that renders additionalProperties={} as key/value pair
export const KeyValuePairsFieldTemplate: React.FC<
  CompositeObjectFieldTemplateProps
> = ({ props, groupName }) => {
  const { schema, properties, onAddClick } = props;

  return (
    <div>
      {/* mimic FieldTemplate to show the label and Add button*/}
      <div className="custom-field-template-content">
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={schema.description}
          placement={"left"}
        >
          <label className={`field-label ${schema.required ? "required" : ""}`}>
            {camelCaseToWords(groupName || "")}
          </label>
        </Tooltip>

        <div className={"field-widget"}>
          <button
            className={"array-field-add-button"}
            onClick={onAddClick(schema)}
          >
            <RemixIcon name={"menu-add-line"} />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* TODO: Key/value technically don't have order, but we can make it work nicer by manipulating the formData */}
      <div>{properties.map((prop) => prop.content)}</div>
    </div>
  );
};
