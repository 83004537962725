import React, { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

// Interfaces

// Components
import AppActionsMenu from "./AppActionsMenu";

// Assets
import default_app_image from "../assets/image_placeholder.svg";
import {
  AccessType,
  AppCategory,
  AppData,
  CollaboratorData,
} from "../models/model";
import CollaboratorAvatar from "./CollaboratorAvatar";
import { Link } from "react-router-dom";

interface AppCardProps {
  app: AppData;
}
const AppCard: React.FC<AppCardProps> = ({ app }) => {
  const [collaborators, setCollaborators] = useState<CollaboratorData[]>([]);
  const [image, setImage] = useState(default_app_image);
  const storage = getStorage();

  const displayCollaborators = collaborators.slice(0, 5);

  useEffect(() => {
    const storageRef = ref(storage, `apps/${app.id}.jpg`);

    getDownloadURL(storageRef)
      .then((url) => {
        setImage(url);
      })
      .catch(() => {
        // console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const collaboratorsArray: CollaboratorData[] = [];

    app.collaborators?.forEach((value: AccessType, key: string) => {
      const thisCollborator = new CollaboratorData({
        id: key,
        accessType: value,
      });
      collaboratorsArray.push(thisCollborator);
    });
    setCollaborators(collaboratorsArray);
  }, [app.collaborators]);

  return (
    <Link
      to={`/app/${app.id}/screens`}
      onClick={(event) => {
        if ((event.target as HTMLElement).classList.contains("szh-menu__item"))
          event.preventDefault();
      }}
    >
      <div className="app-card">
        <div
          className="app-card__image"
          style={{ backgroundImage: `url(${image}` }}
        ></div>
        <div className="app-card__content">
          <h4>{app.name}</h4>

          {/* app details: x screens */}
          <div className="app-card__content-info">
            {app.description && (
              <p
                className={`${
                  app.category === AppCategory.Demo ? "demo" : "app"
                }-description`}
              >
                {app?.description}
              </p>
            )}

            <div className="app-card__content-info-ct">
              {app.category !== AppCategory.Demo &&
                app.collaborators != null &&
                app.collaborators.size > 1 && (
                  <a href={`/app/${app.id}/collaborators`}>
                    <div className="homepage-collaborator">
                      {/* show avatar of collaborators */}
                      {displayCollaborators.map((collaborator) => {
                        return (
                          <CollaboratorAvatar
                            key={collaborator.id}
                            collaboratorId={collaborator.id}
                          />
                        );
                      })}
                      {collaborators.length > 5 && (
                        <div className="collaborator-avatar more-collaborators">{`+${
                          collaborators.length - 5
                        }`}</div>
                      )}
                    </div>
                  </a>
                )}

              {app.category !== AppCategory.Demo &&
                app.accessType === AccessType.read && <>Read-only</>}
            </div>

            {/*<span>{app.screens_aggregate?.aggregate.count} screens</span>*/}
          </div>

          {/* app actions */}
          <AppActionsMenu app={app} />
        </div>
      </div>
    </Link>
  );
};

export default AppCard;
