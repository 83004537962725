import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as IconExpand } from "../../assets/expand.svg";
import { ReactComponent as IconError } from "../../assets/error-warning-fill.svg";
import { ReactComponent as IconWarn } from "../../assets/warning-fill.svg";
import { ReactComponent as IconInfo } from "../../assets/info_debug.svg";
import { IErrorMessage } from "../../schema/actionSchema";

import "./DebugPanel.sass";

const getIcon = (error: IErrorMessage) => {
  if (error.isError) return <IconError className="error-icon" />;
  if (error.isWarn) return <IconWarn className="warn-icon" />;
  if (error.isLog) return <IconInfo className="warn-icon" />;
  else null;
};

const DebugPanel = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);

  const handleErrorMessages = (e: MessageEvent) => {
    if (
      (e.source as WindowProxy).location.pathname === "/preview/" ||
      (e.source as WindowProxy).location.pathname.startsWith("/app")
    ) {
      try {
        const errorObj = JSON.parse(e.data);
        if (
          errorObj.message &&
          typeof errorObj.message === "string" &&
          !errorObj.message.startsWith("Connect SDK Version") &&
          !errorObj.message.startsWith("Loading app from service worker") &&
          !errorObj.message.startsWith("Error From INSIDE FRAME_WORK") &&
          !errorObj.message.startsWith("----------------------") &&
          !errorObj.message.startsWith("StackTrace :  null")
        ) {
          if (errorObj.type === "error") errorObj.isError = true;
          setErrorMessage((prev) => [...prev, errorObj]);
        }
      } catch (err) {
        return false;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleErrorMessages);

    return () => {
      window.removeEventListener("message", handleErrorMessages);
    };
  }, []);

  const onClickClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setErrorMessage([]);
  };

  const toggleDebug = () => {
    setIsExpanded((prev) => !prev);
  };
  const errorCount = useMemo(
    () => errorMessage.filter((error) => error.isError).length,
    [errorMessage],
  );
  const warnCount = useMemo(
    () => errorMessage.filter((error) => error.isWarn).length,
    [errorMessage],
  );
  const infoCount = useMemo(
    () => errorMessage.filter((error) => error.isLog).length,
    [errorMessage],
  );

  console.log(errorMessage);
  return (
    <div className={`debug-panel ${isExpanded ? "" : "close"}`}>
      <div className="debug-header" onClick={toggleDebug}>
        <div>
          <IconExpand className={`${isExpanded ? "hide-icon" : "show-icon"}`} />
          Console
          {isExpanded && (
            <button className="clear_btn" onClick={onClickClear}>
              Clear
            </button>
          )}
          <div className="icon-container">
            {errorCount > 0 && (
              <div>
                <IconError /> {errorCount}
              </div>
            )}
            {warnCount > 0 && (
              <div>
                <IconWarn /> {warnCount}
              </div>
            )}
            {infoCount > 0 && (
              <div>
                <IconInfo /> {infoCount}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="debug-content">
        {errorMessage.map((error: IErrorMessage, index: number) => {
          const icon = getIcon(error);
          return (
            <div
              key={index}
              className={`debug-content__content ${
                error.isError
                  ? "debug-error"
                  : error.isWarn
                  ? "debug-warn"
                  : "debug-info"
              }`}
            >
              <div className="debug-content__content__with-icon">
                {icon ? <div className="icon">{icon}</div> : null}
                <pre>{error.message}</pre>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(DebugPanel);
