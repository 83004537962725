/* eslint-disable @typescript-eslint/no-explicit-any */

export const generateTypeString = (value: any): string => {
  if (typeof value === "function") {
    const argsMatch = value.toString().match(/\(([^)]*)\)/);
    let args: string[] = [];
    if (argsMatch && argsMatch[1]) {
      args = argsMatch[1]
        .split(",")
        .map((arg: string) => arg.trim())
        .filter((arg: any) => arg) // Ensure only non-empty argument names are included
        .map((arg: any) => `${arg}: any`); // Assume 'any' type for all arguments
    }
    const argsString = args.join(", ");
    return `(${argsString}) => any`; // Assume 'any' as the return type
  } else if (Array.isArray(value)) {
    const elementType = value.length > 0 ? generateTypeString(value[0]) : "any";
    return `${elementType}[]`;
  } else if (typeof value === "object" && value !== null) {
    const properties = Object.entries(value)
      .map(([key, val]) => {
        return `${key}: ${generateTypeString(val)};`;
      })
      .join(" ");
    return `{ ${properties} }`;
  } else {
    switch (typeof value) {
      case "string":
        return "string";
      case "number":
        return "number";
      case "boolean":
        return "boolean";
      default:
        return "any";
    }
  }
};

export const generateTypeDeclarations = (obj: any): string => {
  let declarations = "";

  Object.entries(obj).forEach(([key, value]) => {
    declarations += `declare const ${key}: ${generateTypeString(value)};\n`;
  });

  return declarations;
};

export const convertFunctionStrings = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      // More precise check for function expressions
      if (/^function\s*\((.*?)\)\s*\{(.*)\}\s*$/.test(obj[key])) {
        try {
          // Convert string to function using new Function()
          // Extracting arguments and function body
          const match = obj[key].match(/^function\s*\((.*?)\)\s*\{(.*)\}\s*$/);
          const args =
            match[1].length > 0
              ? match[1].split(",").map((arg: string) => arg.trim())
              : [];
          const body = match[2];
          obj[key] = new Function(...args, body);
        } catch (e) {
          console.warn(
            `Could not convert string to function for key "${key}":`,
            e,
          );
        }
      }
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively apply to nested objects
      convertFunctionStrings(obj[key]);
    }
  }
};
