import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";
import { Icon } from "@iconify/react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import AppActionsMenu from "../components/AppActionsMenu";
import DemoAppBadge from "../components/DemoAppBadge";
import ScreenCard from "../components/ScreenCard";
import Modal from "../components/Modal";
import AppClone from "../components/AppClone";
import ScreenCreateModal from "../components/ScreenCreateModal";
import { useGetCategories } from "../hooks/useAPIs";
import CollaboratorAvatar from "../components/CollaboratorAvatar";
import DevicePreviewModal from "../components/DevicePreviewModal";
import { AppCategory } from "../models/model";
import { useFeatureFlag } from "../hooks/useFeatureFlag";

const AppScreens: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { screenTemplateEnabled } = useFeatureFlag();

  const { app, isAppReadOnly, isDemoApp } = useAppContext();
  const [cloneTrigger, setCloneTrigger] = useState<boolean>(false);
  const [displayPreviewModal, setDevicePreviewModal] = useState<boolean>(false);
  const categories = useGetCategories();

  const { isModalDisplayed, toggleModal } = useModal();
  const onCancel = () => toggleModal();
  const onClosePreview = () => setDevicePreviewModal(false);

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  const handleClone = () => {
    setCloneTrigger(true);
  };

  const onClickCreateScreen = () => {
    if (screenTemplateEnabled) {
      navigate(`/app/${app.id}/screens/create`);
    } else {
      toggleModal();
    }
  };

  return (
    <div className="screen-content" key={app.id}>
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>

        {app.isPublic && !isDemoApp && (
          <span>
            {" "}
            / <span className="public-app-badge">Public</span>
          </span>
        )}

        {isDemoApp && (
          <span>
            {" "}
            / <DemoAppBadge />
          </span>
        )}
      </div>

      <div className="page-header">
        <h1>{app?.name}</h1>

        <div className="page-actions">
          {app?.category !== AppCategory.Demo &&
            app?.collaborators &&
            app.collaborators.size > 1 && (
              <div className="collaborators-share-action">
                <Link to={`/app/${app.id}/collaborators`}>
                  {Array.from(app.collaborators.keys()).map((key) => (
                    <CollaboratorAvatar key={key} collaboratorId={key} />
                  ))}

                  <Icon
                    icon="tabler:user-plus"
                    width={30}
                    height={30}
                    className="collaborator-avatar more-collaborators"
                  />
                </Link>
                <p
                  className="collaborators-share-button"
                  onClick={() => setDevicePreviewModal(true)}
                >
                  Share
                </p>
              </div>
            )}

          {isAppReadOnly && (
            <button className="button__primary" onClick={handleClone}>
              Clone this app
            </button>
          )}

          {!isAppReadOnly && (
            <button className="button__primary" onClick={onClickCreateScreen}>
              Create screen
            </button>
          )}

          {/* app actions */}
          <AppActionsMenu app={app} />
        </div>
      </div>

      <p className="app-description">{app?.description}</p>

      {/* screen cards */}
      <div className="screen-cards-ct">
        {/* screens */}
        {app.screens && app.screens?.length > 0 && (
          <>
            {app.screens.map((screen) => (
              <ScreenCard
                key={screen.id}
                categories={categories.data?.category}
                screen={screen}
                app_id={app.id}
                isTemplate={app.category === "Template"}
                isReadOnly={isAppReadOnly}
              />
            ))}
          </>
        )}
      </div>

      {/* App clone */}
      {app && (
        <AppClone
          app={app}
          displayCloneModal={cloneTrigger}
          setCloneTrigger={setCloneTrigger}
        />
      )}

      {app && !isAppReadOnly && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Create screen"
          modalContent={<ScreenCreateModal onCancel={onCancel} />}
        />
      )}
      <Modal
        isModalDisplayed={displayPreviewModal}
        onHide={onClosePreview}
        headerText="Preview on your device"
        modalContent={<DevicePreviewModal />}
      />
    </div>
  );
};

export default AppScreens;
