import React, { FormEvent, useCallback, useMemo } from "react";
import { isMap, isScalar, Pair, Scalar, YAMLMap } from "yaml";
import { getPairKeyText } from "../../utils/docUtils";
import "./ActionPropertyPanel.sass";
import { PropertySetForm } from "../WidgetPropertyPanel/PropertySetForm";
import { useSchemas } from "../../hooks/useSchemas";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import {
  generateUiSchema,
  updateNodeWithHex,
} from "../WidgetPropertyPanel/utils/propertyPanelSchemaUtils";
import { IChangeEvent } from "@rjsf/core";
import { RemixIcon } from "../../components/Widgets";
import { ActionMenu } from "../../components/VisualEditor/ActionMenu";
import {
  camelCaseToWords,
  camelCaseToWordsExceptFirstLetter,
} from "../WidgetPropertyPanel/utils/propertyPanelUtils";
import { EventNode } from "./ActionTreeBuilder";
import { RootWidgetContext } from "../WidgetPropertyPanel/fields/RootWidgetContext";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { ActionItemPicker } from "../../components/VisualEditor/ItemPicker";

interface ActionPropertyPanelProps {
  // note this is the Event pair (essentially the parent of the Action pair) e.g. onTap -> invokeAPI
  // we need this to modify the Action even when the Action is just a Scalar
  eventNode: EventNode;
  onItemUpdate: (eventNode: EventNode) => void;
  onItemRemove: (eventNode: EventNode) => void;
}

export const ActionPropertyPanel: React.FC<ActionPropertyPanelProps> = ({
  eventNode,
  onItemUpdate,
  onItemRemove,
}) => {
  const { eventPair } = eventNode;
  const { app } = useAppContext();
  const { findActionSchema } = useSchemas();
  const {
    doc,
    updateNode,
    removeNode,
    addNode,
    setSelectedNode,
    dispatchVisualEditorChanges,
    nonEditorChanges,
  } = useYamlDoc();

  const { actionName, actionNodeData, schema, uiSchema } = useMemo(() => {
    const actionName = getActionName(eventPair);
    const actionNodeData = getActionNodeData(eventPair);
    const schema = findActionSchema(actionName) ?? {};
    const uiSchema = generateUiSchema(schema, false);

    return { actionName, actionNodeData, schema, uiSchema };
    // when formData is changed outside of the panel (e.g. pick a widget from ActionPropertyPanle),
    // we need to recalculate the data, hence listening to nonEditorChanges below
  }, [eventPair, findActionSchema, nonEditorChanges]); // eslint-disable-line

  // when the user selects an Action from the ActionPicker, connecting the Action to the Event
  // We need to notify the parent since both the Tree and this component needs to reload
  const handleActionSelect = (actionName: string) => {
    const actionNode = doc?.createNode({
      [actionName]: new Scalar(null),
    }) as YAMLMap;
    let newEventPair: Pair<Scalar, Scalar | YAMLMap> | null;
    // if the eventPair is just a placeholder, ignore the eventPair and construct the paths from the root
    if (eventNode.placeholderContext) {
      newEventPair = addChildActionToContext(
        eventNode.placeholderContext,
        actionNode,
      );
    } else {
      eventPair.value = actionNode;
      newEventPair = eventPair;
    }
    if (newEventPair) {
      dispatchVisualEditorChanges();

      onItemUpdate({
        eventPair: newEventPair,
        parent: eventNode.parent,
      });
    }
  };

  const onSubmit = useCallback<(data: IChangeEvent, event: FormEvent) => void>(
    ({ formData }) => {
      if (!doc) return;

      const mergedData = {
        ...(actionNodeData?.toJSON() ?? {}),
        ...formData,
      };
      const updatedNode = doc.createNode(mergedData);
      const updatedNodeWithHex = updateNodeWithHex(updatedNode);
      if (updatedNodeWithHex) {
        // convert the Action node to YAMLMap if it is a Scalar
        if (isScalar(eventPair.value)) {
          eventPair.value = doc.createNode({
            [actionName]: null,
          });
        }

        // now update the Action data
        (eventPair.value as YAMLMap).items[0].value = updatedNodeWithHex;
        dispatchVisualEditorChanges();

        // need to update the Tree?
      }
    },
    [actionName, actionNodeData, dispatchVisualEditorChanges, doc, eventPair],
  );

  return isScalar(eventPair.value) && eventPair.value.value === null ? (
    <ActionItemPicker onItemSelect={handleActionSelect} />
  ) : (
    <div className={"action-property-panel"}>
      <div className={"action-property-panel-header"}>
        <div className={"action-property-panel-header-title"}>
          <div>
            {camelCaseToWordsExceptFirstLetter(getPairKeyText(eventPair))}
          </div>
          <div>{camelCaseToWords(actionName)}</div>
        </div>

        <ActionMenu
          anchor={
            <div className={"action-icon"}>
              <RemixIcon name={"more-2-fill"} />
            </div>
          }
          items={[
            {
              iconName: "delete-bin-2-line",
              label: "Remove this Action",
              value: "delete",
            },
          ]}
          onSelect={(value) => {
            if (value === "delete") {
              onItemRemove(eventNode);
            }
          }}
        />
      </div>

      <div>
        <PropertySetForm
          schema={schema}
          uiSchema={uiSchema}
          formData={actionNodeData?.toJSON() ?? {}}
          formContext={{
            doc,
            node: getActionPair(eventPair),
            // activeCategory,
            // platformWidgets: schemaProps?.widgets,
            // customWidgets: Array.from(customWidgets).map((widget) => ({
            //   key: widget,
            // })),
            appData: app,
            addNode,
            setSelectedNode,
            removeNode,
            updateNode,
          }}
          onFormSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

// get the Action name from the event pair e.g. onTap -> invokeAPI
const getActionName = (eventPair: Pair<Scalar, Scalar | YAMLMap>): string => {
  if (eventPair.value instanceof Scalar) {
    return eventPair.value.value?.toString() ?? "";
  }
  if (eventPair.value instanceof YAMLMap) {
    if (eventPair.value.items.length > 0) {
      return getPairKeyText(eventPair.value.items[0]);
    }
  }
  return "";
};

const getActionPair = (
  eventPair: Pair<Scalar, Scalar | YAMLMap>,
): Pair | null => {
  if (eventPair.value instanceof YAMLMap && eventPair.value.items.length == 1) {
    return eventPair.value.items[0];
  }
  return null;
};

const getActionNodeData = (
  eventPair: Pair<Scalar, Scalar | YAMLMap>,
): YAMLMap | null => {
  if (isMap(eventPair.value) && isMap(eventPair.value.items[0].value)) {
    return eventPair.value.items[0].value;
  }
  return null;
};

const addChildActionToContext = (
  nodeContext: RootWidgetContext,
  actionNode: YAMLMap,
): Pair<Scalar, Scalar | YAMLMap> | null => {
  if (nodeContext.paths.length === 0) return null;

  // first ensure the rootNode's value is a Map, as it might not be e.g. "Button: null"
  if (!isMap(nodeContext.rootNode.value)) {
    nodeContext.rootNode.value = new YAMLMap();
  }

  let node = nodeContext.rootNode.value as YAMLMap;
  for (let i = 0; i < nodeContext.paths.length - 1; i++) {
    const path = nodeContext.paths[i];
    const nextNode = node.get(path);
    if (!nextNode || !isMap(nextNode)) {
      node.set(new Scalar(path), new YAMLMap());
    }
    node = node.get(path) as YAMLMap;
  }

  // handle last node separately as we need to return the eventPair
  const lastPath = nodeContext.paths[nodeContext.paths.length - 1];
  let eventPair: Pair<Scalar, Scalar | YAMLMap>;
  if (!node.has(lastPath)) {
    eventPair = new Pair(new Scalar(lastPath), actionNode);
    node.items.push(eventPair);
  } else {
    eventPair = node.items.find((pair) => pair.key === lastPath) as Pair<
      Scalar,
      Scalar | YAMLMap
    >;
    if (eventPair) {
      eventPair.value = actionNode;
    }
  }
  return eventPair;
};
