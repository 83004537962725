import React from "react";
import { FieldProps } from "@rjsf/utils";
import { ExpressionInput } from "../components/ExpressionInput";
import { CustomJSONSchema7 } from "../utils/propertyPanelSchemaUtils";
import "./ExpressionField.sass";

export const ExpressionField: React.FC<FieldProps> = ({
  schema,
  idSchema,
  value,
  formData,
  onChange,
  onBlur,
}) => {
  const handleChange = (newValue: string) => {
    // if the user clears the expression, set the value to undefined to remove its entry
    onChange(newValue.trim() === "" ? undefined : newValue);
  };

  const handleBlur = () => {
    onBlur(idSchema.$id, value);
  };

  const handleEnterKey = () => {
    onBlur(idSchema.$id, value);
  };

  return (
    <ExpressionInput
      placeholder={(schema as CustomJSONSchema7).uiPlaceholder}
      value={typeof formData === "object" ? "" : formData || ""}
      onChange={handleChange}
      onEnterKey={handleEnterKey}
      onBlur={handleBlur}
    />
  );
};

/**
 * We use this to simply display a "Clear Expression" button.
 * This handles the state where expression is entered, and we switched to non-expression UI.
 *
 * A better UX maybe for each and every field to have a concept of showing there's
 * an expression set, but still allow the user to manually select the value without first clearing it out
 */
export const ClearExpressionField: React.FC<FieldProps> = ({
  onChange,
  formContext,
}) => {
  const handleClear = () => {
    onChange(undefined);
    formContext.submitFormLater();
  };
  return (
    <div className={"clear-expression"} onClick={handleClear}>
      <span>Clear Expression</span>
    </div>
  );
};
