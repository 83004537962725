import { useRef, type FC, ChangeEvent } from "react";
import type { DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { ReactComponent as IconAdd } from "../../assets/icon_plus.svg";

import "../../styles/components/upload-modal.sass";
export interface UploadProps {
  onDrop: (files: File[]) => void;
  isUploading: boolean;
}

export const Upload: FC<UploadProps> = (props) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const { onDrop, isUploading } = props;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        if (onDrop) {
          onDrop(item.files);
        }
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );
  const openFileUpload = () => {
    fileRef.current?.click();
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.target.files as FileList).length === 0) return;
    onDrop([(e.target?.files as FileList)[0]]);
  };

  const isActive = canDrop && isOver;

  if (isUploading) {
    return (
      <div className="upload-asset">
        <p>Uploading...</p>
      </div>
    );
  }

  return (
    <div ref={drop} className="upload-asset" onClick={openFileUpload}>
      {isActive ? (
        "Release to drop"
      ) : (
        <>
          <IconAdd className="upload-asset__icon" />
          <input type="file" ref={fileRef} onChange={onSelectFile} />
          <p>
            Drop images, videos, or other assets here or click to select from
            file system
          </p>
        </>
      )}
    </div>
  );
};
