import React, { useEffect, useContext } from "react";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { AuthContext } from "../../../App";

// APIs
import { useUpdateWidget } from "../../../hooks/useAPIs";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface ScriptArchiveModalProps {
  script: EnsembleWidgetData;
  onCancel: () => void;
  onSubmit: () => void;
}

const ScriptArchiveModal: React.FC<ScriptArchiveModalProps> = ({
  script,
  onCancel,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const scriptChanges = { isArchived: true };
  const updateScriptQuery = useUpdateWidget(currentUser, app.id, script.id);

  // trigger update
  const archiveScript = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateScriptQuery.mutate(scriptChanges);
  };

  // update complete
  useEffect(() => {
    if (updateScriptQuery.isSuccess) onSubmit();
  }, [updateScriptQuery.isSuccess, onSubmit]);

  return (
    <div className="app-create-modal">
      <button onClick={archiveScript} className="button__primary">
        {updateScriptQuery.isIdle && <>Archive script</>}
        {updateScriptQuery.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default ScriptArchiveModal;
