import React from "react";
import { FieldProps } from "@rjsf/utils";
import { InputNumber } from "antd";
import "./CustomNumberField.sass";

/* can display both a number and an integer */
export const CustomNumberField: React.FC<FieldProps> = ({
  schema,
  formData,
  onChange,
  onBlur,
  idSchema,
}) => {
  const handleBlur = () => {
    onBlur(idSchema.$id, formData);
  };

  return (
    <InputNumber
      className={"custom-number-field"}
      value={formData}
      onChange={(value) => onChange(value)}
      onBlur={handleBlur}
      min={schema.minimum}
      max={schema.maximum}
      // TODO: figure out how to style dark theme for the up/down button
      controls={false}
    />
  );
};
