import RJSFObjectField from "@rjsf/core/lib/components/fields/ObjectField";
import {
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
} from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";

export class ObjectField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
> extends RJSFObjectField<T, S, F> {
  // This function return the default Value for an Object with additionalProperties.
  // It is crucial to get the value right, otherwise the value field might not show up
  getDefaultValue(type?: RJSFSchema["type"]) {
    const {
      registry: { translateString },
      schema,
    } = this.props;
    switch (type) {
      case "array":
        return [];
      case "boolean":
        return false;
      case "null":
        return null;
      case "number":
        return 0;
      case "object":
        return {};
      case "string":
        return translateString(TranslatableString.NewStringDefault);
      default:
        // special handling for Action as the value (e.g. Event handler when calling a widget)
        if (
          schema.additionalProperties &&
          (schema.additionalProperties as JSONSchema7).$ref === "#/$defs/Action"
        ) {
          // TODO: empty object should work too but the ActionTreeBuilder makes the
          //  incorrect assumption that an Action should either be a Scalar or a Map with a single child
          return null;
        }

        // default fallback. This is in the super getDefaultValue() and seems reasonable
        return translateString(TranslatableString.NewStringDefault);
    }
  }
}
