import React, { useContext, useState } from "react";
//assets
import RemoveIcon from "../../../assets/remove.svg";
//interfaces
import { KeyValuePair } from "../../../config/interfaces";

interface InputsModalContentProps {
  defaultInputs: KeyValuePair[];
  onCancel: () => void;
  handleSetInputs: (inputs: KeyValuePair[]) => void;
}

const ArtifactInputsModalContent: React.FC<InputsModalContentProps> = ({
  defaultInputs,
  onCancel,
  handleSetInputs,
}) => {
  const [keyValuePairs, setKeyValuePairs] =
    useState<KeyValuePair[]>(defaultInputs);

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const updatedPairs = [...keyValuePairs];
    updatedPairs[index][name.trim() as keyof KeyValuePair] = value;
    setKeyValuePairs(updatedPairs);
  };

  const handleAddPair = () => {
    setKeyValuePairs([...keyValuePairs, { key: "", value: "" }]);
  };

  const handleRemovePair = (index: number) => {
    const updatedPairs = [...keyValuePairs];
    updatedPairs.splice(index, 1);
    setKeyValuePairs(updatedPairs);
  };

  const onSetInputs = () => {
    const filteredData = keyValuePairs.filter((data) => data.key !== "");
    handleSetInputs(filteredData);
  };

  return (
    <div className="artifact-inputs-modal">
      <span>
        Set screen inputs so you can preview this screen with test data. <br />
        This will not impact the published app.
      </span>
      <div className="inputs-section">
        {keyValuePairs.map((pair, index) => (
          <div key={index} className="single-input-section">
            <input
              type="text"
              name="key"
              placeholder="e.g. firstName"
              value={pair.key.trim()}
              onChange={(e) => handleChange(index, e)}
            />
            <input
              type="text"
              name="value"
              placeholder="e.g. John"
              value={pair.value}
              onChange={(e) => handleChange(index, e)}
            />
            {index > 0 && (
              <img
                className="remove"
                src={RemoveIcon}
                onClick={() => handleRemovePair(index)}
              />
            )}
          </div>
        ))}
      </div>
      <span className="add-button" onClick={handleAddPair}>
        + Add
      </span>
      <div className="action-buttons">
        <button className="button__primary" onClick={onCancel}>
          Cancel
        </button>
        <button className="button__primary" onClick={onSetInputs}>
          Set Inputs
        </button>
      </div>
    </div>
  );
};

export default ArtifactInputsModalContent;
