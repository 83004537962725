import React, { useState, createRef, useEffect, useContext } from "react";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { AuthContext } from "../../../App";

// APIs
import { useUpdateWidget } from "../../../hooks/useAPIs";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

import { formatWidgetName } from "../../../utils/functions";

interface ScriptRenameModalProps {
  script: EnsembleWidgetData;
  onCancel: () => void;
  onSubmit: () => void;
}

const ScriptRenameModal: React.FC<ScriptRenameModalProps> = ({
  script,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [scriptName, setWidgetName] = useState(script.name);
  const updateScriptQuery = useUpdateWidget(currentUser, app.id, script.id);
  const scriptNameInputRef = createRef<HTMLInputElement>();
  const submitButtonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    scriptNameInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    const formattedName = formatWidgetName(event.currentTarget.value);
    setWidgetName(formattedName);
  };

  // submit on Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      submitButtonRef.current?.click();
    }
  };

  // trigger update
  const renameApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    updateScriptQuery.mutate({
      name: scriptName,
    });
  };

  // update complete
  useEffect(() => {
    if (updateScriptQuery.isSuccess) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScriptQuery.status]);

  return (
    <form className="screen-create-modal" onSubmit={renameApp}>
      <input
        type="text"
        ref={scriptNameInputRef}
        value={scriptName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <br />
      <button type="submit" ref={submitButtonRef} className="button__primary">
        {updateScriptQuery.isIdle && <>Rename script</>}
        {updateScriptQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default ScriptRenameModal;
