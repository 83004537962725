import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";

import {
  getAppConfig,
  useCreateApp,
  useGetAppFullContent,
} from "../hooks/useAPIs";

// Interfaces
import {
  EnsembleNewScreenData,
  EnsembleNewThemeData,
  EnsembleTranslationData,
  EnsembleWidgetData,
} from "../config/interfaces";
import { AppData } from "../models/model";
import { useQuery } from "react-query";

interface AppCloneModalProps {
  app: AppData;
}

const AppCloneModal: React.FC<AppCloneModalProps> = ({ app }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const appQuery = useGetAppFullContent(app.id);
  const appConfigQuery = useQuery("appConfig", () => getAppConfig(app.id));

  const [screensToClone, setScreensToClone] = useState<EnsembleNewScreenData[]>(
    [],
  );
  const [themeToClone, setThemeToClone] = useState<EnsembleNewThemeData>();
  const [widgetsToClone, setWidgetsToClone] = useState<EnsembleWidgetData[]>(
    [],
  );
  const [scriptsToClone, setScriptsToClone] = useState<EnsembleWidgetData[]>(
    [],
  );
  const [translationsToClone, setTranslationsToClone] = useState<
    EnsembleTranslationData[]
  >([]);
  const [envVariablesToClone, setEnvVariablesToClone] =
    useState<Map<string, string>>();

  const appInsertQuery = useCreateApp(
    currentUser,
    app.name + " copy",
    screensToClone,
    themeToClone,
    widgetsToClone,
    scriptsToClone,
    translationsToClone,
    envVariablesToClone,
    app.description,
    app.isReact,
  );

  // get full app details
  useEffect(() => {
    if (!appQuery.isLoading && appQuery.data) {
      const newScreens: EnsembleNewScreenData[] =
        appQuery.data.app_by_pk?.screens.map((screen) => ({
          name: screen.name,
          content: screen.content,
          isRoot: screen.isRoot,
          isArchived: screen.isArchived,
        }));
      setScreensToClone(newScreens);

      // add the theme
      const theme = appQuery.data.app_by_pk?.theme;
      if (theme != null) {
        setThemeToClone(
          new EnsembleNewThemeData(theme.content, theme.isArchived),
        );
      }

      const newWidgets: EnsembleWidgetData[] =
        appQuery.data.app_by_pk?.internalWidgets.map((widget) => ({
          id: widget.id,
          isRoot: widget?.isRoot,
          name: widget.name,
          content: widget?.content,
          isArchived: widget.isArchived,
        }));
      setWidgetsToClone(newWidgets);

      const newScripts: EnsembleWidgetData[] =
        appQuery.data.app_by_pk?.internalScripts.map((script) => ({
          id: script.id,
          isRoot: script?.isRoot,
          name: script.name,
          content: script?.content,
          isArchived: script.isArchived,
        }));
      setScriptsToClone(newScripts);

      const newTranslations: EnsembleTranslationData[] =
        appQuery.data.app_by_pk?.translations.map((translation) => ({
          id: translation.id,
          isArchived: translation.isArchived,
          isRoot: translation.isRoot,
          type: translation.type,
          content: translation.content,
          defaultLocale: translation.defaultLocale,
        }));
      setTranslationsToClone(newTranslations);
    }
  }, [appQuery.isLoading, appQuery.data]);

  // get app config
  useEffect(() => {
    if (!appConfigQuery.isLoading && appConfigQuery.data) {
      setEnvVariablesToClone(appConfigQuery.data?.envVariables);
    }
  }, [appConfigQuery]);

  const handleCreateApp = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    appInsertQuery.mutate();
  };

  useEffect(() => {
    if (appInsertQuery.isSuccess) {
      const newAppId = appInsertQuery.data.insert_app_one.id;
      navigate(`/app/${newAppId}/screens`);
    }
  }, [appInsertQuery, navigate]);

  return (
    <React.Fragment>
      <p>Are you sure you want to make a copy of {app.name}?</p>
      <br />
      <button
        className="button__primary"
        onClick={handleCreateApp}
        disabled={appInsertQuery.isLoading}
      >
        {appInsertQuery.isIdle && <>Yes, clone app</>}
        {appInsertQuery.isLoading && <>Processing...</>}
      </button>
    </React.Fragment>
  );
};

export default AppCloneModal;
