import React, { useContext, useState } from "react";
import { Menu, MenuItem, MenuDivider, ClickEvent } from "@szhsin/react-menu";
import { toast } from "react-toastify";
import config from "../config/config";
import { AuthContext } from "../App";
import { useNavigate } from "react-router-dom";

import "@szhsin/react-menu/dist/index.css";

// Components
import AppClone from "./AppClone";
import AppRename from "./AppRename";
import AppArchive from "./AppArchive";
import AppLeave from "./AppLeave";
import AppVisibility from "./AppVisibility";
import AppGetPrepublishReport from "./AppGetPrepublishReport";
import { ReactComponent as IconMenu } from "../assets/icon_menu.svg";

// Interfaces
import { AccessType, AppCategory, AppData } from "../models/model";
import { downloadApp } from "../hooks/useAPIs";

interface AppActionsMenuProps {
  app: AppData;
}

const AppActionsMenu: React.FC<AppActionsMenuProps> = ({ app }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [cloneTrigger, setCloneTrigger] = useState<boolean>(false);
  const [renameTrigger, setRenameTrigger] = useState<boolean>(false);
  const [leaveTrigger, setLeaveTrigger] = useState<boolean>(false);
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);
  const [appVisibilityTrigger, setAppVisibilityTrigger] =
    useState<boolean>(false);
  const [getPrepublishReportTrigger, setGetPrepublishReportTrigger] =
    useState<boolean>(false);
  const handleClone = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setCloneTrigger(true);
  };

  const handleRename = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setRenameTrigger(true);
  };

  const handleLeave = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setLeaveTrigger(true);
  };

  const handleArchive = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const handleAppVisibility = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setAppVisibilityTrigger(true);
  };

  const handleGetPrepublishReport = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setGetPrepublishReportTrigger(true);
  };
  const handleCopyId = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    navigator.clipboard.writeText(app.id!);
    toast("App ID copied to your clipboard.", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCopyPreviewLink = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    navigator.clipboard.writeText(
      `${config.preview.url}?appId=${app.id}&showAction=true&devicePreview=true`,
    );
    toast("Public link copied to your clipboard.", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDownloadApp = async () => {
    try {
      const response = await downloadApp(app.id!);
      if (response.status === 200) {
        const filename =
          response.headers["content-disposition"]?.split("filename=")[1];
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename ?? "app.zip";
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.error("Error downloading app:", response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function isCollaborator(userId: string): boolean {
    if (app.collaborators) {
      return app.collaborators.has(userId);
    }
    return false;
  }

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="top"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {app.accessType !== AccessType.read && (
          <MenuItem onClick={handleRename}>Edit name and description</MenuItem>
        )}

        <MenuItem onClick={handleClone}>Clone</MenuItem>

        <MenuItem onClick={handleCopyId}>Copy app ID</MenuItem>

        <MenuItem onClick={handleCopyPreviewLink}>Get preview link</MenuItem>

        {currentUser &&
          isCollaborator(currentUser.id) &&
          app.accessType !== AccessType.owner && (
            <MenuItem onClick={handleLeave}>Leave app</MenuItem>
          )}

        {app.accessType === AccessType.owner && (
          <MenuItem
            onClick={handleAppVisibility}
            className="actions-menu__make-private-item"
          >
            {app.isPublic ? "Make private" : "Make public"}
          </MenuItem>
        )}

        {app.category !== AppCategory.Demo && (
          <MenuItem
            onClick={() => {
              console.log("share clicked ", app.id);
              navigate(`/app/${app.id}/collaborators`);
              // window.open(`/app/${app.id}/collaborators`, "_self");
            }}
          >
            Share
          </MenuItem>
        )}
        <MenuItem onClick={handleGetPrepublishReport}>Publish App</MenuItem>
        <MenuItem onClick={handleDownloadApp}>Download App (beta)</MenuItem>
        {app.accessType === AccessType.owner && (
          <>
            <MenuDivider />
            <MenuItem
              className="actions-menu__delete-item"
              onClick={handleArchive}
            >
              Archive
            </MenuItem>
          </>
        )}
      </Menu>

      <AppClone
        app={app}
        displayCloneModal={cloneTrigger}
        setCloneTrigger={setCloneTrigger}
      />

      <AppRename
        app={app}
        displayRenameModal={renameTrigger}
        setRenameTrigger={setRenameTrigger}
      />

      <AppLeave
        app={app}
        displayLeaveModal={leaveTrigger}
        setLeaveTrigger={setLeaveTrigger}
      />

      <AppArchive
        app={app}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />

      <AppVisibility
        app={app}
        displayAppVisibilityModal={appVisibilityTrigger}
        setAppVisibilityTrigger={setAppVisibilityTrigger}
      />

      <AppGetPrepublishReport
        app={app}
        displayReportModal={getPrepublishReportTrigger}
        setReportTrigger={setGetPrepublishReportTrigger}
      />
    </>
  );
};

export default AppActionsMenu;
