import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../App";

// APIs
import { useCreateApp } from "../hooks/useAPIs";
import { useFeatureFlag } from "../hooks/useFeatureFlag";

const starterYaml = `View:
  # Optional - style the screen
  styles:
    scrollableView: true

  # Optional - set the header for the screen
  header:
    title: Home

  # Specify the body of the screen
  body:
    Column:
      styles:
        padding: 24
        gap: 8
      children:
        - Text:
            text: Hi there!
        - Button:
            label: Checkout Ensemble Kitchen Sink
            onTap:
              openUrl:
                url: 'https://studio.ensembleui.com/preview/index.html?appId=e24402cb-75e2-404c-866c-29e6c3dd7992'
  `;

interface AppCreateModalProps {
  onCancel: () => void;
}

const AppCreateModal: React.FC<AppCreateModalProps> = () => {
  const navigate = useNavigate();
  const { screenTemplateEnabled, reactEnabled } = useFeatureFlag();

  const [appNameInput, setAppNameInput] = useState("");
  const [screenNameInput, setScreenNameInput] = useState("Home");
  const [appDescriptionInput, setAppDescriptionInput] = useState("");
  const [isReactInput, setIsReactInput] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const newScreen = screenTemplateEnabled
    ? []
    : [
        {
          name: screenNameInput,
          content: starterYaml,
          isRoot: true,
          isArchived: false,
        },
      ];

  const appInsertQuery = useCreateApp(
    currentUser,
    appNameInput,
    newScreen,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    appDescriptionInput,
    isReactInput,
  );

  const appNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setAppNameInput(event.currentTarget.value);
  };

  const appDescriptionChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    setAppDescriptionInput(event.currentTarget.value);
  };

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setScreenNameInput(event.currentTarget.value);
  };

  const handleCreateApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    appInsertQuery.mutate();
  };

  useEffect(() => {
    if (appInsertQuery.isSuccess) {
      const newAppId = appInsertQuery.data.insert_app_one.id;
      if (screenTemplateEnabled) {
        navigate(`/app/${newAppId}/screens/create`);
      } else {
        const newScreenId = appInsertQuery.data.insert_app_one.screens?.[0]?.id;
        navigate(`/app/${newAppId}/screen/${newScreenId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInsertQuery.status]);

  return (
    <form className="app-create-modal" onSubmit={handleCreateApp}>
      <label>App name</label>
      <input
        type="text"
        value={appNameInput}
        placeholder="Enter app name"
        onChange={appNameChange}
        autoFocus
      />

      <label>App description (optional)</label>
      <input
        type="text"
        value={appDescriptionInput}
        placeholder="Enter app description"
        onChange={appDescriptionChange}
      />

      {!screenTemplateEnabled && (
        <>
          <label>First screen name</label>
          <input
            type="text"
            value={screenNameInput}
            onChange={screenNameChange}
          />
          <br />
        </>
      )}

      {reactEnabled && (
        <>
          <label>Use React</label>
          <input
            type="checkbox"
            checked={isReactInput}
            onChange={(e) => setIsReactInput(e.target.checked)}
          />
          <br />
        </>
      )}

      <button
        type="submit"
        className="button__primary"
        disabled={
          !appNameInput ||
          (!screenTemplateEnabled && !screenNameInput) ||
          appInsertQuery.isLoading
        }
      >
        {appInsertQuery.isIdle && <>Create app</>}
        {appInsertQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default AppCreateModal;
