import React, { useMemo, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import { ActionBuilderModal } from "./ActionBuilderModal";
import "./ActionBuilderField.sass";
import { FormRefProvider } from "../../../models/FormRefContext";
import { v4 as uuidv4 } from "uuid";
import { camelCaseToWords, getDataPreview } from "../utils/propertyPanelUtils";
import { useSchemas } from "../../../hooks/useSchemas";
import { RootWidgetContext } from "./RootWidgetContext";
import { Scalar } from "yaml";
import { useYamlDoc } from "../../../hooks/useYamlDoc";
import { ActionItemPicker } from "../../../components/VisualEditor/ItemPicker";

export const ActionBuilderField: React.FC<FieldProps> = (props) => {
  const { formData, idSchema, formContext } = props;
  const { findActionSchema } = useSchemas();
  const { dispatchVisualEditorChanges } = useYamlDoc();
  const [isOpened, setIsOpened] = useState(false);

  // preview for the Action anchor. If this returns undefined, it can be safe to assume that there is no Action selected
  const anchorPreview = useMemo(() => {
    if (
      formData &&
      typeof formData === "object" &&
      Object.keys(formData).length > 0
    ) {
      const actionName = Object.keys(formData)[0];
      const actionSchema = findActionSchema(actionName);
      if (actionSchema) {
        const actionNameNode = (
          <span className={"action-builder-field-preview-name"}>
            {camelCaseToWords(actionName)}
          </span>
        );

        const previewPayload = getDataPreview(
          actionSchema,
          formData[actionName],
        );
        if (previewPayload) {
          return (
            <>
              <div className={"action-builder-field-preview-title"}>
                {actionNameNode}
                {previewPayload.short && (
                  <div className={"action-builder-field-preview-short"}>
                    {previewPayload.short}
                  </div>
                )}
              </div>
              {previewPayload.long && (
                <div className={"action-builder-field-preview-long"}>
                  {previewPayload.long}
                </div>
              )}
            </>
          );
        }
        return actionNameNode;
      }
    }
  }, [findActionSchema, formData]);

  const handleActionSelect = (actionName: string) => {
    const nodeContext = new RootWidgetContext(formContext, idSchema.$id);
    const leafPair = nodeContext.getOrCreateLeafNodePair();
    leafPair.value?.set(new Scalar(actionName), new Scalar(null));
    dispatchVisualEditorChanges();
    setIsOpened(true);
  };

  // unique key for each field so the Modal state sticks around for a bit
  const uniqueModalKey = useMemo(() => uuidv4(), []);

  return (
    <div className={"action-builder-field"}>
      {anchorPreview ? (
        <div
          className={"action-builder-field-preview"}
          onClick={() => setIsOpened(true)}
        >
          {anchorPreview}
        </div>
      ) : (
        <ActionItemPicker onItemSelect={handleActionSelect} />
        // <ActionPicker
        //   onActionSelect={handleActionSelect}
        //   trigger={
        //     <div className={"action-builder-field-placeholder"}>
        //       <span>
        //         <RemixIcon name={"slideshow-3-line"} />
        //       </span>
        //       <span> Select an Action</span>
        //     </div>
        //   }
        // />
      )}

      <FormRefProvider>
        <ActionBuilderModal
          key={uniqueModalKey}
          {...props}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </FormRefProvider>
    </div>
  );
};
