import React from "react";

// APIs
import { useModal } from "../../../hooks/useModal";

// Components
import Modal from "../../Modal";
import ScriptRenameModal from "./ScriptRenameModal";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface ScriptRenameProps {
  script: EnsembleWidgetData;
  displayRenameModal: boolean;
  setRenameTrigger: (val: boolean) => void;
}

const ScriptRename: React.FC<ScriptRenameProps> = ({
  script,
  displayRenameModal,
  setRenameTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setRenameTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    toggleModal();
    setRenameTrigger(false);
  };

  return (
    <Modal
      isModalDisplayed={displayRenameModal}
      onHide={onCancel}
      headerText="Rename script"
      modalContent={
        <ScriptRenameModal
          script={script}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScriptRename;
