import { isMap, isScalar, isSeq, Pair, Scalar, YAMLMap, YAMLSeq } from "yaml";
import { TreeItemWidgetPayload, TreeNode } from "./TreeNode";
import { getTreeIcon } from "../../utils/treeUtils";
import { NodeAction } from "../../hooks/useYamlDoc";
import { ReactNode } from "react";

export class TreeBuilder {
  private readonly UNKNOWN_WIDGET = "(Unknown widget)";
  private readonly ADD_WIDGET = "Add widget";

  constructor(
    // all platform widgets + custom widgets
    private whitelistedWidgets: ReadonlySet<string>,
    // all containers that can have children
    private containerList: ReadonlySet<string>,

    private treeItemWidgetMap: Map<string, TreeItemWidgetPayload[]>,
  ) {}

  private isWidget(key: string) {
    return this.whitelistedWidgets.has(key);
  }

  private isContainer(key: string) {
    return this.containerList.has(key);
  }

  // placeholder for "add a widget" node. We'll override the actual UI
  // in the TreeView component
  private buildAddWidgetNode(parent: YAMLMap, keyPrefix: string): TreeNode {
    return {
      key: `${keyPrefix}-addNode`,
      selectable: false,
      ref: {
        node: new Scalar(""),
        parent: parent,
        action: NodeAction.AddChild,
      },
      isLeaf: true,
    };
  }

  private buildSetTreeWidgetNode(
    parent: YAMLMap,
    keyPrefix: string,
    treeItemWidget: TreeItemWidgetPayload,
  ): TreeNode {
    return {
      key: `${keyPrefix}-${treeItemWidget.property}-setWidget`,
      selectable: false,
      ref: {
        node: new Scalar(""),
        parent: parent,
        action: NodeAction.SetTreeItemWidget,
      },
      payload: treeItemWidget,
      isLeaf: true,
    };
  }

  // process a scalar e.g. "Column"
  private processScalar(
    parent: YAMLMap,
    node: Scalar,
    keyPrefix: string,
  ): TreeNode {
    const name = node.value as string;
    return {
      key: `${keyPrefix}-${name}`,
      ref: {
        node: node,
        parent: parent,
      },
      title: this.isWidget(name) ? name : this.UNKNOWN_WIDGET,
      icon: () => getTreeIcon(name),
      isLeaf: true,
    };
  }
  // process a key/value pair e.g. "Column:"
  private processPair(
    parent: YAMLMap,
    pair: Pair,
    keyPrefix: string,
  ): TreeNode {
    const keyNode = pair.key as Scalar;
    const valueNode = pair.value;

    let children = null;
    // handle children node
    if (this.isContainer(keyNode.value as string) && isMap(valueNode)) {
      const childrenNode = valueNode.get("children");
      if (isSeq(childrenNode)) {
        children = this.processSeq(valueNode, childrenNode, keyPrefix);
      }
      // placeholder node to add additional child
      (children ??= []).push(this.buildAddWidgetNode(valueNode, keyPrefix));
    }

    // handle mappings to widgets in the schema
    if (this.treeItemWidgetMap.has(keyNode.value as string)) {
      const widgetItems = this.treeItemWidgetMap.get(keyNode.value as string);
      widgetItems?.forEach((item) => {
        // have existing entry
        if (isMap(valueNode) && valueNode.get(item.property)) {
          // only handle non-Scalar widget for now (e.g. don't handle 'Text' without colon)
          const itemNode = valueNode.get(item.property);
          if (isMap(itemNode)) {
            children = [
              ...(children ??= []),
              ...this.processMap(
                valueNode,
                itemNode,
                `${keyPrefix}-${item.property}`,
              ),
            ];
          }
        }
        // placeholder to add
        else {
          (children ??= []).push(
            this.buildSetTreeWidgetNode(valueNode as YAMLMap, keyPrefix, item),
          );
        }
      });
    }
    return {
      key: `${keyPrefix}-${keyNode.value}`,
      ref: {
        node: pair,
        parent: parent,
      },
      title: this.buildPairTitle(pair),
      icon: () => getTreeIcon(keyNode.value as string),
      children: children ?? undefined,
      isLeaf: !children,
    };
  }

  private processMap(
    parent: YAMLMap,
    node: YAMLMap,
    keyPrefix: string,
  ): TreeNode[] {
    const nodes: TreeNode[] = [];
    node.items.forEach((item) => {
      nodes.push(this.processPair(parent, item, keyPrefix));
    });
    return nodes;
  }

  private processSeq(
    parent: YAMLMap,
    node: YAMLSeq,
    keyPrefix: string,
  ): TreeNode[] {
    const nodes: TreeNode[] = [];
    node.items.forEach((item, index) => {
      if (isMap(item)) {
        nodes.push(...this.processMap(parent, item, `${keyPrefix}-${index}`));
      } else if (isScalar(item)) {
        // as a scalar
        nodes.push(this.processScalar(parent, item, `${keyPrefix}-${index}`));
      }
    });
    return nodes;
  }

  private buildPairTitle(pair: Pair): ReactNode {
    const key = (pair.key as Scalar).value as string;
    if (!this.isWidget(key)) return this.UNKNOWN_WIDGET;

    const id = isMap(pair.value) && (pair.value as YAMLMap).get("id");
    if (!id) return key;

    return (
      <span>
        {key} <span className={"ant-tree-subtitle"}>{id as string}</span>
      </span>
    );
  }

  build(node: YAMLMap, keyPrefix: string): TreeNode[] | null {
    return this.processMap(node, node, keyPrefix);
  }
}
