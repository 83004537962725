import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import { createUser } from "../hooks/useAPIs";
import { auth } from "../config/firebase";
import { useLocationQuery } from "../hooks/useQuery";

// Assets
import logo from "../assets/logo_reverse.svg";
import google_logo from "../assets/signin_google.svg";
import github_logo from "../assets/signin_github.svg";

const SignIn: React.FC = () => {
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const query = useLocationQuery();
  const redirectTo = query.get("redirectTo");

  const signInWithGoogle = async () => {
    setAuthenticating(true);

    signInWithPopup(auth, new GoogleAuthProvider().addScope("email, profile"))
      .then((response) => {
        verifyUserAndRedirect(response.user);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (
          error.message.includes(
            "auth/account-exists-with-different-credential"
          )
        )
          setErrorMessage(
            "Looks like you used Google before to sign in. To proceed, sign in with Github."
          );
      });
  };

  const signInWithGithub = async () => {
    setAuthenticating(true);

    signInWithPopup(
      auth,
      new GithubAuthProvider().addScope("read:user,user:email")
    )
      .then((response) => {
        verifyUserAndRedirect(response.user);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (
          error.message.includes(
            "auth/account-exists-with-different-credential"
          )
        )
          setErrorMessage(
            "Looks like you used Github before to sign in. To proceed, sign in with Google."
          );
      });
  };

  const verifyUserAndRedirect = async (authedUser: User) => {
    // new user
    if (
      authedUser.metadata.creationTime === authedUser.metadata.lastSignInTime
    ) {
      console.log("creating new user in table, then redirecting");
      await createAndAuthenticateNewUser(authedUser);
    } else {
      console.log("existing user - redirecting");
    }

    if (typeof redirectTo === "string") navigate(redirectTo);
    else navigate("/");
  };

  //
  async function createAndAuthenticateNewUser(authedUser: User) {
    console.log("User created: " + authedUser.displayName);
    await createUser(authedUser);
  }

  return (
    <div className="signup-ct">
      <img className="logo" src={logo} alt={"logo"} />
      <button
        className="button__secondary"
        onClick={() => signInWithGoogle()}
        disabled={authenticating}
      >
        <img src={google_logo} alt={"google"} />
        Sign in with Google
      </button>

      <button
        className="button__secondary"
        onClick={() => signInWithGithub()}
        disabled={authenticating}
      >
        <img src={github_logo} alt={"github"} />
        Sign in with Github
      </button>

      <p className="sign-in-error">{errorMessage}</p>

      <p className="tc">
        By signing in, you confirm that you have read and agreed with our{" "}
        <a
          href="https://ensembleui.com/Terms_and_Conditions.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        ,{" "}
        <a href="https://ensembleui.com/privacy_policy.html" target="blank">
          Privacy Policy
        </a>
        , and{" "}
        <a href="https://ensembleui.com/EULA.html" target="blank">
          EULA
        </a>
        .
      </p>
    </div>
  );
};
export default SignIn;
