import React from "react";
import "./ArtifactInputsModel.sass";
// APIs
import { useModal } from "../../../hooks/useModal";

// Components
import Modal from "../../Modal";
import ArtifactInputsModalContent from "./ArtifactInputsModalContent";

// Interfaces
import { KeyValuePair } from "../../../config/interfaces";

interface ArtifactInputsProps {
  isModalDisplayed: boolean;
  inputs: KeyValuePair[];
  handleInputsModalTrigger: () => void;
  handleSetInputs: (inputs: KeyValuePair[]) => void;
}

const ArtifactInputsModal: React.FC<ArtifactInputsProps> = ({
  isModalDisplayed,
  inputs,
  handleInputsModalTrigger,
  handleSetInputs,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    handleInputsModalTrigger();
    toggleModal();
  };

  const onSetInputs = (inputs: KeyValuePair[]) => {
    handleSetInputs(inputs);
    onCancel();
  };

  return (
    <Modal
      isModalDisplayed={isModalDisplayed}
      onHide={onCancel}
      headerText="Inputs"
      modalContent={
        <ArtifactInputsModalContent
          defaultInputs={inputs}
          onCancel={onCancel}
          handleSetInputs={onSetInputs}
        />
      }
    />
  );
};

export default ArtifactInputsModal;
