import { YAMLDocNode } from "../hooks/useYamlDoc";
import {
  isMap,
  isPair,
  isScalar,
  isSeq,
  Pair,
  Scalar,
  YAMLMap,
  YAMLSeq,
} from "yaml";

// return the text representation of pair's key
export const getPairKeyText = (pair: Pair): string => {
  return (pair.key as Scalar).value?.toString() ?? "";
};

// add a new Pair to the parent
export const addValuePair = (parentPair: Pair, valuePair: Pair): void => {
  if (!isMap(parentPair.value)) {
    parentPair.value = new YAMLMap();
  }
  (parentPair.value as YAMLMap).items.push(valuePair);
};

export const updateNodeId = (node: YAMLDocNode, id: string | null): boolean => {
  if (!isPair(node)) return false;

  if (isMap(node.value)) {
    if (!id) {
      return (node.value as YAMLMap).delete("id");
    }
    (node.value as YAMLMap).set(new Scalar("id"), id);
  } else {
    node.value = new YAMLMap();
    if (id) {
      (node.value as YAMLMap).set(new Scalar("id"), id);
    }
  }
  return true;
};

export const removeNodeFromParent = (
  parent: YAMLMap | YAMLSeq,
  node: YAMLDocNode,
): boolean => {
  if (isMap(parent)) {
    return (parent as YAMLMap).delete((node as Pair).key);
  }
  if (isSeq(parent)) {
    if (isPair(node)) {
      return parent.delete(node.key);
    } else if (isScalar(node)) {
      return parent.delete(node);
    }
  }
  return false;
};
