export const HOTJAR_EXCLUDE_LIST = [
  "amin.nas@gmail.com",
  "amin@ensembleui.com",
  "vu@ensembleui.com",
  "vusters@gmail.com",
  "khurram@ensembleui.com",
  "khurram.mahmood@gmail.com",
  "ruthlhanan@gmail.com",
  "hey@hemish.dev",
  "vinothvino42@gmail.com",
  "sneh@ensembleui.com",
  "sharjeel924@gmail.com",
  "sibteali786@gmail.com",
  "evan@ensembleui.com",
  "5922malikanser@gmail.com",
  "snehmehta933@gmail.com",
  "sharjeel@ensembleui.com",
  "ahsen.waqar@gmail.com",
];

export const HOTJAR_INCLUDE_LIST = [
  "faizansattar1490@gmail.com",
  "javeriazh@gmail.com",
];
