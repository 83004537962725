import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { EnsembleWidgetData } from "../../config/interfaces";
import ScriptActionsMenu from "../ActionMenus/ScriptActionsMenu";

interface ScriptCardProps {
  script: EnsembleWidgetData;
  app_id: string;
  isReadOnly: boolean;
}

const ScriptCard: React.FC<ScriptCardProps> = ({
  script,
  app_id,
  isReadOnly,
}) => {
  return (
    <Link to={`/app/${app_id}/script/${script.id}`}>
      <div className="script-screen-card">
        <div className="script-screen-card__content space-between">
          <h4>{script.name}</h4>
          {!isReadOnly && (
            <ScriptActionsMenu script={script} isReadOnly={isReadOnly} />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default ScriptCard;
