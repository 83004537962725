import React, { useMemo, useState, useEffect, useRef, LegacyRef } from "react";
import { getTreeIcon } from "../../utils/treeUtils";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";

export type WidgetSelectionItem = {
  key: string;
  exampleLink?: string;
};

export type WidgetSelectionPanelProps = {
  isShowing?: boolean;
  platformWidgets?: WidgetSelectionItem[];
  customWidgets?: WidgetSelectionItem[];
  onWidgetSelect?: (widgetKey: string) => void;
  panelRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
};

enum TabType {
  platform = "platform",
  custom = "custom",
}

export const WidgetSelectionPanel: React.FC<WidgetSelectionPanelProps> = ({
  isShowing,
  platformWidgets,
  customWidgets,
  onWidgetSelect,
  panelRef,
  style,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.platform);

  const availableWidgets = useMemo(() => {
    if (searchValue) {
      return [...(platformWidgets ?? []), ...(customWidgets ?? [])]
        .filter((widget) =>
          widget.key.toLowerCase().includes(searchValue.toLowerCase()),
        )
        .sort();
    } else {
      return activeTab === TabType.platform
        ? platformWidgets?.sort()
        : customWidgets?.sort();
    }
  }, [searchValue, activeTab, platformWidgets, customWidgets]);

  const [widgetFocusedIndex, rootRef] = useKeyboardNavigation(
    availableWidgets?.length ?? 0,
  );

  useEffect(() => {
    if (isShowing) {
      searchInputRef.current?.focus();
    } else {
      setSearchValue("");
    }
  }, [isShowing]);

  const handleWidgetSelect = (widgetKey: string) => {
    setSearchValue("");
    onWidgetSelect?.(widgetKey);
  };

  if (!isShowing) {
    return null;
  }

  return (
    <div className="widget-selection-panel" style={style} ref={panelRef}>
      <div className={"widget-selection-panel-header"}>
        <input
          type="search"
          ref={searchInputRef}
          placeholder="Find a widget ..."
          value={searchValue} // Set the input value to the search state
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          className={`widget-selection-tab-container ${
            searchValue ? "widget-selection-disabled-tab-container" : ""
          }`}
        >
          <span
            className={
              activeTab === TabType.platform
                ? "widget-selection-active-tab"
                : "widget-selection-inactive-tab"
            }
            onClick={() => {
              setActiveTab(TabType.platform);
              searchInputRef.current?.focus();
            }}
          >
            PLATFORM
          </span>
          <span
            className={
              activeTab === TabType.custom
                ? "widget-selection-active-tab"
                : "widget-selection-inactive-tab"
            }
            onClick={() => {
              setActiveTab(TabType.custom);
              searchInputRef.current?.focus();
            }}
          >
            CUSTOM
          </span>
        </div>
      </div>

      <div className={"widget-selection-panel-body"}>
        {availableWidgets?.map((widget, index) => (
          <div
            key={widget.key}
            onClick={() => handleWidgetSelect(widget.key)}
            tabIndex={index}
            role="button"
            ref={
              index === widgetFocusedIndex
                ? (rootRef as LegacyRef<HTMLDivElement>)
                : null
            }
          >
            {getTreeIcon(widget.key)}
            <span>{widget.key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
