import React from "react";

// APIs
import { useModal } from "../../../hooks/useModal";

// Components
import Modal from "../../Modal";
import WidgetArchiveModal from "./WidgetArchiveModal";

// Interfaces
import { EnsembleWidgetData } from "../../../config/interfaces";

interface WidgetArchiveProps {
  widget: EnsembleWidgetData;
  displayArchiveModal: boolean;
  setArchiveTrigger: any;
}

const WidgetArchive: React.FC<WidgetArchiveProps> = ({
  widget,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={onCancel}
      headerText={`Archive ${widget.name}?`}
      modalContent={
        <WidgetArchiveModal
          widget={widget}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default WidgetArchive;
