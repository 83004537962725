import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../App";

// APIs
import { useUpdateScreen, useGetArtifactHistory } from "../hooks/useAPIs";

// Interfaces
import { AppData } from "../models/model";

interface AppArchiveModalProps {
  app: AppData;
  screenId: string;
  editorContent: string;
  onCancel: () => void;
}

const CheckinModalContent: React.FC<AppArchiveModalProps> = ({
  app,
  screenId,
  editorContent,
  onCancel,
}) => {
  const { currentUser } = useContext(AuthContext);

  const [checkinName, setCheckinName] = useState("");
  const [checkinComment, setCheckinComment] = useState("");

  const artifactHistoryQuery = useGetArtifactHistory(app.id, screenId);
  const updateScreenQuery = useUpdateScreen(currentUser, app.id, screenId);

  const checkinNameChange = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setCheckinName(event.currentTarget.value);
  };

  const checkinCommentChange = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setCheckinComment(event.currentTarget.value);
  };

  const handleCreateCheckin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateScreenQuery.mutate({
      content: editorContent,
      label: checkinName,
      comment: checkinComment,
    });
  };

  // update complete
  useEffect(() => {
    if (updateScreenQuery.isSuccess) {
      onCancel();
      artifactHistoryQuery.refetch();
    }
  }, [updateScreenQuery.status]);

  return (
    <form className="app-create-modal" onSubmit={handleCreateCheckin}>
      <label>Name</label>
      <input
        type="text"
        value={checkinName}
        placeholder="e.g. v2"
        onChange={checkinNameChange}
        autoFocus
      />

      <label>Comment</label>
      <input
        type="text"
        placeholder="Update"
        value={checkinComment}
        onChange={checkinCommentChange}
      />
      <br />

      <button
        type="submit"
        className="button__primary"
        disabled={!checkinName || !checkinComment}
      >
        {updateScreenQuery.isIdle && <>Save</>}
        {updateScreenQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default CheckinModalContent;
