import React from "react";
import { EnsembleTranslationData } from "../config/interfaces";
import { useModal } from "../hooks/useModal";
import Modal from "./Modal";
import { LanguageArchiveModal } from "./LanguageArchiveModal";
import { getLanguageCode, getTranslationLanguageName } from "../utils/Util";

interface LanguageArchiveProps {
  language: EnsembleTranslationData; // Adjust type as necessary
  displayArchiveModal: boolean;
  setArchiveTrigger: (val: boolean) => void;
}

const LanguageArchive: React.FC<LanguageArchiveProps> = ({
  language,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();
  const languageCode = getLanguageCode(language);

  const onCancel = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    // Implement language archiving logic here
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={onCancel}
      headerText={`Archive ${getTranslationLanguageName(languageCode)}`} // Adjust property if necessary
      modalContent={
        <LanguageArchiveModal
          language={language}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default LanguageArchive;
