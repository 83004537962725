import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import {
  ChangeHandler,
  MonacoEditor,
} from "../../components/Monaco/MonacoEditor";
import { Button, ButtonVariant } from "../../components/Button/Button";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ensemble from "../../types/ensemble.d.ts?raw";
import {
  convertFunctionStrings,
  generateTypeDeclarations,
} from "../../utils/ts_generator";

export type JavascriptEditorProps = {
  value: string;
  onSave: (value: string) => void;
  onCancel: () => void;
};

export const JavascriptEditor: React.FC<JavascriptEditorProps> = ({
  value,
  onSave,
  onCancel,
}) => {
  const [editorContent, setEditorContent] = useState(value);
  const modelRef = useRef<monaco.editor.ITextModel>();
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const linter: Worker = useMemo(
    () => new Worker(new URL("./linterWorker.ts", import.meta.url)),
    [],
  );

  const handleContextMessage = (e: MessageEvent) => {
    if (
      (e.source as WindowProxy).location.pathname === "/preview/" ||
      (e.source as WindowProxy).location.pathname.startsWith("/app")
    ) {
      try {
        if (e.data && e.data.type === "context") {
          convertFunctionStrings(e.data.message.context);
          const ensembleTypeDefs: string = generateTypeDeclarations(
            e.data.message.context,
          );
          monaco.languages.typescript.javascriptDefaults.addExtraLib(
            ensembleTypeDefs,
            "globals.d.ts",
          );
        }
      } catch (err) {
        return false;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleContextMessage);

    return () => {
      window.removeEventListener("message", handleContextMessage);
    };
  }, []);

  const onChangeCallback = useCallback<ChangeHandler>(
    (value, event, model) => {
      setEditorContent(value);
      if (!window.Worker || !model) {
        return;
      }
      // Reset the markers
      monaco.editor.setModelMarkers(model, "eslint", []);

      // Send the code to the worker
      linter.postMessage({
        value: model.getValue(),
        // Unique identifier to avoid displaying outdated validation
        version: model.getVersionId(),
      });
    },
    [linter],
  );

  useEffect(() => {
    linter.onmessage = ({ data }) => {
      const { markers, version } = data;
      const model = modelRef.current;
      if (model && model.getVersionId() === version) {
        monaco.editor.setModelMarkers(model, "eslint", markers);
      }
    };
  }, [linter]);

  const onSaveCallback = useCallback(() => {
    onSave(editorContent);
  }, [editorContent, onSave]);

  useEffect(() => {
    // validation settings
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let blah: boolean;
    // compiler options
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES5,
      lib: ["es5"],
      noLib: true,
      allowNonTsExtensions: true,
      allowJs: true,
      checkJs: true,
    });

    const libUri = "file:///src/types/ensemble.d.ts";
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      ensemble,
      libUri,
    );
    if (!monaco.editor.getModel(monaco.Uri.parse(libUri))) {
      monaco.editor.createModel(
        ensemble,
        "typescript",
        monaco.Uri.parse(libUri),
      );
    }
  }, []);

  return (
    <div
      style={{
        width: "800px",
        height: "600px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MonacoEditor
        editor={editor}
        value={value}
        modelRef={modelRef}
        key={"js-editor-v1"}
        onChange={onChangeCallback}
        onSave={onSaveCallback}
        options={{ language: "javascript" }}
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}>
        <Button variant={ButtonVariant.PRIMARY} onClick={onSaveCallback}>
          Done
        </Button>
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={onCancel}
          style={{ marginLeft: "1em" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
