import { configureMonacoYaml, MonacoYaml } from "monaco-yaml";
import * as monaco from "monaco-editor";
import { EnsembleSchema } from "../hooks/useSchemas";

// Ensure configureMonacoYaml() is called once ever per requirement
let instance: MonacoYaml;

// base configuration options
const baseOptions = {
  validate: true,
  enableSchemaRequest: true,
  format: true,
  hover: true,
  completion: true,
};

/**
 * Configure different schemas (screen, widget, or theme) only once
 */
export const configureYamlSchema = (schema?: EnsembleSchema) => {
  // update if already called once before
  if (instance) {
    instance.update({
      ...baseOptions,
      schemas: schema ? [schema] : [],
    });
  } else {
    instance = configureMonacoYaml(monaco, {
      ...baseOptions,
      schemas: schema ? [schema] : [],
    });
  }
};
