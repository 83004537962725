import React, { useEffect, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import { IconOptionItem } from "./SelectWidget";
import { Tooltip } from "antd";
import { getOptionTooltip, getVisualEditorIcon } from "../utils/rendererUtils";
import "./IconSelectWidget.sass";
import { CustomJSONSchema7 } from "../utils/propertyPanelSchemaUtils";

interface IconSelectWidgetProps extends WidgetProps {
  iconOptions: IconOptionItem[];
}

export const IconSelectWidget: React.FC<IconSelectWidgetProps> = ({
  formContext,
  schema,
  value = (schema as CustomJSONSchema7).defaultValue,
  onChange,
  iconOptions,
}) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const handleOnClick = (newValue: string) => {
    if (newValue !== value) {
      onChange(newValue);
      setHasPendingChanges(true);
    }
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges, formContext]);

  return (
    <div className="icon-select-widget">
      {iconOptions?.map((option: IconOptionItem) => (
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={getOptionTooltip(option)}
          placement="top"
          key={option.value}
        >
          <div
            className={`icon-select-widget-cell ${
              value === option.value ? "selected" : ""
            }`}
            onClick={() => handleOnClick(option.value)}
          >
            {getVisualEditorIcon(option.icon)}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
