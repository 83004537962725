import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "./AppPagesWrapper";

// Components
import TemplateScreenCard from "../components/TemplateScreenCard";
import { useGetCategories, useGetTemplateScreens } from "../hooks/useAPIs";

const CreateAppScreen: React.FunctionComponent = () => {
  const { app, isAppReadOnly } = useAppContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<boolean>(false);
  const [screenNameInput, setScreenNameInput] = useState("");
  const categories = useGetCategories();
  const screenDetailsQuery = useGetTemplateScreens();
  const screen = screenDetailsQuery.data;

  const [selectedCategories, setSelectedCategories] = useState<string>("All");

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setError(false);
    setScreenNameInput(event.currentTarget.value);
  };

  const categorieChange = (value: string): void => {
    setSelectedCategories(value);
  };

  const validateScreenName = () => {
    setError(true);
    inputRef.current?.focus();
  };

  return (
    <div className="screen-content" key={app.id}>
      <div className="page-header">
        <div className={`input-box ${error ? "error" : ""}`}>
          <label>Enter Screen name</label>
          <input
            type="text"
            autoFocus
            ref={inputRef}
            value={screenNameInput}
            onChange={screenNameChange}
          />
          <span className="input-box__error">Screen name is required</span>
        </div>
      </div>
      <h4>{`Select a template to begin with`}</h4>

      <div className="segment">
        <div
          className={`label ${selectedCategories === "All" ? "selected" : ""}`}
          onClick={() => categorieChange(`All`)}
        >
          {`All`}
        </div>
        {categories.data?.category
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c, i) => (
            <div
              key={i}
              className={`label ${
                c.id === selectedCategories ? "selected" : ""
              }`}
              onClick={() => categorieChange(c.id)}
            >
              {c.name}
            </div>
          ))}
      </div>
      <div className="screen-cards-ct">
        {screen?.screens && screen?.screens?.length > 0 && (
          <>
            {screen?.screens
              ?.filter((f) =>
                selectedCategories === "All"
                  ? true
                  : f.category === selectedCategories,
              )
              .map((scr) => (
                <TemplateScreenCard
                  key={scr.name}
                  screenName={screenNameInput}
                  screen={scr}
                  app_id={app.id}
                  isReadOnly={isAppReadOnly}
                  validateScreenName={validateScreenName}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateAppScreen;
