import { useState } from 'react';

export const useModal = () => {
  const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false);
  const toggleModal = () => setIsModalDisplayed(!isModalDisplayed);
  return {
    isModalDisplayed,
    toggleModal,
  };
};


export const useGeneratingModal = () => {
  const [isGeneratingModalDisplayed, setIsGeneratingModalDisplayed] = useState<boolean>(false);
  const toggleGeneratingModal = () => setIsGeneratingModalDisplayed(!isGeneratingModalDisplayed);
  return {
    isGeneratingModalDisplayed,
    toggleGeneratingModal,
  };
};
