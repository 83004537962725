import React, { useState, createRef, useEffect, useContext } from "react";
import { useAppContext } from "../pages/AppPagesWrapper";
import { AuthContext } from "../App";

// APIs
import { useUpdateScreen } from "../hooks/useAPIs";

// Interfaces
import { EnsembleScreenData } from "../config/interfaces";

interface ScreenRenameModalProps {
  screen: EnsembleScreenData;
  onCancel: () => void;
  onSubmit: () => void;
}

const ScreenRenameModal: React.FC<ScreenRenameModalProps> = ({
  screen,
  onSubmit,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [screenName, setScreenName] = useState(screen.name);
  const updateScreenQuery = useUpdateScreen(currentUser, app.id, screen.id);
  const screenNameInputRef = createRef<HTMLInputElement>();
  const submitButtonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    screenNameInputRef.current?.focus();
  }, [screenNameInputRef]);

  const handleInputChange = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setScreenName(event.currentTarget.value);
  };

  // submit on Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      submitButtonRef.current?.click();
    }
  };

  // trigger update
  const renameApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    updateScreenQuery.mutate({
      name: screenName,
    });
  };

  // update complete
  useEffect(() => {
    if (updateScreenQuery.isSuccess) onSubmit();
  }, [onSubmit, updateScreenQuery.isSuccess]);

  return (
    <form className="app-create-modal" onSubmit={renameApp}>
      <input
        type="text"
        ref={screenNameInputRef}
        value={screenName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <br />
      <button type="submit" ref={submitButtonRef} className="button__primary">
        {updateScreenQuery.isIdle && <>Rename screen</>}
        {updateScreenQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default ScreenRenameModal;
