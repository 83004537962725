import {
  collection,
  doc,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import {
  EnsembleScreenData,
  EnsembleWidgetData,
  EnsembleThemeData,
  EnsembleTranslationData,
  EnsembleAssetData,
} from "../config/interfaces";
import { db } from "../config/firebase";

export class Model {
  constructor(data: Model) {
    this.id = data.id;
  }
  id: string;
}

export class GeneratingAppData {
  constructor(id: string, name: string, status?: string) {
    this.id = id;
    this.name = name;
    this.status = status;
  }
  id: string;
  name: string;
  status?: string;
}

// represents an App
export class AppData extends Model {
  constructor(data: AppData) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.isArchived = data.isArchived;
    this.isPublic = data.isPublic;
    this.isAutoGenerated = data.isAutoGenerated;
    this.isReact = data.isReact;
    this.status = data.status;
    this.category = data.category;
    this.collaborators = data.collaborators;
    this.demoOrder = data.demoOrder;
  }
  name?: string;
  description?: string;
  isArchived?: boolean;
  isPublic?: boolean;
  isAutoGenerated?: boolean;
  isReact?: boolean;
  status?: string;
  category?: AppCategory;
  collaborators?: Map<string, AccessType>;
  demoOrder?: number;

  // populate manually
  accessType?: AccessType;
  screens?: EnsembleScreenData[];
  internalWidgets?: EnsembleWidgetData[];
  internalScripts?: EnsembleWidgetData[];
  translations?: EnsembleTranslationData[];
  theme?: EnsembleThemeData;
  assets?: EnsembleAssetData[];

  // return a reference to the docId
  static doc(appId: string) {
    return doc(db, "apps", appId).withConverter(this.converter);
  }

  static collection() {
    return collection(db, "apps").withConverter(this.converter);
  }

  static get converter() {
    return {
      fromFirestore: (
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions,
      ) => {
        const data = snapshot.data(options);

        const collaborators = new Map<string, AccessType>();
        for (const key in data.collaborators) {
          if (key.startsWith("users_")) {
            collaborators.set(
              key.replace(/^users_/, ""),
              data.collaborators[key] as AccessType,
            );
          }
        }

        return {
          id: snapshot.id,
          name: data.name,
          description: data.description,
          isArchived: data.isArchived,
          isPublic: data.isPublic,
          isAutoGenerated: data.isAutoGenerated,
          isReact: data.isReact,
          status: data.status,
          category: data.category as AppCategory,
          collaborators: collaborators,
          demoOrder: data.demoOrder,
        } as AppData;
      },
      toFirestore: (appData: AppData) => {
        return {
          id: appData.id,
          name: appData.name,
          description: appData.description,
          isArchived: appData.isArchived,
          isPublic: appData.isPublic,
          isAutoGenerated: appData.isAutoGenerated,
          isReact: appData.isReact,
          status: appData.status,
          category: appData.category as string,
          demoOrder: appData.demoOrder,
          //collaborators: appData.collaborators
        };
      },
    };
  }
}

export class CollaboratorData extends Model {
  constructor(data: CollaboratorData) {
    super(data);
    this.name = data.name;
    this.email = data.email;
    this.accessType = data.accessType;
  }
  accessType: AccessType;
  name?: string;
  email?: string;

  static doc(userId: string) {
    return doc(db, "users", userId).withConverter(this.converter);
  }

  static get converter() {
    return {
      fromFirestore: (
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions,
      ) => {
        const data = snapshot.data(options);

        return {
          id: snapshot.id,
          name: data.name,
          email: data.email,
        } as CollaboratorData;
      },
      toFirestore: (collaboratorData: CollaboratorData) => {
        return {
          id: collaboratorData.id,
          name: collaboratorData.name,
          email: collaboratorData.email,
        };
      },
    };
  }
}

export class AppConfigData {
  constructor(data?: AppConfigData) {
    this.id = data?.id;
    this.baseUrl = data?.baseUrl;
    this.useBrowserUrl = data?.useBrowserUrl;
    this.envVariables = data?.envVariables;
  }
  id?: string;
  baseUrl?: string;
  useBrowserUrl?: boolean;
  envVariables?: Map<string, string>;

  static get converter() {
    return {
      fromFirestore: (
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions,
      ) => {
        const data = snapshot.data(options);

        const variables: Map<string, unknown> = new Map();
        if (data.envVariables != null) {
          for (const key in data.envVariables) {
            variables.set(key, data.envVariables[key]);
          }
        }

        return {
          id: snapshot.id,
          baseUrl: data.baseUrl,
          useBrowserUrl: data.useBrowserUrl,
          envVariables: variables,
        } as AppConfigData;
      },
      toFirestore: (data: AppConfigData) => {
        return {
          baseUrl: data.baseUrl,
          useBrowserUrl: data.useBrowserUrl ?? null,
          envVariables:
            data.envVariables != null
              ? Object.fromEntries(data.envVariables)
              : undefined,
        };
      },
    };
  }
}

export enum AppCategory {
  Demo = "Demo",
  Template = "Template",
}

export enum AccessType {
  read = "read",
  write = "write",
  owner = "owner",
}

export class AppSecrets {
  constructor(data?: AppSecrets) {
    this.id = data?.id;
    this.secrets = data?.secrets;
  }
  id?: string;
  secrets?: Map<string, string>;

  static get converter() {
    return {
      fromFirestore: (
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions,
      ) => {
        const data = snapshot.data(options);

        const variables: Map<string, unknown> = new Map();
        if (data.secrets != null) {
          for (const key in data.secrets) {
            variables.set(key, data.secrets[key]);
          }
        }

        return {
          id: snapshot.id,
          secrets: variables,
        } as AppSecrets;
      },
      toFirestore: (data: AppSecrets) => {
        return {
          secrets:
            data.secrets != null ? Object.fromEntries(data.secrets) : undefined,
        };
      },
    };
  }
}
