import React from "react";
import ReactTooltip from "react-tooltip";

import AssetsActionsMenu from "./AssetsActionsMenu";

// Assets
import default_screen_image from "../../assets/image_placeholder.svg";

// Interfaces
import { EnsembleAssetData } from "../../config/interfaces";

interface AssetsCardProps {
  asset: EnsembleAssetData;
  isReadOnly?: boolean;
}

const AssetsCard: React.FC<AssetsCardProps> = ({ asset, isReadOnly }) => {
  const imageSrc = asset.publicUrl ?? default_screen_image;

  return (
    <>
      <div className="screen-card assets">
        <div
          className="screen-card__asset-image"
          style={{ backgroundImage: `url(${imageSrc}` }}
        ></div>

        <div className="screen-card__content">
          <h4>{asset.name}</h4>

          {/* assets actions */}
          {!isReadOnly && (
            <AssetsActionsMenu
              asset={asset}
              isTemplate={false}
              isReadOnly={false}
            />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </>
  );
};

export default AssetsCard;
