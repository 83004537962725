import * as firebase from "./useFirebase";
import {
  collection,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  Query,
  where,
  deleteField,
  setDoc,
  serverTimestamp,
  orderBy,
  and,
  or,
  onSnapshot,
} from "firebase/firestore";
import { auth, cloudFunctions, db } from "../config/firebase";
import { User } from "firebase/auth";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  AccessType,
  AppConfigData,
  AppData,
  AppSecrets,
  GeneratingAppData,
} from "../models/model";
import {
  EnsembleScreenData,
  EnsembleWidgetData,
  EnsembleThemeData,
  ISubscriptionData,
  IUserSubscriptionPlan,
  ICheckoutSession,
  ICancelSubscription,
  EnsembleAssetData,
  EnsembleTranslationData,
} from "../config/interfaces";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Changeset } from "../components/PublishingReport";
import axios from "axios";

export const useGetUserDetails = firebase.useGetUserDetails;
export const useGetScreenDetails = firebase.useGetScreenDetails;
export const useGetTemplateScreens = firebase.useGetTemplateScreens;
export const useGetCategories = firebase.useGetCategories;
export const useGetArtifactHistory = firebase.useGetArtifactHistory;
export const useUpdateScreen = firebase.useUpdateScreen;
export const useUpdateWidget = firebase.useUpdateWidget;
export const useCreateApp = firebase.useCreateApp;
export const useCreateScreens = firebase.useCreateScreens;
export const useCreateTheme = firebase.useCreateTheme;
export const useUpdateTheme = firebase.useUpdateTheme;
export const useUpdateApp = firebase.useUpdateApp;
export const useResetRootScreen = firebase.useResetRootScreen;
export const useGetAppFullContent = firebase.useGetAppFullContent;

/// the document ID of the app configuration (only one per application)
const CONFIG_ID = "appConfig";
const SECRETS_ARTIFACT_NAME = "secrets";

// get the userId from authId via Firebase custom claim
export async function getUserId(): Promise<string | undefined> {
  return auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
    // console.log("ID TOKEN : ", idTokenResult);
    // if userId claim is not there, ask the server to set it
    if (!idTokenResult.claims.userId) {
      // console.log("UserId claim not found. Refreshing it");
      // hit cloud function
      return updateUserClaim().then(() => {
        // force token refresh
        return auth
          .currentUser!.getIdTokenResult(true)
          .then((refreshedToken) => refreshedToken.claims.userId);
      });
    }
    return idTokenResult.claims.userId;
  });
}
function updateUserClaim() {
  const setClaim = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_SET_USERID_AUTH_CLAIM!,
  );

  return setClaim()
    .then(() => {
      // console.log("Success" + result.data?.toString());
    })
    .catch((error) => {
      console.log("Error " + error.code + error.toString());
    });
}

/*calls cloud function to verify invite and adds collaborator*/
export async function verifyAppInvite(
  inviteRef: string,
  inviteeEmail: string,
  app_id: string,
) {
  const verifyInvite = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_VERIFY_APP_INVITE!,
  );

  const inviteIds = await getInviteIds(app_id, inviteeEmail);

  inviteIds.forEach((inviteId) => {
    setDoc(
      doc(db, "invites", inviteId),
      {
        isAccepted: true,
      },
      { merge: true },
    );
  });

  return verifyInvite({ ref: inviteRef });
}

export const getInviteIds = async (appId: string, inviteeEmail: string) => {
  const q = query(
    collection(db, "invites"),
    where("template.data.appId", "==", appId) &&
      where("template.data.inviteeEmail", "==", inviteeEmail),
  );
  const snapshot = await getDocs(q);
  const inviteIds: string[] = [];
  snapshot.forEach((doc) => {
    inviteIds.push(doc.id);
  });
  return inviteIds;
};

export function generateApp(query: string) {
  console.log(
    `URL: ${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GENERATE_APP!}`,
  );
  const generate = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GENERATE_APP!,
  );
  return generate({
    query: query,
  });
}

export async function getAssets(appId: string): Promise<EnsembleAssetData[]> {
  try {
    const response = await cloudFunctions.studio_get_assets({
      appId: appId,
    });
    const assets: EnsembleAssetData[] = [];
    if (response.data) {
      const studioAssets = response.data.assets ?? response.data;
      studioAssets.forEach((asset: any) => {
        assets.push(
          new EnsembleAssetData(
            asset.id,
            asset.asset.fileName,
            asset.asset.content,
            asset.asset.publicUrl,
            asset.asset.copyText,
          ),
        );
      });
    }
    return assets;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function uploadAsset(
  appId: string,
  fileName: string,
  fileData: string,
) {
  return cloudFunctions.studio_upload_asset({
    appId,
    fileName,
    fileData,
  });
}

export async function deleteAsset(appId: string, documentId: string) {
  return cloudFunctions.studio_delete_asset({
    appId,
    documentId,
  });
}

export async function downloadApp(appId: string) {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.post(
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_STUDIO_APP_DOWNLOAD!,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: "application/zip",
      },
      params: { appId },
      responseType: "blob",
    },
  );
  return response;
}

export function useCreateCheckoutSession(
  productId: string,
  email: string,
  redirectUrl: string,
) {
  return useMutation(async () => {
    const generate = httpsCallableFromURL(
      getFunctions(),
      process.env.REACT_APP_FIREBASE_FUNCTIONS_CREATE_CHECKOUT_SESSION!,
    );
    const result = (await generate({
      productId,
      email,
      redirectUrl,
    })) as ICheckoutSession;
    return {
      sessionUrl: result.data?.sessionUrl,
    };
  });
}

export function useCancelSubscription(subscriptionId: string | undefined) {
  return useMutation(async () => {
    const generate = httpsCallableFromURL(
      getFunctions(),
      process.env.REACT_APP_FIREBASE_FUNCTIONS_CANCEL_SUBSCRIPTION!,
    );
    const result = (await generate({
      subscriptionId,
    })) as ICancelSubscription;
    return result.data;
  });
}

// whether the user has completed the intro questionnaire
export function getQuestionnaireStatus(
  userId: string,
): Promise<boolean | undefined> {
  return getDoc(doc(db, "users", userId)).then(
    (snapshot) => snapshot.data()?.questionnaireAttempted,
  );
}

export async function createUser(authedUser: User): Promise<string> {
  const timestamp = serverTimestamp();

  await addDoc(collection(db, "users"), {
    authId: authedUser.uid,
    email: authedUser.email,
    name: authedUser.displayName,
    questionnaireAttempted: false,
    createdAt: timestamp,
    updatedAt: timestamp,
  });

  // TODO: what if the cloud function has not completed by the time this is executed ???
  return authedUser.getIdTokenResult(true).then((token) => {
    // console.log("Claim userId: " + token.claims.userId);
    return token.claims.userId;
  });
}

// return all Subscription plans
export function useGetSubscriptionPlans() {
  return useQuery(["subscriptionPlans"], async () => {
    const q = query(
      collection(db, "subscriptionPlans"),
      orderBy("name", "asc"),
    );

    const result = await getDocs(q).then((snapshot) => {
      return snapshot.docs.map((doc) => {
        return doc.data() as ISubscriptionData;
      });
    });
    return result;
  });
}

// return all Subscription plans
export function getUserSubscriptionPlan(
  userId: string,
  callback: (data: IUserSubscriptionPlan) => void,
) {
  const q = query(
    collection(db, "users", userId, "subscriptions"),
    where("status", "in", ["Active", "Trial"]),
  );

  onSnapshot(q, (snapshot) => {
    if (!snapshot.empty) {
      const data = snapshot.docs[0].data();
      callback({
        id: snapshot.docs[0].id,
        status: data.status,
        subscriptionPlanId: data.subscriptionPlanId,
        nextPayment: data.nextPayment,
      });
    } else {
      callback({ status: "Active" });
    }
  });
}

// return all Demo Apps + Apps belong to this user
export async function getApps(userId: string): Promise<AppData[]> {
  return Promise.all([getUserApps(userId), getDemoApps(userId)]).then(
    (results) => results.flat(1),
  );
}

async function getDemoApps(userId: string): Promise<AppData[]> {
  const q = query(
    AppData.collection(),
    where("category", "==", "Demo"),
    where("isArchived", "==", false),
    where("isPublic", "==", true),
  );
  return getAppsFromQuery(q, userId);
}

async function getUserApps(userId: string): Promise<AppData[]> {
  const q = query(
    AppData.collection(),
    where("isArchived", "==", false),
    where("collaborators.users_" + userId, "in", ["read", "write", "owner"]),
  );
  return getAppsFromQuery(q, userId);
}

export async function getGeneratingApps(
  userId: string,
): Promise<GeneratingAppData[]> {
  const q = query(
    collection(db, "apps"),
    where("isAutoGenerated", "==", true),
    where("collaborators.users_" + userId, "in", ["read", "write", "owner"]),

    // TODO: we want to exclude Success from this list, but Firestore then requires index to use it.
    //  For now exclude Success manually on client
    //where("status", "!=", "Success"),
  );
  return getDocs(q).then((snapshot) =>
    snapshot.docs.map((doc) => {
      const data: DocumentData = doc.data();
      return new GeneratingAppData(doc.id, data["name"], data["status"]);
    }),
  );
}

// get the user's App by ID
export async function getApp(
  appId: string,
  userId: string,
): Promise<AppData | null> {
  // first check if this app belongs to the user
  const q = query(
    AppData.collection(),
    and(
      where(documentId(), "==", appId),
      or(
        where("collaborators.users_" + userId, "in", [
          "read",
          "write",
          "owner",
        ]),
        where("isPublic", "==", true),
        where("category", "==", "Demo"),
      ),
    ),
  );

  const apps: AppData[] = await getAppsFromQuery(q, userId);

  // if app is valid, get its artifacts
  if (apps.length !== 0) {
    const app = apps[0];
    const artifacts = await getArtifacts(doc(db, "apps", appId));
    const assets = await getAssets(appId);
    app.theme = artifacts.theme;
    app.screens = artifacts.screens;
    app.internalWidgets = artifacts.internalWidgets;
    app.internalScripts = artifacts.internalScripts;
    app.translations = artifacts.translations;
    app.assets = assets;
    return app;
  }
  return null;
}

// fetch Apps from query
async function getAppsFromQuery(q: Query<AppData>, userId: string) {
  return getDocs(q).then((snapshot) =>
    snapshot.docs.map((doc) => {
      const app: AppData = doc.data();

      // figure out my access level to this App
      app.accessType = app.collaborators?.get(userId) || AccessType.read;

      return app;
    }),
  );
}

// return all artifacts of an App.
// Note that there is no permission checks here.
// Make sure appRef belongs to the user
export async function getArtifacts(appRef: DocumentReference<DocumentData>) {
  const snapshot = await getDocs(
    query(collection(appRef, "artifacts"), where("isArchived", "!=", true)),
  );
  const internalArtifactsSnapshot = await getDocs(
    query(
      collection(appRef, "internal_artifacts"),
      where("isArchived", "!=", true),
    ),
  );

  let theme;
  const screens = [];
  const internalWidgets = [];
  const internalScripts = [];
  const translations = [];
  for (const artifact of snapshot.docs) {
    const myData = artifact.data();
    if (artifact.data()["type"] === "screen") {
      screens.push(
        new EnsembleScreenData(
          artifact.id,
          myData["name"],
          myData["isArchived"],
          myData["isRoot"],
          myData["content"],
          myData["isDraft"],
          myData["category"],
        ),
      );
    } else if (artifact.data()["type"] === "theme") {
      theme = new EnsembleThemeData(
        artifact.data()["content"],
        artifact.id,
        artifact.data()["isArchived"],
      );
    } else if (artifact.data()["type"] === "i18n") {
      translations.push(
        new EnsembleTranslationData(
          artifact.id,
          artifact.data()["isArchived"],
          artifact.data()["isRoot"],
          artifact.data()["type"],
          artifact.data()["content"],
          artifact.data()["defaultLocale"],
        ),
      );
    }
  }
  for (const artifact of internalArtifactsSnapshot.docs) {
    const artifactData = artifact.data();
    if (artifactData["type"] === "internal_widget") {
      internalWidgets.push(
        new EnsembleWidgetData(
          artifact.id,
          artifactData["name"],
          artifactData["isArchived"],
          artifactData["isRoot"],
          artifactData["content"],
        ),
      );
    } else if (artifactData["type"] === "internal_script") {
      internalScripts.push(
        new EnsembleWidgetData(
          artifact.id,
          artifactData["name"],
          artifactData["isArchived"],
          artifactData["isRoot"],
          artifactData["content"],
        ),
      );
    }
  }
  return {
    screens,
    internalWidgets,
    internalScripts,
    theme,
    translations,
  };
}

export function useRemoveCollaborator(
  app_id: string,
  user_id: string | undefined,
) {
  const queryClient = useQueryClient();
  const leaveApp = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_LEAVE_APP!,
  );

  return useMutation(
    async () => {
      await leaveApp({ appId: app_id, userId: user_id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("apps");
        queryClient.invalidateQueries(["app", app_id]);
        queryClient.invalidateQueries(["user", user_id]);
        queryClient.invalidateQueries(["userApps", user_id]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useMergeApps(
  sourceAppId: string,
  destinationAppId: string,
  userId: string,
  changeset: Changeset, // additional parameter to include the changeset as a JSON object
) {
  // Define the mergeApps function
  const mergeApps = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_STUDIO_MERGE_APPS!,
  );

  return useMutation(
    async () => {
      const response = await mergeApps({
        sourceAppId: sourceAppId,
        destinationAppId: destinationAppId,
        userId: userId,
        changeset: changeset, // passing the changeset to the function call
      });
      return response;
    },
    {
      onSuccess: (data) => {
        // Print the JSON response to the console
        console.log("Success:", data);
      },
      onError: (error) => {
        console.log("Error: " + error);
      },
    },
  );
}

export function useGetPrepublishReport(
  sourceAppId: string,
  destinationAppId: string,
  userId: string,
) {
  // Define the getPrepublishReport function
  const getPrepublishReport = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GET_PREPUBLISH_REPORT!,
  );

  return useMutation(
    async () => {
      console.log(
        "about to call getPrepublishReport with source_appid: " +
          sourceAppId +
          " destination_appid: " +
          destinationAppId +
          " user_id: " +
          userId,
      );
      const response = await getPrepublishReport({
        sourceAppId: sourceAppId,
        destinationAppId: destinationAppId,
        userId: userId,
      });
      return response;
    },
    {
      onSuccess: (data) => {
        // Print the JSON response to the console
        console.log("Success:", data);
      },
      onError: (error) => {
        console.log("Error: " + error);
      },
    },
  );
}

export function useUpdateCollaboratorRole(
  app_id: string,
  user_id: string | undefined,
  accessType: AccessType,
) {
  const queryClient = useQueryClient();
  const userKey = "collaborators.users_" + user_id;
  return useMutation(
    async () => {
      const appRef = doc(db, "apps", app_id);

      await updateDoc(appRef, {
        [userKey]: accessType,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", app_id]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useInviteCollaborator(
  appId: string,
  appName: string | undefined,
  userId: string | undefined,
  userName: string | undefined | null,
  inviteeAccessType: AccessType,
  inviteeEmail: string,
) {
  return useMutation(
    async () => {
      const inviteRef = collection(db, "invites");
      const ref = crypto.randomUUID();
      await addDoc(inviteRef, {
        to: inviteeEmail,
        template: {
          name: "appInvite",
          data: {
            ref: ref,
            appId: appId,
            appName: appName,
            userId: userId,
            userName: userName,
            inviteeEmail: inviteeEmail,
            inviteeAccessType: inviteeAccessType,
          },
        },
        ref: ref,
      });
    },
    {
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useSaveQuestionnaireFeedback(
  data: QuestionnaireData,
  userId: string | undefined,
) {
  const feedbackRef = collection(db, "questionnaireFeedback");
  const userRef = doc(db, "users", userId!);

  const timestamp = serverTimestamp();

  return useMutation(async () => {
    await addDoc(feedbackRef, {
      ...data,
      createdAt: timestamp,
      user: userRef,
    });
    await updateDoc(userRef, {
      questionnaireAttempted: true,
    });
  });
}

export function useGetInvite(ref: string | undefined) {
  return useQuery(
    ["invite", ref],
    async () => {
      const q = query(collection(db, "invites"), where("ref", "==", ref));

      const result = await getDocs(q).then((snapshot) => {
        const invite = snapshot.docs[0];
        return {
          data: invite.data(),
        };
      });
      return result.data;
    },
    {
      enabled: !!ref,
    },
  );
}

export function useGetInvitedCollaborators(appId: string) {
  return useQuery(
    ["invitedCollaborators", appId],
    async () => {
      const q = query(
        collection(db, "invites"),
        where("template.data.appId", "==", appId),
      );

      const result = await getDocs(q).then((snapshot) => {
        return snapshot.docs.map((doc) => {
          return {
            data: doc.data(),
          };
        });
      });
      return result;
    },
    {
      enabled: !!appId,
    },
  );
}

export function useAddCollaborator(
  appId: string,
  userId: string | undefined,
  accessType: AccessType,
) {
  const queryClient = useQueryClient();
  const userKey = "collaborators.users_" + userId;
  return useMutation(
    async () => {
      const appRef = doc(db, "apps", appId);

      await updateDoc(appRef, {
        [userKey]: accessType,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", appId]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

/// get the App's BaseURL configuration
export async function getAppConfig(
  appId: string,
): Promise<AppConfigData | null> {
  const snapshot = await getDocs(
    query(
      collection(doc(db, "apps", appId), "artifacts"),
      where("isRoot", "==", true),
      where("type", "==", "config"),
    ).withConverter(AppConfigData.converter),
  );
  if (!snapshot.empty) {
    return snapshot.docs[0].data() as AppConfigData;
  }
  return null;
}

export async function updateAppConfig(
  appId: string,
  baseUrl: string,
  useBrowserUrl?: boolean,
) {
  // add or overwrite existing config
  await setDoc(
    doc(db, "apps", appId, "artifacts", CONFIG_ID),
    {
      isRoot: true,
      isArchived: false,
      type: "config",
      baseUrl: baseUrl,
      useBrowserUrl: useBrowserUrl ?? null, // firebase only understand null, not undefined
    },
    {
      merge: true,
    },
  );
}

export async function addEnvironmentVariable(
  appId: string,
  envKey: string,
  envValue: unknown,
) {
  await setDoc(
    doc(db, "apps", appId, "artifacts", CONFIG_ID),
    {
      isRoot: true,
      isArchived: false,
      type: "config",
      envVariables: {
        [envKey]: envValue, // set key using the variable
      },
    },
    {
      merge: true,
    },
  );
}

export async function deleteEnvironmentVariable(appId: string, envKey: string) {
  console.log("deleting " + envKey);
  await updateDoc(doc(db, "apps", appId, "artifacts", CONFIG_ID), {
    [`envVariables.${envKey}`]: deleteField(), // deleting nested field
  });
}

export async function getSecrets(appId: string): Promise<AppSecrets | null> {
  const snapshot = await getDocs(
    query(
      collection(doc(db, "apps", appId), "artifacts"),
      where("type", "==", "secrets"),
    ).withConverter(AppSecrets.converter),
  );
  if (!snapshot.empty) {
    return snapshot.docs[0].data() as AppSecrets;
  }
  return null;
}

export async function addSecret(appId: string, key: string, value: string) {
  await setDoc(
    doc(db, "apps", appId, "artifacts", SECRETS_ARTIFACT_NAME),
    {
      isRoot: true,
      isArchived: false,
      type: "secrets",
      secrets: {
        [key]: value, // set key using the variable
      },
    },
    {
      merge: true,
    },
  );
}

export async function removeSecret(appId: string, key: string) {
  await updateDoc(doc(db, "apps", appId, "artifacts", SECRETS_ARTIFACT_NAME), {
    [`secrets.${key}`]: deleteField(), // deleting nested field
  });
}
