import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { ExpressionInput } from "../components/ExpressionInput";
import { Widgets } from "@rjsf/bootstrap-4";
import { CustomJSONSchema7 } from "../utils/propertyPanelSchemaUtils";

export const TextWidget: React.FC<WidgetProps> = (props) => {
  const { id, value, onChange, onBlur, options, schema } = props;

  const path = props.id.split("_").slice(1);
  const isCodeBody =
    path.includes("executeCode") && path[path.length - 1] === "body";
  if (isCodeBody) return <Widgets.TextareaWidget {...props} />;

  const handleChange = (newValue: string) => {
    onChange(newValue === "" ? options.emptyValue : newValue);
  };

  const handleBlur = () => {
    onBlur(id, value);
  };

  const handleEnterKey = () => {
    onBlur(id, value);
  };

  return (
    <ExpressionInput
      placeholder={(schema as CustomJSONSchema7).uiPlaceholder}
      value={typeof value === "object" ? "" : value || ""}
      onChange={handleChange}
      onEnterKey={handleEnterKey}
      onBlur={handleBlur}
    />
  );
};
