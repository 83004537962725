import React, { useState, ReactNode } from "react";
import CoreForm from "@rjsf/core";

interface FormRefContextProps {
  formRef: React.RefObject<CoreForm> | null;
  hasFormChanges: boolean;
  setHasFormChanges: (hasChanges: boolean) => void;
  validateForm: () => boolean;
}

// Store the recursive Form reference and states
export const FormRefContext = React.createContext<FormRefContextProps>({
  formRef: null,
  hasFormChanges: false,
  setHasFormChanges: () => {},
  validateForm: () => true,
});

export const FormRefProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const formRef = React.useRef<CoreForm>(null);
  const [hasFormChanges, setHasFormChanges] = useState(false);

  const validateForm = () => {
    return (
      !formRef ||
      !formRef.current ||
      !hasFormChanges ||
      formRef.current.validateForm()
    );
  };

  return (
    <FormRefContext.Provider
      value={{ formRef, hasFormChanges, setHasFormChanges, validateForm }}
    >
      {children}
    </FormRefContext.Provider>
  );
};
