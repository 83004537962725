import {
  useRemoveCollaborator,
  useUpdateCollaboratorRole,
} from "../hooks/useAPIs";
import { AccessType } from "../models/model";

type Props = {
  appId: string;
  userId: string;
  onCancel: () => void;
  type: string;
  selectedAccessType?: AccessType;
};

const ChangeAccessModal = ({
  appId,
  userId,
  onCancel,
  type,
  selectedAccessType,
}: Props) => {
  const updateCollaboratorRole = useUpdateCollaboratorRole(
    appId,
    userId,
    selectedAccessType ? selectedAccessType : AccessType.read
  );
  const removeCollaborator = useRemoveCollaborator(appId, userId);

  const handleUpdateChangeAccess = () => {
    if (type === "revoke") {
      removeCollaborator.mutate();
      onCancel();
      return;
    } else if (type === "change") {
      updateCollaboratorRole.mutate();
      onCancel();
      return;
    } else {
      onCancel();
      return;
    }
  };

  return (
    <div className="revoke-access-modal">
      <p>Are you sure you want to {type} access of this user?</p>
      <div>
        <button onClick={onCancel}>Cancel</button>
        <button className="button__primary" onClick={handleUpdateChangeAccess}>
          {type}
        </button>
      </div>
    </div>
  );
};

export default ChangeAccessModal;
