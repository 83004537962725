import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import ScreenCategoryModal from "./ScreenCategoryModal";

// Interfaces
import {
  EnsembleCategoriesData,
  EnsembleScreenData,
} from "../config/interfaces";

interface ScreenCategoryProps {
  screen: EnsembleScreenData;
  categories: EnsembleCategoriesData[] | undefined;
  displayCategoryModal: boolean;
  setCategoryTrigger: (value: boolean) => void;
}

const ScreenCategory: React.FC<ScreenCategoryProps> = ({
  screen,
  categories,
  displayCategoryModal,
  setCategoryTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setCategoryTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    toggleModal();
    setCategoryTrigger(false);
  };

  return (
    <Modal
      isModalDisplayed={displayCategoryModal}
      onHide={onCancel}
      headerText="Category"
      modalContent={
        <ScreenCategoryModal
          screen={screen}
          categories={categories}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default ScreenCategory;
