import React, { useState, useCallback } from "react";
import { FieldProps } from "@rjsf/utils";
import { RemixIcon } from "../../../components/Widgets";
import "./IntegerField.sass";
import { ErrorWrapper } from "../components/ErrorWrapper";

export const IntegerField: React.FC<FieldProps> = (props) => {
  const { schema, rawErrors } = props;
  const [value, setValue] = useState(props.formData);

  const min = schema.minimum || Number.MIN_SAFE_INTEGER;
  const max = schema.maximum || Number.MAX_SAFE_INTEGER;
  const getFallbackDefaultValue = () => {
    if (min > 0) {
      return min;
    }
    if (max < 0) {
      return max;
    }
    return min <= 0 && max >= 0 ? 0 : min;
  };

  // for slider
  const [dragging, setDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [startValue, setStartValue] = useState<number>(0);

  const enforceConstraints = useCallback(
    (newValue: number) => {
      if (newValue) {
        return Math.min(Math.max(newValue, min), max);
      }
    },
    [min, max],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    let newValue;
    if (inputValue === "") {
      newValue = undefined;
    } else {
      newValue = enforceConstraints(
        parseInt(event.target.value, 10) || getFallbackDefaultValue(),
      );
    }
    setValue(newValue);
    props.onChange(newValue);
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);
    setStartX(event.clientX);
    setStartValue(value || 0);
    event.preventDefault();
  };

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (dragging) {
        const diff = event.clientX - startX;
        const newValue = enforceConstraints(startValue + Math.round(diff / 2));
        setValue(newValue);
        props.onChange(newValue);
      }
    },
    [dragging, startX, startValue, enforceConstraints, props],
  );

  const onMouseUp = useCallback(() => {
    if (dragging) {
      setDragging(false);

      // submit the value
      props.onBlur(props.idSchema.$id, value);
    }
  }, [dragging, props, value]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onBlur(props.idSchema.$id, value);
      event.preventDefault();
    }
  };

  const onBlur = () => {
    props.onBlur(props.idSchema.$id, value);
  };

  // Attach global event listeners for mouse move and mouse up
  React.useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging, onMouseMove, onMouseUp]);

  return (
    <div className={"numeric-stepper"}>
      <ErrorWrapper errors={rawErrors}>
        <input
          type="number"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={handleKeyUp}
        />
      </ErrorWrapper>
      <div
        onMouseDown={onMouseDown}
        style={{
          cursor: "ew-resize",
        }}
      >
        <RemixIcon name="expand-width-line" />
      </div>
    </div>
  );
};
