import { FieldProps } from "@rjsf/utils";
import React, { useMemo, useState } from "react";
import "./WidgetBuilderField.sass";
import { WidgetIcon } from "../../../utils/widgetUtils";
import { WidgetBuilderModal } from "./WidgetBuilderModal";
import { FormRefProvider } from "../../../models/FormRefContext";
import { useSchemas } from "../../../hooks/useSchemas";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import { useYamlDoc } from "../../../hooks/useYamlDoc";
import { RootWidgetContext } from "./RootWidgetContext";
import { Scalar, YAMLMap } from "yaml";
import { WidgetItemPicker } from "../../../components/VisualEditor/ItemPicker";

export const WidgetBuilderField: React.FC<FieldProps> = (props) => {
  const { formData, formContext, idSchema } = props;
  const { findWidgetSchema } = useSchemas();
  const { dispatchVisualEditorChanges } = useYamlDoc();
  const [isOpened, setIsOpened] = useState(false);

  // preview for the Widget anchor. If returns undefined, it can be safe to assume that there is no Widget selected
  const anchorPreview = useMemo(() => {
    if (
      formData &&
      typeof formData === "object" &&
      Object.keys(formData).length > 0
    ) {
      const widgetName = Object.keys(formData)[0];
      if (findWidgetSchema(widgetName)) {
        return (
          <div className={"widget-picker-trigger-label"}>
            <WidgetIcon iconName={widgetName} />
            {camelCaseToWords(widgetName)}
          </div>
        );
      }
    }
  }, [findWidgetSchema, formData]);

  const handleWidgetSelect = (widgetName: string) => {
    const nodeContext = new RootWidgetContext(formContext, idSchema.$id);
    const leafPair = nodeContext.getOrCreateLeafNodePair();
    leafPair.value?.set(new Scalar(widgetName), new YAMLMap());
    dispatchVisualEditorChanges();
    setIsOpened(true);
  };

  return (
    <div className={"widget-builder-field"}>
      {anchorPreview ? (
        <div
          className={"widget-picker-trigger"}
          onClick={() => setIsOpened(true)}
        >
          {anchorPreview}
        </div>
      ) : (
        <WidgetItemPicker onItemSelect={handleWidgetSelect} />
      )}
      <FormRefProvider>
        <WidgetBuilderModal
          {...props}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </FormRefProvider>
    </div>
  );
};
