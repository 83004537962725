import React, { useEffect, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import { Widgets } from "@rjsf/bootstrap-4";

export const DropdownWidget: React.FC<WidgetProps> = (props) => {
  const { formContext, onChange } = props;
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const handleChange = (data: unknown) => {
    onChange(data);
    setHasPendingChanges(true);
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Widgets.SelectWidget {...props} onChange={handleChange} />;
};
