import React from "react";
import CustomIcon from "../assets/widget/custom.svg";

// Return a icon that maps to the widget name
export const WidgetIcon: React.FC<{ iconName: string }> = ({ iconName }) => {
  const sanitizeString = (string: string) => {
    return string
      .replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") // eslint-disable-line
      .trim();
  };

  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const source = require(`../assets/widget/${sanitizeString(
      iconName,
    ).toLowerCase()}.svg`);
    return <img alt={"icon"} src={source} width={16} height={16} />;
  } catch (e) {
    return <img alt={"icon"} src={CustomIcon} width={16} height={16} />;
  }
};
