import React from "react";
import { useModal } from "../hooks/useModal";
import Modal from "./Modal";
import { getLanguageCode, getTranslationLanguageName } from "../utils/Util";
import { LanguageActionProps } from "./LanguageActionMenu";
import { LanguageSetDefaultModal } from "./LanguageSetDefaultModal";

export const LanguageSetDefault: React.FC<LanguageActionProps> = ({
  language,
  actionTrigger,
  setActionTrigger,
}) => {
  const { toggleModal } = useModal();
  const languageCode = getLanguageCode(language);

  const onCancel = () => {
    setActionTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setActionTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={actionTrigger}
      onHide={onCancel}
      headerText={`Set '${getTranslationLanguageName(
        languageCode,
      )}' as the default language?`}
      modalContent={
        <LanguageSetDefaultModal
          language={language}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};
