import { NodeRef } from "./TreeNode";
import React, { useLayoutEffect, useState } from "react";
import { isNil } from "ramda";
import { TreePanel } from "./TreePanel";
import { ArtifactType } from "../../pages/EditorPage";
import { RemixIcon } from "../../components/Widgets";

export type NavigatorPanelProps = {
  onNodeSelected: (node: NodeRef | null) => Promise<boolean>;
  artifactType: ArtifactType;
};

// const actionTabsList = [{ id: "tab-1", label: "Navigator" }];

export const NavigatorPanel: React.FC<NavigatorPanelProps> = ({
  onNodeSelected,
  artifactType,
}) => {
  // const [selectedTab, setSelectedTab] = useState<string>("tab-1");
  const [treeClosed, toggleTreeView] = useState(true);

  useLayoutEffect(() => {
    const treeState = localStorage.getItem("tree-state");
    if (treeState) {
      const treeOpenState = JSON.parse(treeState);
      if (!isNil(treeOpenState)) toggleTreeView(!!treeOpenState);
    }
  }, []);

  const hideTreeView = () => {
    toggleTreeView((prevState) => !prevState);
    localStorage.setItem("tree-state", JSON.stringify(!treeClosed));
  };

  if (treeClosed) {
    return (
      <div className="action-tab-closed">
        <div>
          <button onClick={hideTreeView} className={"widget-toggle-btn"}>
            <div className={"tooltip-container navigator-panel-control"}>
              <RemixIcon name={"expand-right-line"} />
              <span className={"tooltip"}>Expand</span>
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="navigator-panel">
      <div className="action-tab-container">
        {/*<div className="action-tab-section">*/}
        {/*  {actionTabsList.map((act) => {*/}
        {/*    const labelStyle = act.id === selectedTab ? "active" : "inactive";*/}
        {/*    return (*/}
        {/*      <button*/}
        {/*        key={act.id}*/}
        {/*        className={`tab-label ${labelStyle}`}*/}
        {/*        onClick={() => setSelectedTab(act.id)}*/}
        {/*      >*/}
        {/*        {act.label}*/}
        {/*      </button>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
        <div>
          <button onClick={hideTreeView} className={"widget-toggle-btn"}>
            <div className={"tooltip-container navigator-panel-control"}>
              <RemixIcon name={"expand-left-line"} />
              <span className={"tooltip"}>Collapse</span>
            </div>
          </button>
        </div>
      </div>
      <div className="navigator-panel-content">
        <TreePanel
          artifactType={artifactType}
          onNodeSelected={onNodeSelected}
        />
      </div>
    </div>
  );
};
