import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "../pages/AppPagesWrapper";

// Components
import ScriptEditor from "./ScriptEditor";

// APIs
import { AuthContext } from "../App";
import {
  getScreenDetailsWithSnapShot,
  useUpdateWidget,
} from "../hooks/useFirebase";
import { EnsembleScreenData } from "../config/interfaces";

const AppScript: React.FunctionComponent = () => {
  const params = useParams();
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);

  const [screenDetails, setScreenDetails] = useState<EnsembleScreenData>({
    id: "",
    name: "",
    isArchived: true,
    isRoot: false,
  });

  const scriptId = params.script_id;

  const updateScriptQuery = useUpdateWidget(
    currentUser,
    app.id,
    scriptId ?? "",
  );

  useEffect(() => {
    getScreenDetailsWithSnapShot(
      app.id,
      scriptId,
      "internal_artifacts",
      (res: EnsembleScreenData) => {
        setScreenDetails(res);
        document.title = app.name + " | " + res?.name;
      },
    );
  }, [app.id, app.name, scriptId]);

  const onSave = useCallback(
    (content: string) => {
      updateScriptQuery.mutate({ content });
    },
    [updateScriptQuery],
  );

  useEffect(() => {
    document.title = app.name + " script";
  });

  return (
    <div className="script-screen">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/scripts`}>{`Scripts`}</Link>
      </div>

      {!screenDetails?.id && <p>loading...</p>}

      <div className="page-header">
        <h1>{screenDetails.name}</h1>
      </div>

      {screenDetails?.id && (
        <ScriptEditor
          disableButton={updateScriptQuery.isLoading}
          onSave={onSave}
          screen={screenDetails}
        />
      )}
    </div>
  );
};
export default AppScript;
