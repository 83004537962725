import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "ramda";
import { useAppContext } from "./AppPagesWrapper";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import DemoAppBadge from "../components/DemoAppBadge";
import Modal from "../components/Modal";
import ScriptCreateModal from "../components/modals/Scripts/ScriptCreateModal";
import ScriptCard from "../components/cards/ScriptCard";

const ScriptScreens: React.FunctionComponent = () => {
  const { app, isAppReadOnly, isDemoApp } = useAppContext();

  const { isModalDisplayed, toggleModal } = useModal();

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  return (
    <div className="screen-content script-screen" key={app.id}>
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>

        {app.isPublic && !isDemoApp && (
          <span>
            {" "}
            / <span className="public-app-badge">Public</span>
          </span>
        )}

        {isDemoApp && (
          <span>
            {" "}
            / <DemoAppBadge />
          </span>
        )}
      </div>

      <div className="page-header">
        <div>
          <h1>Scripts</h1>
          <p className="title">
            Scripts are reusable code written in JavaScript. Define them once
            and reuse by importing where needed.
            <a
              className="doc"
              onClick={() =>
                window.open(
                  "https://docs.ensembleui.com/#/javascript-reference/README",
                  "_blank",
                )
              }
            >
              <span>Visit Docs</span>
            </a>
          </p>
        </div>
        <div className="page-actions">
          {!isAppReadOnly && (
            <button className="button__primary" onClick={toggleModal}>
              Create new script
            </button>
          )}
        </div>
      </div>

      {/* scripts cards */}
      <div className="screen-cards-st">
        {/* script */}
        {isEmpty(app.internalScripts) ? (
          <span>This app does not have custom scripts.</span>
        ) : (
          <>
            {app.internalScripts?.map((script) => (
              <ScriptCard
                key={script.id}
                script={script}
                app_id={app.id}
                isReadOnly={isAppReadOnly}
              />
            ))}
          </>
        )}
      </div>

      {app && !isAppReadOnly && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Create script"
          modalContent={<ScriptCreateModal />}
        />
      )}
    </div>
  );
};

export default ScriptScreens;
