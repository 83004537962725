import {
  canExpand,
  ObjectFieldTemplatePropertyType,
  ObjectFieldTemplateProps,
} from "@rjsf/utils";
import React, { useState } from "react";
import "./ObjectFieldTemplate.sass";
import { RemixIcon } from "../../../components/Widgets";
import { Tooltip } from "antd";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import { CardFieldTemplate } from "./CardFieldTemplate";
import {
  CustomJSONSchema7,
  GroupDisplay,
} from "../utils/propertyPanelSchemaUtils";
import { KeyValuePairsFieldTemplate } from "./KeyValuePairsFieldTemplate";
import { Icon } from "@iconify/react";
import { ExternalDoc } from "../../../components/ExternalDoc";

export interface CompositeObjectFieldTemplateProps {
  props: ObjectFieldTemplateProps;
  groupName?: string;
}

// Custom ObjectFieldTemplate that can render an Object differently:
// 1. Render as Card
// 2. Render additionalProperties={} as key/value pair
// 3. Render as collapsible group
export const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = (
  props,
) => {
  const { schema, uiSchema, idSchema, properties, formData } = props;

  const groupCollapsible =
    (schema as CustomJSONSchema7)?.groupCollapsible ?? true;
  // expanded by default for the root node, collapsed otherwise
  const isRoot = idSchema?.$id === "root" ?? false;
  const [isExpanded, setIsExpanded] = useState(isRoot || !groupCollapsible);
  const toggleExpand = () => {
    if (groupCollapsible) {
      setIsExpanded((prevExpanded) => !prevExpanded);
    }
  };

  // Not so great: group name is not available inside the object, so we have to extract it from the id
  // Alternatively we can use FieldTemplate to render the group name (but that's not great either)
  const groupName = idSchema?.$id.split("_").pop();

  // render the group differently if groupDisplay is specified
  const groupDisplay = (schema as CustomJSONSchema7)?.groupDisplay;
  if (groupDisplay === GroupDisplay.card) {
    return <CardFieldTemplate props={props} groupName={groupName} />;
  } else if (groupDisplay === GroupDisplay.flatten) {
    return (
      <div>
        {properties.map(
          (prop: ObjectFieldTemplatePropertyType) => prop.content,
        )}
      </div>
    );
  }

  // if have additionalProperties = {}, we render as key value pair
  if (canExpand(schema, uiSchema, formData)) {
    return <KeyValuePairsFieldTemplate props={props} groupName={groupName} />;
  }

  // render a generic object field template
  return (
    <div
      className={`object-field-template ${
        isRoot ? "object-field-template-root" : ""
      }`}
    >
      <div
        className={`object-field-template-header ${
          groupCollapsible ? "object-field-template-header-actionable" : ""
        } ${!isExpanded ? "object-field-template-header-collapsed" : ""}`}
        onClick={toggleExpand}
      >
        <div className={"object-field-template-header-title"}>
          {groupName ? camelCaseToWords(groupName) : ""}
          {(schema.description || (schema as CustomJSONSchema7).docTopic) && (
            <Tooltip
              overlayClassName={"property-panel-tooltip"}
              title={
                <div>
                  {schema.description && <div>{schema.description}</div>}
                  {(schema as CustomJSONSchema7).docTopic && (
                    <div>
                      <ExternalDoc
                        topicName={(schema as CustomJSONSchema7).docTopic!}
                      />
                    </div>
                  )}
                </div>
              }
            >
              <Icon
                className={"object-field-template-header-title-help-icon"}
                icon={"ic:sharp-help-outline"}
              />
              {/*<span>*/}
              {/*  <RemixIcon name={"information-line"} />*/}
              {/*</span>*/}
            </Tooltip>
          )}
        </div>

        {groupCollapsible && (
          <RemixIcon
            name={isExpanded ? "arrow-down-s-line" : "arrow-right-s-line"}
          />
        )}
      </div>
      <div style={{ display: isExpanded ? "block" : "none" }}>
        {properties.map((element) => element.content)}
      </div>
    </div>
  );
};
