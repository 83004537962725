const AppendScript = (callback?: () => void) => {
  const scriptLoaded = document.getElementById("HJ_SCRIPT");
  if (!scriptLoaded) {
    const script = document.createElement("script");
    //@ts-ignore
    script.src = import("./hotjar");
    script.id = "HJ_SCRIPT";
    script.type = "module";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
};
export default AppendScript;
