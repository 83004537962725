import { RefObject, useEffect, useRef, useState } from "react";
import useKeyPress from "./useKeyPress";

const useKeyboardNavigation = (
  elementsLength: number,
): [number, RefObject<HTMLElement>] => {
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const rootRef = useRef<HTMLElement>(null);

  const downArrowKey = useKeyPress("ArrowDown");
  const upArrowKey = useKeyPress("ArrowUp");
  const enterKey = useKeyPress("Enter");

  useEffect(() => {
    if (downArrowKey) {
      setFocusedIndex((index) => (index < elementsLength - 1 ? index + 1 : 0));
    }
  }, [elementsLength, rootRef, downArrowKey]);

  useEffect(() => {
    if (upArrowKey) {
      setFocusedIndex((index) => (index > 0 ? index - 1 : elementsLength - 1));
    }
  }, [elementsLength, rootRef, upArrowKey]);

  useEffect(() => {
    if (enterKey) {
      rootRef.current?.click();
    }
  }, [enterKey, rootRef]);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.focus();
    }
  }, [rootRef, focusedIndex]);

  return [focusedIndex, rootRef];
};

export default useKeyboardNavigation;
