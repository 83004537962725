import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { AuthContext } from "../App";
import { useQueryClient } from "react-query";
import { updateSession } from "../hooks/useFirebase";
import { useCancelSubscription } from "../hooks/useAPIs";
import SubscriptionPlansModal from "../components/modals/SubscriptionPlans/SubscriptionPlansModal";
import { toastOptions } from "../config/constants";

const Account: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const cancelSubscriptionQuery = useCancelSubscription(
    currentUser?.subscriptionPlan?.id,
  );
  const [subscriptionPlanTrigger, setSubscriptionPlanTrigger] =
    useState<boolean>(false);

  const sessionId = localStorage.getItem("sessionId");
  const currentPlanName = currentUser?.subscriptionPlan?.planDetails?.name;

  const logout = async () => {
    try {
      if (sessionId) {
        localStorage.removeItem("sessionId");
        await updateSession(currentUser?.id ? currentUser.id : "", sessionId);
      }
      await signOut(auth);
      setCurrentUser(null);
      await queryClient.removeQueries();
      navigate("/sign-in");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cancelSubscriptionQuery.isSuccess) {
      toast.success(cancelSubscriptionQuery.data.message, toastOptions);
      setCurrentUser({
        ...currentUser,
        subscriptionPlan: cancelSubscriptionQuery.data.subscriptionPlan,
      });
    }
    if (cancelSubscriptionQuery.isError)
      toast.error("Error while canceling subscription.", toastOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSubscriptionQuery.isSuccess, cancelSubscriptionQuery.isError]);

  return (
    <div className="page-ct">
      <div className="account-content">
        <div>
          <h1>Account</h1>
          {currentUser?.id ? (
            <>
              <p>
                You are logged in as {currentUser?.name} ({currentUser?.email})
              </p>
              <p className="small">User id: {currentUser?.id}</p>
              <button className="button__primary" onClick={logout}>
                Log out
              </button>
            </>
          ) : null}
        </div>
        {currentUser?.subscriptionPlan && (
          <div className="plan-section">
            <h1>My Plan</h1>
            <span>{currentPlanName} Plan</span>
            <br />
            {/* <button
              className="plan-button"
              onClick={() => setSubscriptionPlanTrigger(true)}
            >
              {currentPlanName === "Community"
                ? "View plans & upgrade"
                : "Change Plan"}
            </button>{" "} */}
            &nbsp;
            {currentPlanName !== "Community" && (
              <button
                className="cancel-button"
                onClick={() => cancelSubscriptionQuery.mutate()}
              >
                {cancelSubscriptionQuery.isLoading
                  ? "Canceling..."
                  : "Cancel Subscription"}
              </button>
            )}
          </div>
        )}
        <SubscriptionPlansModal
          displayModal={subscriptionPlanTrigger}
          handleSubscriptionPlanTrigger={() =>
            setSubscriptionPlanTrigger(false)
          }
        />
      </div>
    </div>
  );
};

export default Account;
