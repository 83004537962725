import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

// Components
import Nav from '../components/Nav';


const E404: React.FunctionComponent = props => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className='page-ct'>
      <div className='account-content'>
        <h1>404!</h1>
        <p>The resource you tried to access does not exist, or you may not have access to it.</p>

        { currentUser && <button className='button__primary' onClick={() => navigate('/')}>View your apps</button> }
        { !currentUser && <button className='button__primary' onClick={() => navigate('/signin')}>Sign in</button> }

      </div>
    </div>
  );
}

export default E404;
