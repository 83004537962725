import React from "react";
import "../../../styles/components/subscription-plans.sass";
// Components
import Modal from "../../Modal";
import SubscriptionCard from "../../cards/SubscriptionCard";
// APIs
import { useGetSubscriptionPlans } from "../../../hooks/useAPIs";
import { ISubscriptionData } from "../../../config/interfaces";

interface SubscriptionPlansModalProps {
  displayModal: boolean;
  handleSubscriptionPlanTrigger: () => void;
}

const SubscriptionPlansModal: React.FC<SubscriptionPlansModalProps> = ({
  displayModal,
  handleSubscriptionPlanTrigger,
}) => {
  const subscriptionPlansData = useGetSubscriptionPlans();

  const onCancel = () => {
    handleSubscriptionPlanTrigger();
  };

  const subscriptionPlansModalContent = () => (
    <div>
      <h6>All plans get a 30 day free trial period</h6>
      <div className="subscription-plans">
        {subscriptionPlansData.data?.map(
          (plan: ISubscriptionData, index: number) => (
            <SubscriptionCard key={index} subscriptionDetails={plan} />
          )
        )}
      </div>
    </div>
  );

  return (
    <Modal
      isModalDisplayed={displayModal}
      onHide={onCancel}
      headerText="Choose a plan"
      modalContent={subscriptionPlansModalContent()}
    />
  );
};

export default SubscriptionPlansModal;
