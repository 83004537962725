import { ReactComponent as ensembleColumnMax } from "../../../assets/visualEditor/ensemble-column-max.svg";
import { ReactComponent as ensembleColumnMin } from "../../../assets/visualEditor/ensemble-column-min.svg";
import { ReactComponent as ensembleRowMax } from "../../../assets/visualEditor/ensemble-row-max.svg";
import { ReactComponent as ensembleRowMin } from "../../../assets/visualEditor/ensemble-row-min.svg";
import { RemixIcon } from "../../../components/Widgets";
import React from "react";

const visualEditorIconMap: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  "ensemble-column-max": ensembleColumnMax,
  "ensemble-column-min": ensembleColumnMin,
  "ensemble-row-max": ensembleRowMax,
  "ensemble-row-min": ensembleRowMin,
};

export const getVisualEditorIcon = (icon: string) => {
  if (icon.startsWith("ensemble")) {
    const SvgIcon = visualEditorIconMap[icon];
    if (SvgIcon) return <SvgIcon />;
  }
  return <RemixIcon name={icon} />;
};

// an option representing an enum or a oneOf (with const/description)
export interface OptionItem {
  value: string;
  description: string | undefined;
}

// return the tooltip when hovering over an Option
export const getOptionTooltip = (option: OptionItem) => {
  return (
    <div>
      {option.description ?? (
        <>
          <span>{option.description}</span>
          <br />
        </>
      )}
      <div className="property-panel-tooltip-emphasized-token">
        {option.value}
      </div>
    </div>
  );
};
