import React, { useState, createRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../App";
// APIs
import {
  useGetUserDetails,
  useCreateApp,
  verifyAppInvite, generateApp, addEnvironmentVariable
} from "../hooks/useAPIs";
import {useMutation} from "react-query";

const starterYaml = `View:
  title: Home
  Column:
    styles: { mainAxis: center }
    children:
      - Row:
          styles: { mainAxis: center }
          children:
            - Text:
                text: Hello world!
  `;

interface AppGeneratingModalProps {
  onCancel: () => void;
}



const AppGeneratingModal: React.FC<AppGeneratingModalProps> = () => {
  const navigate = useNavigate();
  const [appPrompt, setAppPrompt] = useState("");
  const [appOutput, setAppOutput] = useState("");
  const [showResult, setShowResult] = useState(false);

  // navigate to Home screen where generating apps are being displayed
  const navigateHome = () => {

    console.log("navigating home");
    navigate(`/?key=${Date.now()}`);
  };

  const appNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setAppPrompt(event.currentTarget.value);
  };

  const useGeneratingApp = useMutation(
    async () => {
      console.log(`Prompt: ${appPrompt}`);
      const response = await generateApp(appPrompt);
      if (response.data) {
        // @ts-ignore
        setAppOutput(`Your App "${response.data.appName}" (${response.data.appId}) is being created.`)
        setShowResult(true);


      }
    }
  );

  const handleGeneratingApp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    useGeneratingApp.mutate();
  };

  if (showResult) {
    return <div>
        <div>{appOutput}</div>
        {/*<button type="submit" className="button__primary" onClick={navigateHome}>Go Home</button>*/}
      </div>
  } else {
    return <form className="app-create-modal" onSubmit={handleGeneratingApp}>
      <label>Describe your App</label>
      <input
        type="text"
        value={appPrompt}
        placeholder="an app to rent your pet"
        onChange={appNameChange}
        autoFocus
      />

      <button
        type="submit"
        className="button__primary"
        disabled={!appPrompt || useGeneratingApp.isLoading}
      >
        Go
      </button>
    </form>
  }


};

export default AppGeneratingModal;
