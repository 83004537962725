import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { useQueryClient } from "react-query";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";

// Styles
import "../styles/app-assets.sass";

// Components
import { Upload } from "../components/Upload/Upload";
import AssetsCard from "../components/AppAssets/AssetsCard";
import AssetsActionButtons from "../components/AppAssets/AssetsActionButtons";

import { useAppContext } from "./AppPagesWrapper";
import { uploadAsset } from "../hooks/useAPIs";

// Assets
import assets_empty_state from "../assets/assets_empty_state.png";
import Modal from "../components/Modal";

const AppAssets: React.FunctionComponent = () => {
  const { app, isAppReadOnly } = useAppContext();
  const queryClient = useQueryClient();
  const [uploadTrigger, setUploadTrigger] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    document.title = app.name + " assets";
  });

  const handleAssetsUpload = () => {
    setUploadTrigger(true);
  };

  const onCloseTrigger = () => {
    setUploadTrigger(false);
    setIsUploading(false);
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () =>
        resolve(reader.result?.toString().split(",")[1] as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onUploadAssets = async (item: File[]) => {
    if (item.length === 0) return;
    setIsUploading(true);
    try {
      const base64 = await convertToBase64(item[0]);
      await uploadAsset(app.id, item[0].name, base64);
      toast.success(`Asset uploaded successfully.`, {
        position: "top-right",
        type: toast.TYPE.SUCCESS,
        theme: "dark",
      });
      queryClient.invalidateQueries(["app", app.id]);
    } catch (error) {
      toast.error(`Failed to upload asset.`, {
        position: "top-right",
        type: toast.TYPE.ERROR,
        theme: "dark",
      });
    } finally {
      onCloseTrigger();
    }
  };

  return (
    <div className="screen-content">
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <h1>Assets</h1>
        <div className="page-actions">
          {!isAppReadOnly && (
            <div className="assets-actions assets-actions--dense">
              <AssetsActionButtons handleAssetsUpload={handleAssetsUpload} />
            </div>
          )}
        </div>
      </div>

      {app.assets && app.assets?.length > 0 ? (
        <div className="screen-cards-ct">
          {app.assets.map((asset) => (
            <AssetsCard asset={asset} key={asset.id} />
          ))}
        </div>
      ) : (
        <div className="empty-content">
          <div className="empty-assets">
            <img src={assets_empty_state} alt="empty assets" />
            <h2 className="empty-assets__text">
              Add images, videos, fonts, and other assets to use in your app.
            </h2>
            <div className="assets-actions assets-actions--normal">
              <AssetsActionButtons handleAssetsUpload={handleAssetsUpload} />
            </div>
          </div>
        </div>
      )}

      <DndProvider backend={HTML5Backend}>
        <Modal
          isModalDisplayed={uploadTrigger}
          onHide={onCloseTrigger}
          headerText="Add assets"
          modalContent={
            <Upload
              key={`upload-${app.id}`}
              onDrop={onUploadAssets}
              isUploading={isUploading}
            />
          }
        />
      </DndProvider>
    </div>
  );
};
export default AppAssets;
