import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import AppRenameModal from "./AppRenameModal";

// Interfaces
import { AppData } from "../models/model";

interface AppRenameProps {
  app: AppData;
  displayRenameModal: boolean;
  setRenameTrigger: (value: boolean) => void;
}

const AppRename: React.FC<AppRenameProps> = ({
  app,
  displayRenameModal,
  setRenameTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setRenameTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    toggleModal();
    setRenameTrigger(false);
  };

  return (
    <Modal
      isModalDisplayed={displayRenameModal}
      onHide={onCancel}
      headerText="Edit app name and description"
      modalContent={
        <AppRenameModal app={app} onCancel={onCancel} onSubmit={onSubmit} />
      }
    />
  );
};

export default AppRename;
