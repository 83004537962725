import { NodeAction, useYamlDoc } from "../../hooks/useYamlDoc";
import { TreeView } from "./TreeView";
import React, { useEffect, useState } from "react";
import { NodeRef, TreeItemPayload } from "./TreeNode";
import { ArtifactType } from "../../pages/EditorPage";
import { WidgetPicker } from "../../components/VisualEditor/WidgetPicker";
import { isMap, Scalar, YAMLMap } from "yaml";
import { RemixIcon } from "../../components/Widgets";
import { ActionMenu } from "../../components/VisualEditor/ActionMenu";
import {
  generateAPIName,
  generateWidgetName,
  getKeyValue,
} from "../../utils/schemaUtils";
import { ScreenTypePicker } from "../../components/VisualEditor/ScreenTypePicker";
import { useEditor } from "../../hooks/useEditor";
import { useCustomWidgets } from "../../hooks/useCustomWidgets";

export type TreePanelProps = {
  onNodeSelected: (node: NodeRef | null) => Promise<boolean>;
  artifactType: ArtifactType;
};

export const TreePanel: React.FC<TreePanelProps> = ({
  onNodeSelected,
  artifactType,
}) => {
  const {
    docTreeStore,
    doc,
    addNode,
    updateNode,
    addNodeByAction,
    insertRootNode,
    onAppCustomWidgetsChanged,
  } = useYamlDoc();
  const { setIsSourceView } = useEditor();
  const labelName = artifactType === ArtifactType.screen ? "SCREEN" : "WIDGET";

  const { appCustomWidgets } = useCustomWidgets();
  useEffect(() => {
    onAppCustomWidgetsChanged(appCustomWidgets);
  }, [appCustomWidgets, onAppCustomWidgetsChanged]);

  // the key for the selected node across all the trees
  const { selectedNode } = useYamlDoc();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const onSelect = (key: string | null, ref: NodeRef | null) => {
    // dispatch the selected node to the parent
    onNodeSelected(ref).then((success) => {
      if (success) {
        // tell all the trees to select or deselect its node
        setSelectedKey(key);
      }
    });
  };

  useEffect(() => {
    if (!selectedNode) {
      setSelectedKey(null);
    }
  }, [selectedNode]);

  // user picked a widget to be added to the tree
  const onWidgetSelectedAction = (
    parentNode: YAMLMap,
    widgetKey: string,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) => {
    const widgetRecord: Record<string, unknown> = {};
    widgetRecord[widgetKey] = {};
    const widgetNode = doc!.createNode(widgetRecord);
    addNodeByAction(parentNode, widgetNode, action, payload);

    // TODO: select the new node
  };

  const onRootAction = (action: string) => {
    if (action === "View" || action === "ViewGroup") {
      insertRootNode(action);
    }
  };

  const onScreenAction = (action: string) => {
    if (action === "addWidget") {
      addNewWidget();
    } else if (action === "addAPI") {
      addNewAPI();
    } else if (action === "addScript") {
      addScript();
    }
  };

  // add a new custom widget to the tree
  const addNewWidget = () => {
    if (!doc?.contents) {
      return;
    }
    const newWidgetName = generateWidgetName(doc.contents as YAMLMap);
    const pair = doc.createPair(newWidgetName, {});
    if (pair) {
      addNode(doc.contents, pair);
      // setSelectedNode(pair, [doc]);
      // setIsPropertyPanelOpen(true);
    }
  };

  const addNewAPI = () => {
    if (!doc?.contents || !isMap(doc.contents)) {
      return;
    }
    const newAPIName = generateAPIName(doc.contents);

    const apiNode = doc.get("API");
    if (isMap(apiNode)) {
      const pair = doc.createPair(newAPIName, {});
      apiNode.items.push(pair);
      updateNode(apiNode);
    } else {
      const contents = doc.contents;
      if (isMap(contents)) {
        contents.items = contents.items.filter(
          (item) => getKeyValue(item) !== "API",
        );
      }
      const pair = doc.createPair<Scalar, YAMLMap>("API", { [newAPIName]: {} });
      addNode(doc.contents, pair);
    }
    // TODO: select the new node
  };

  const addScript = () => {
    if (!doc?.contents) {
      return;
    }
    const hasScript = doc.has("Global");
    if (hasScript) {
      return;
    }
    // don't change this line (hard to generate the correct syntax)
    const globalBlock = new Scalar(`// JavaScript code
`);
    const pair = doc.createPair<Scalar, Scalar>("Global", globalBlock);
    addNode(doc.contents, pair);
  };

  if (docTreeStore === undefined) {
    return <div></div>;
  }

  // Empty definition. need to select View//ViewGroup
  if (artifactType === ArtifactType.screen && !docTreeStore?.root) {
    // TODO: show ViewGroup in the tree
    if (docTreeStore?.viewGroup) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <span
            style={{ textAlign: "center", width: "200px", lineHeight: 1.5 }}
          >
            Use the{" "}
            <span
              style={{
                fontStyle: "italic",
                color: "#2BAADF",
                cursor: "pointer",
              }}
              onClick={() => setIsSourceView(true)}
            >
              Code Editor
            </span>{" "}
            to edit Screen Group
          </span>
        </div>
      );
    }
    return (
      <ScreenTypePicker
        onSelect={(screenType: string) => {
          onRootAction(screenType);
        }}
      />
    );
  }

  return (
    <div className={"tree-panel"}>
      <div className={"tree-block"}>
        <div className={"tree-block-header-with-separator"}>
          {labelName}
          {/* Screen's ACTIONS */}
          {artifactType === ArtifactType.screen && (
            <div className={"screen-action-menu-anchor"}>
              <ActionMenu
                anchor={
                  <button>
                    <span>Actions</span>
                    <RemixIcon name={"arrow-down-s-line"} />
                  </button>
                }
                items={[
                  {
                    iconName: "add-circle-line",
                    label: "Add Widget",
                    value: "addWidget",
                  },
                  {
                    iconName: "database-2-line",
                    label: "Add API",
                    value: "addAPI",
                  },
                  {
                    iconName: "braces-line",
                    label: "Add Script",
                    value: "addScript",
                  },
                ]}
                onSelect={onScreenAction}
              />
            </div>
          )}
        </div>
        <div className={"tree-block-body"}>
          <TreeView
            treeData={docTreeStore?.root ?? []}
            onSelect={onSelect}
            selectedKey={selectedKey}
          />
        </div>
      </div>

      <div className={"tree-block"}>
        <div className={"tree-block-header"}>{labelName} BODY</div>
        {docTreeStore?.body ? (
          <div className={"tree-block-body"}>
            <TreeView
              treeData={docTreeStore?.body}
              onSelect={onSelect}
              onWidgetSelectedAction={onWidgetSelectedAction}
              selectedKey={selectedKey}
              expandFirstNode={true}
            />
          </div>
        ) : (
          docTreeStore?.root &&
          docTreeStore.root.length > 0 && (
            <WidgetPicker
              trigger={
                <div className={"set-screen-or-widget-body"}>
                  Set Body Widget
                </div>
              }
              onWidgetSelect={(widgetKey) => {
                onWidgetSelectedAction(
                  docTreeStore!.root![0].ref.parent as YAMLMap,
                  widgetKey,
                  NodeAction.SetRootBody,
                );
              }}
            />
          )
        )}
      </div>

      {docTreeStore?.customWidgets && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>
            WIDGETS
            <div
              onClick={addNewWidget}
              className={"action-icon tooltip-container"}
            >
              <RemixIcon name={"add-line"} />
              <div className={"tooltip"}>Add a widget</div>
            </div>
          </div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={docTreeStore.customWidgets}
              onSelect={onSelect}
              onWidgetSelectedAction={onWidgetSelectedAction}
              selectedKey={selectedKey}
            />
          </div>
        </div>
      )}

      {docTreeStore?.api && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>
            API
            <div
              onClick={addNewAPI}
              className={"action-icon tooltip-container"}
            >
              <RemixIcon name={"add-line"} />
              <div className={"tooltip"}>Add an API</div>
            </div>
          </div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={docTreeStore?.api}
              onSelect={onSelect}
              selectedKey={selectedKey}
            />
          </div>
        </div>
      )}

      {docTreeStore?.codeBlock && artifactType === ArtifactType.screen && (
        <div className={"tree-block"}>
          <div className={"tree-block-header-with-separator"}>SCRIPT</div>
          <div className={"tree-block-body"}>
            <TreeView
              treeData={docTreeStore?.codeBlock}
              onSelect={onSelect}
              selectedKey={selectedKey}
            />
          </div>
        </div>
      )}
    </div>
  );
};
