import "remixicon/fonts/remixicon.css";
import React from "react";

interface RemixIconProps {
  name: string;
  [key: string]: unknown;
}
export const RemixIcon: React.FC<RemixIconProps> = ({ name, ...props }) => {
  return <i className={`ri-${name}`} {...props}></i>;
};
