import React from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import Modal from "./Modal";
import AppArchiveModal from "./AppArchiveModal";

// Interfaces
import { AppData } from "../models/model";

interface AppArchiveProps {
  app: AppData;
  displayArchiveModal: boolean;
  setArchiveTrigger: (value: boolean) => void;
}

const AppArchive: React.FC<AppArchiveProps> = ({
  app,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={(event: any) => onCancel(event)}
      headerText={`Archive ${app.name}?`}
      modalContent={
        <AppArchiveModal
          app={app}
          onCancel={(event: any) => onCancel(event)}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default AppArchive;
