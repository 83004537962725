import { FieldProps } from "@rjsf/utils";
import { Modal, Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import React, { useState } from "react";
import { ActionTree } from "../../NavigatorPanel/ActionTree";
import { ActionPropertyPanel } from "../../NavigatorPanel/ActionPropertyPanel";
import "./ActionBuilderModal.sass";
import { EventNode } from "../../NavigatorPanel/ActionTreeBuilder";
import { useYamlDoc } from "../../../hooks/useYamlDoc";
import { Scalar } from "yaml";
import { getKeyValue } from "../../../utils/schemaUtils";

export type ActionBuilderModalProps = FieldProps & {
  isOpened: boolean;
  setIsOpened: (isOpened: React.SetStateAction<boolean>) => void;
};

export const ActionBuilderModal: React.FC<ActionBuilderModalProps> = (
  props,
) => {
  const { formContext } = props;
  const { isOpened, setIsOpened } = props;
  const { dispatchVisualEditorChanges } = useYamlDoc();
  const [eventNode, setEventNode] = useState<EventNode | undefined>(undefined);

  // used to force the Tree to re-render
  const [treeDataChanges, setTreeDataChanges] = useState<number>(0);

  // callback when the PropertyPanel updates its eventPair
  const handleItemUpdate = (eventNode: EventNode) => {
    setTreeDataChanges((prev) => prev + 1);
    setEventNode(eventNode);
  };

  const handleItemRemove = (eventNode: EventNode) => {
    if (eventNode.parent) {
      eventNode.parent.delete(eventNode.eventPair.key);
      setEventNode(undefined);
    } else {
      // this is the root Event, just remove the root Action
      eventNode.eventPair.value = new Scalar(null);
    }
    dispatchVisualEditorChanges();
    setTreeDataChanges((prev) => prev + 1);
  };

  // callback when the Tree selects an item
  const handleItemSelect = (eventNode: EventNode) => {
    setEventNode(eventNode);
  };

  return (
    <Modal
      className={"modal-container right-modal-container"}
      title={getKeyValue(formContext.node)}
      open={isOpened}
      keyboard={false}
      onCancel={(e) => {
        e.stopPropagation();
        setIsOpened(false);
      }}
      closeIcon={
        <Tooltip title={"Go Back"} placement={"left"}>
          <span>
            <RemixIcon
              name={"close-line"}
              style={{ cursor: "pointer", fontSize: "1.5em" }}
            />
          </span>
        </Tooltip>
      }
      maskClosable={false}
      footer={null}
    >
      <div className={"action-builder-panel"}>
        <ActionTree
          {...props}
          onItemSelect={handleItemSelect}
          treeDataChanges={treeDataChanges}
          setTreeDataChanges={setTreeDataChanges}
        />
        <div className={"action-property-panel-container"}>
          {eventNode && (
            <ActionPropertyPanel
              eventNode={eventNode}
              onItemUpdate={handleItemUpdate}
              onItemRemove={handleItemRemove}
            />
          )}
          {/*<p>Select an Action to edit</p>*/}
        </div>
      </div>
    </Modal>
  );
};
