import React, { useState } from "react";
import { ClickEvent, Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { EnsembleTranslationData } from "../config/interfaces";
import { ReactComponent as IconMenu } from "../assets/icon_menu.svg";
import LanguageArchive from "./LanguageArchive";
import { LanguageSetDefault } from "./LanguageSetDefault";

export interface LanguageActionProps {
  language: EnsembleTranslationData;
  actionTrigger: boolean;
  setActionTrigger: (val: boolean) => void;
}

export interface LanguageActionModalProps {
  language: EnsembleTranslationData;
  onCancel: () => void;
  onSubmit: () => void;
}

interface LanguageActionsMenuProps {
  language: EnsembleTranslationData;
  isReadOnly: boolean;
}

export const LanguageActionsMenu: React.FC<LanguageActionsMenuProps> = ({
  language,
  isReadOnly,
}) => {
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);
  const [setDefaultTrigger, setSetDefaultTrigger] = useState<boolean>(false);

  const handleArchive = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const handleSetDefault = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setSetDefaultTrigger(true);
  };

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => e.preventDefault()}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="bottom"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {!isReadOnly && !language.defaultLocale && (
          <MenuItem className="actions-menu" onClick={handleSetDefault}>
            Set as default language
          </MenuItem>
        )}

        {!isReadOnly && (
          <MenuItem
            className="actions-menu__delete-item"
            onClick={handleArchive}
          >
            Archive
          </MenuItem>
        )}
      </Menu>

      <LanguageSetDefault
        language={language}
        actionTrigger={setDefaultTrigger}
        setActionTrigger={setSetDefaultTrigger}
      />

      <LanguageArchive
        language={language}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />
    </>
  );
};
