import React from "react";

// APIs
import { useModal } from "../../hooks/useModal";

// Components
import Modal from "../Modal";
import AssetArchiveModal from "./AssetArchiveModal";

// Interfaces
import { EnsembleAssetData } from "../../config/interfaces";

interface AssetArchiveProps {
  asset: EnsembleAssetData;
  displayArchiveModal: boolean;
  setArchiveTrigger: (value: boolean) => void;
}

const AssetArchive: React.FC<AssetArchiveProps> = ({
  asset,
  displayArchiveModal,
  setArchiveTrigger,
}) => {
  const { toggleModal } = useModal();

  const onCancel = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  const onSubmit = () => {
    setArchiveTrigger(false);
    toggleModal();
  };

  return (
    <Modal
      isModalDisplayed={displayArchiveModal}
      onHide={onCancel}
      headerText={`Archive ${asset.name}?`}
      modalContent={
        <AssetArchiveModal
          asset={asset}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default AssetArchive;
