import { useAppContext } from "./AppPagesWrapper";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "ramda";
import { LanguageCard } from "../components/cards/LanguageCard";
import { useState } from "react";
import { Popover, Menu } from "antd";
import { languageMap } from "../utils/Util";
import "./Translations.sass";

export const Translations = () => {
  const { app, isAppReadOnly } = useAppContext();
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const navigate = useNavigate();

  const handleLanguageSelect = (selectedOption: any) => {
    setShowLanguageSelection(false);
    navigate(`/app/${app.id}/translations/${selectedOption.key}`);
  };

  const menu = (
    <Menu
      className={"translation-add-language-menu"}
      onClick={handleLanguageSelect}
    >
      {Object.entries(languageMap).map(([code, { name, flag }]) => (
        <Menu.Item key={code}>
          <div className={"translation-add-language-menu-item"}>
            <span style={{ fontSize: "26px" }}>{flag} </span> {name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="screen-content" key={app.id}>
      <div className="breadcrumb">
        <Link to="/">Apps</Link>
        <span>/</span>
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
      </div>

      <div className="page-header">
        <div>
          <h1>Translations</h1>
          <p className="title"></p>
        </div>

        <div className="page-actions">
          {!isAppReadOnly && (
            <Popover
              overlayClassName={"translation-add-language-popup"}
              content={menu}
              trigger="click"
              open={showLanguageSelection}
              onOpenChange={(visible) => setShowLanguageSelection(visible)}
              placement="bottom"
            >
              <button
                className="button__primary"
                onClick={() => setShowLanguageSelection(!showLanguageSelection)}
              >
                Add new language
              </button>
            </Popover>
          )}
        </div>
      </div>

      <div className="screen-cards-st">
        {isEmpty(app.translations) ? (
          <span>Please add a language for translation.</span>
        ) : (
          <>
            {app.translations
              ?.sort((a, b) => (a.defaultLocale ? -1 : b.defaultLocale ? 1 : 0))
              .map((language) => (
                <LanguageCard
                  key={language.id}
                  language={language}
                  app_id={app.id}
                  isReadOnly={isAppReadOnly}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};
