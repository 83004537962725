import React, { useLayoutEffect, useState } from "react";
import { getArtifactPreviewUrl, SESSION_FORCED_LOCALE } from "../utils";
import { KeyValuePair } from "../config/interfaces";

import iconPhone from "../assets/icon_phone.svg";
import iconRefresh from "../assets/icon_refresh.svg";
import iconSettings from "../assets/icon_settings.svg";
import iconInputs from "../assets/icon_inputs.svg";
import Modal from "./Modal";
import DevicePreviewModal from "./DevicePreviewModal";
import ArtifactInputsModal from "./modals/ArtifactInputs/ArtifactInputsModal";
import Dropdown from "./Dropdown";
import { LanguageSelector } from "./LanguageSelector";

interface IEditorPreviewProps {
  appId: string;
  screenId: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  inputParams: KeyValuePair[];
  onRefreshPreview: () => void;
  handleArtifactInputs: (values?: KeyValuePair[]) => void;
  onTogglePreview: () => void;
  onCopyPreviewLink: () => void;
  useReact?: boolean;
}

const EditorPreview: React.FC<IEditorPreviewProps> = (props) => {
  const {
    appId,
    screenId,
    iframeRef,
    inputParams,
    onRefreshPreview,
    useReact,
  } = props;

  const [consoleLogObj, setConsoleLogObj] = useState<Console | null>(null);
  const [displayPreviewModal, setDevicePreviewModal] = useState<boolean>(false);
  const [inputsModalTrigger, setInputsModalTrigger] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (iframeRef.current?.contentWindow) {
      setConsoleLogObj(iframeRef.current.contentWindow.console);
      iframeRef.current.contentWindow.console = {
        ...iframeRef.current.contentWindow.console,
        log: (str) => {
          if (!str.startsWith("Error :")) {
            window.postMessage(
              JSON.stringify({ isLog: true, message: str }),
              "*",
            );
          }
        },
        error: (str) => {
          window.postMessage(
            JSON.stringify({ isError: true, message: str }),
            "*",
          );
        },
        warn: (str) => {
          window.postMessage(
            JSON.stringify({ isWarn: true, message: str }),
            "*",
          );
        },
      };

      iframeRef.current.contentWindow.onerror = (
        _event,
        source,
        lineno,
        colno,
        message,
      ) => {
        window.postMessage(
          JSON.stringify({
            isError: true,
            message: message?.toString(),
            lineno,
            source,
            colno,
          }),
          "*",
        );
        return;
      };
    }
    return () => {
      if (iframeRef.current?.contentWindow && consoleLogObj) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        iframeRef.current.contentWindow.console = consoleLogObj;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    setDevicePreviewModal(false);
  };

  const handlePreview = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setDevicePreviewModal(true);
  };

  const handleSetInputs = () => {
    props.handleArtifactInputs();
    setInputsModalTrigger(!inputsModalTrigger);
  };

  const handleLanguageSelected = (languageCode: string | null) => {
    if (languageCode != sessionStorage.getItem(SESSION_FORCED_LOCALE)) {
      if (languageCode) {
        sessionStorage.setItem(SESSION_FORCED_LOCALE, languageCode);
      } else {
        sessionStorage.removeItem(SESSION_FORCED_LOCALE);
      }
      // refresh the preview
      onRefreshPreview();
    }
  };

  return (
    <div className={`editor-preview-ct ${useReact ? "preview-react" : ""}`}>
      <div className="preview-action">
        {!useReact && (
          <div className="button__preview" onClick={handlePreview}>
            <img className="logo" src={iconPhone} alt={"device"} />
            View on device
          </div>
        )}
        <div className="button__preview" onClick={handleSetInputs}>
          <img className="logo" src={iconInputs} alt={"inputs"} height={18} />
          Inputs
        </div>

        <LanguageSelector onLanguageSelected={handleLanguageSelected} />

        <div className="icon__refresh" onClick={onRefreshPreview}>
          <img
            className="logo"
            src={iconRefresh}
            alt={"refresh"}
            title="Reload the preview to see latest changes"
          />
        </div>
        <Dropdown
          wrapperClass="dropdown-wrapper"
          className="button__preview options-dropdown"
          listClass="options-dropdown-list"
          activatorText={
            <img
              className="logo"
              src={iconSettings}
              alt={"settings"}
              height={18}
              title="Hide or change the device preview"
            />
          }
          hideCaret
          items={[
            {
              id: "preview-toggle",
              text: "Hide preview",
              clickHandler: props.onTogglePreview,
            },
            {
              id: "preview-link",
              text: "Copy Preview Link",
              clickHandler: props.onCopyPreviewLink,
            },
            {
              id: "preview-options",
              text: "Open preview in new tab",
              clickHandler: () =>
                window.open(
                  getArtifactPreviewUrl(
                    appId,
                    props.screenId,
                    props.inputParams,
                  ),
                  "_blank",
                ),
            },
          ]}
        />
      </div>
      <iframe
        title="Ensemble Preview"
        ref={iframeRef}
        // width={previewSize.width}
        // height={previewSize.height}
        // className={`z${previewZoom * 100}`}
        src={getArtifactPreviewUrl(appId, screenId, inputParams!, useReact)}
      />
      <Modal
        isModalDisplayed={displayPreviewModal}
        onHide={onCancel}
        headerText="Preview on your device"
        modalContent={<DevicePreviewModal />}
      />
      <ArtifactInputsModal
        isModalDisplayed={inputsModalTrigger}
        inputs={props.inputParams}
        handleInputsModalTrigger={() => setInputsModalTrigger(false)}
        handleSetInputs={(inputs) => props.handleArtifactInputs(inputs)}
      />
    </div>
  );
};

export default EditorPreview;
