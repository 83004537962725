import React from "react";

export enum ButtonVariant {
  PRIMARY = "button__primary",
  SECONDARY = "button__secondary",
  LINK = "button__link",
  ICON = "button__icon",
}

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: ButtonVariant;
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  children,
  className,
  ...props
}) => {
  return (
    <button className={`${variant} ${className}`} {...props}>
      {children}
    </button>
  );
};
