import React, { useState, createRef, useEffect, useContext } from "react";
import { AuthContext } from "../App";

// APIs
import { useUpdateApp } from "../hooks/useAPIs";
import { AppData } from "../models/model";

interface AppRenameModalProps {
  app: AppData;
  onCancel: () => void;
  onSubmit: () => void;
}

const AppRenameModal: React.FC<AppRenameModalProps> = ({ app, onSubmit }) => {
  const [appName, setAppName] = useState(app.name);
  const [appDescription, setAppDescription] = useState(app.description || "");
  const { currentUser } = useContext(AuthContext);
  const updateAppQuery = useUpdateApp(app.id, currentUser?.id, {
    name: appName,
    description: appDescription,
  });
  const submitButtonRef = createRef<HTMLButtonElement>();

  const handleInputChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    setAppName(event.currentTarget.value);
  };

  const handleDescriptionInputChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    setAppDescription(event.currentTarget.value);
  };

  // submit on Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      submitButtonRef.current?.click();
    }
  };

  // trigger update
  const renameApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    updateAppQuery.mutate();
  };

  // update complete
  useEffect(() => {
    if (updateAppQuery.isSuccess) onSubmit();
  }, [onSubmit, updateAppQuery.isSuccess]);

  return (
    <form className="app-create-modal" onSubmit={renameApp}>
      <label>App name</label>
      <input
        type="text"
        value={appName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        autoFocus
      />

      <label>App description</label>
      <input
        type="text"
        value={appDescription}
        onChange={handleDescriptionInputChange}
        onKeyDown={handleKeyDown}
      />
      <br />

      <button
        type="submit"
        className="button__primary"
        ref={submitButtonRef}
        disabled={
          !appName ||
          (app.name === appName && app.description === appDescription)
        }
      >
        {updateAppQuery.isIdle && <>Edit app</>}
        {updateAppQuery.isLoading && <>Processing...</>}
      </button>
    </form>
  );
};

export default AppRenameModal;
