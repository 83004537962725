// Contains Global Configurations
const config = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  github: {
    accessToken: process.env.REACT_APP_GITHUB_ACCESS_TOKEN,
    userAgent: process.env.REACT_APP_GITHUB_USER_AGENT,
  },
  preview: {
    url: "https://studio.ensembleui.com/preview/index.html",
    relativeUrl: "/preview/index.html",
  },
  previewReact: {
    url: process.env.REACT_APP_REACT_PREVIEW_URL,
  },
  intercom: {
    appId: "rjpfk3ge",
    api_base: "https://api-iam.intercom.io",
  },
  collaboratorId: process.env.REACT_APP_ENSEMBLE_COLLABORATOR_ID,
};

export default config;
