import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { EnsembleArtifactHistoryData } from "../config/interfaces";
import Dropdown from "../components/Dropdown";
import { getDateTime } from "../utils";
import { useGetArtifactHistory } from "../hooks/useAPIs";

export type ArtifactHistoryDropdownProps = {
  appId: string;
  artifactId: string;
  onSelectHistory?: (history: EnsembleArtifactHistoryData) => void;
  selectedHistory?: EnsembleArtifactHistoryData;
};
export const ArtifactHistoryDropdown: React.FC<
  ArtifactHistoryDropdownProps
> = ({ onSelectHistory, appId, artifactId, selectedHistory }) => {
  const params = useParams();
  const artifactHistoryQuery = useGetArtifactHistory(
    appId,
    artifactId,
    params.screen_id ? "artifacts" : "internal_artifacts",
  );
  const [currentlySelectedHistory, setCurrentlySelectedHistory] =
    useState<EnsembleArtifactHistoryData>();

  useEffect(() => {
    setCurrentlySelectedHistory(selectedHistory);
  }, [selectedHistory]);

  const onSelectHistoryItem = useCallback(
    (item: EnsembleArtifactHistoryData) => {
      onSelectHistory?.(item);
      setCurrentlySelectedHistory(item);
    },
    [onSelectHistory],
  );

  // Build history list dropdown
  const historyList = useMemo(() => {
    const history = artifactHistoryQuery.data?.history;
    return history?.map((item) => ({
      id: item.id,
      text: <ArtifactHistoryEntry item={item} />,
      clickHandler: () => onSelectHistoryItem(item),
    }));
  }, [artifactHistoryQuery.data?.history, onSelectHistoryItem]);

  return (
    <div className="history-section">
      <Dropdown
        className="dropdown"
        activatorText={
          currentlySelectedHistory
            ? currentlySelectedHistory.label ??
              getDateTime(currentlySelectedHistory.updatedAt.toDate())
            : "History"
        }
        items={historyList}
      />
    </div>
  );
};

const ArtifactHistoryEntry: React.FC<{ item: EnsembleArtifactHistoryData }> = ({
  item,
}) => {
  return (
    <div className="history-dropdown-item">
      {item.label ? (
        <>
          <span className="item-label">{item.label}</span>
          <br />
        </>
      ) : null}
      <span className={item.label ? "item-name" : "item-time"}>
        {item.updatedAt && getDateTime(item.updatedAt?.toDate())}
      </span>
      <br />
      <span className="item-name">{item.updatedBy?.name ?? ""}</span>
    </div>
  );
};
