import { ActionSchema } from "../../hooks/useSchemas";
import { isMap, isScalar, Pair, Scalar, YAMLMap } from "yaml";
import { DataNode } from "rc-tree/lib/interface";
import { getPairKeyText } from "../../utils/docUtils";
import { RootWidgetContext } from "../WidgetPropertyPanel/fields/RootWidgetContext";

export type ActionTreeNode = DataNode & {
  subtitle?: string;
  ref: EventNode;
  actions?: TreeItemAction[];
};

// for Actions, represent the eventPair (parent of action) and its parent
export interface EventNode {
  eventPair: Pair<Scalar, Scalar | YAMLMap>;
  // the parent of the eventPair. We need this if we need to remove the eventPair.
  // Note at the root eventPair, the parent would be null, and we can only remove the Action
  parent: YAMLMap | null;
  // sometimes we need to show a placeholder since the root event doesn't exist yet (i.e. first time)
  // This placeholderContext has the entire paths for us to construct the eventPair no matter how nested it is
  placeholderContext?: RootWidgetContext;
}

export interface TreeItemAction {
  type: "add" | "delete";
  value?: string;
}

export class ActionTreeBuilder {
  constructor(
    private readonly actionSchemaMap: ReadonlyMap<string, ActionSchema>,
  ) {}

  static NO_ACTION = "Select an Action";

  /**
   * Build the Tree given the root event pair e.g. onTap -> YamlMap(invokeAPI => YamlMap)
   */
  build(eventPair: Pair): ActionTreeNode[] | null {
    // enforce the type here so we can use it properly later on
    if (isScalar(eventPair.value) || isMap(eventPair.value)) {
      const rtnNode = this.buildNode(
        eventPair as Pair<Scalar, Scalar | YAMLMap>,
        // we simply concat eventNames together, separate by - as the key.
        // the reason we don't want actionName in there is for ease of keeping the tree selection intact
        "",
        null,
      );
      if (rtnNode) {
        return [rtnNode];
      }
    }
    return null;
  }

  /**
   * our event Pair (key=eventTrigger, value=Action) looks something like this:
   *   Scalar(onTap) -> {
   *     Scalar(navigateBack)
   *     or
   *     YAMLMap(
   *       Scalar(invokeAPI) -> Scalar|YAMLMap
   *     )
   *   }
   */
  private buildNode(
    eventPair: Pair<Scalar, Scalar | YAMLMap>,
    keyPrefix: string,
    parent: YAMLMap | null,
  ): ActionTreeNode | null {
    const eventName = getPairKeyText(eventPair);
    const prefix = keyPrefix ? keyPrefix + "-" : "";

    if (eventPair.value instanceof Scalar) {
      const actionName = eventPair.value.value as string;
      const key = `${prefix}${eventName}`;
      // we can still show the Node with just the Event name without the Action.
      if (actionName == null) {
        return {
          key: key,
          ref: {
            eventPair,
            parent,
          },
          subtitle: eventName,
          title: ActionTreeBuilder.NO_ACTION,
        };
      }

      // build the node and its available child Events.
      const allEvents = this.actionSchemaMap.get(actionName)?.childEvents;
      return {
        key: key,
        ref: {
          eventPair,
          parent,
        },
        subtitle: eventName,
        title: actionName,
        actions: this.getTreeItemEvents(allEvents, []),
      };
    }
    // if the Action is a YAMLMap, it has to be a single child e.g. YAMLMap(invokeAPI -> ...)
    else if (
      eventPair.value instanceof YAMLMap &&
      eventPair.value.items.length === 1
    ) {
      const actionPair = eventPair.value.items[0];
      const actionName = getPairKeyText(actionPair);
      const allEvents = this.actionSchemaMap.get(actionName)?.childEvents;
      const key = `${prefix}${eventName}`;
      const children = this.buildChildren(actionPair, key);
      return {
        key: key,
        ref: {
          eventPair,
          parent,
        },
        subtitle: eventName,
        title: actionName,
        children: children?.children,
        actions: this.getTreeItemEvents(
          allEvents,
          children?.excludedEvents || [],
        ),
      };
    }
    return null;
  }

  private buildChildren(actionPair: Pair, keyPrefix: string) {
    const childEvents = this.actionSchemaMap.get(getPairKeyText(actionPair))
      ?.childEvents;
    // if our Action's schema has any child events
    if (
      childEvents &&
      childEvents.size > 0 &&
      actionPair.value instanceof YAMLMap
    ) {
      const children: ActionTreeNode[] = [];
      const excludedEvents: string[] = [];

      // iterate through each child property
      actionPair.value.items.forEach((propertyPair) => {
        const eventName = getPairKeyText(propertyPair);
        // if a property matches a event, add it as a tree node
        if (childEvents.has(eventName)) {
          // if the property is an event, add it as a tree node
          const childNode = this.buildNode(
            propertyPair,
            keyPrefix,
            actionPair.value as YAMLMap,
          );
          if (childNode) {
            children.push(childNode);
            excludedEvents.push(eventName);
          }
        }
      });

      if (children.length > 0) {
        return { children, excludedEvents };
      }
    }
    return null;
  }

  // removed the events already added to the Action and return just the available ones
  private getTreeItemEvents(
    allEvents: ReadonlySet<string> | undefined,
    excludedEvents: string[],
  ): TreeItemAction[] | undefined {
    if (allEvents) {
      const result: TreeItemAction[] = Array.from(allEvents)
        .filter((event) => !excludedEvents.includes(event))
        .map((event) => ({ type: "add", value: event }));
      if (result.length > 0) {
        return result;
      }
    }
  }
}

// Build an Event placeholder when the tree doesn't have a root Action yet
export class ActionTreePlaceholderBuilder {
  constructor(private readonly nodeContext: RootWidgetContext) {}

  build(): ActionTreeNode[] {
    const actionName = this.nodeContext.getPropertyName() ?? "";
    return [
      {
        key: actionName,
        subtitle: actionName,
        title: ActionTreeBuilder.NO_ACTION,
        // just placeholder to conform to the interface
        ref: {
          eventPair: new Pair<Scalar, Scalar>(
            new Scalar(actionName),
            new Scalar(null),
          ),
          parent: null,
          // pass the nodeContext to construct the eventPair later
          placeholderContext: this.nodeContext,
        },
      },
    ];
  }
}
