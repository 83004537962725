import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";

// APIs
import { useRemoveCollaborator } from "../hooks/useAPIs";

// Interfaces
import { AppData } from "../models/model";

interface AppArchiveModalProps {
  app: AppData;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: () => void;
}

const AppArchiveModal: React.FC<AppArchiveModalProps> = ({
  app,
  onCancel,
  onSubmit,
}) => {
  const { currentUser } = useContext(AuthContext);
  const removeCollaborator = useRemoveCollaborator(app.id, currentUser?.id);
  const navigate = useNavigate();

  // trigger update
  const archiveApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    removeCollaborator.mutate();
  };

  // update complete
  useEffect(() => {
    if (removeCollaborator.isSuccess) {
      onSubmit();
      return navigate(`/`);
    }
  }, [removeCollaborator.isSuccess, onSubmit, navigate]);

  return (
    <form className="app-create-modal" onSubmit={archiveApp}>
      <p>You will lose access to this app.</p>
      <button
        type="submit"
        onClick={(event: any) => archiveApp(event)}
        className="button__primary"
      >
        {removeCollaborator.isIdle && <>Confirm</>}
        {removeCollaborator.isLoading && <>Processing...</>}
      </button>
      <button className="button__link" onClick={onCancel}>
        Cancel
      </button>
    </form>
  );
};

export default AppArchiveModal;
