import {
  WidgetSelectionItem,
  WidgetSelectionPanel,
} from "./WidgetSelectionPanel";
import React, {
  cloneElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import { createPortal } from "react-dom";

interface PanelPosition {
  left?: number;
  right?: number;
}

export type WidgetPickerProps = {
  trigger: ReactElement;
  onWidgetSelect?: (widgetKey: string) => void;
};

export const WidgetPicker: React.FC<WidgetPickerProps> = ({
  trigger,
  onWidgetSelect,
}) => {
  const [isPanelShowing, setIsPanelShowing] = useState(false);
  const { docTreeStore } = useYamlDoc();
  const triggerRef = useRef<HTMLDivElement>(null);

  // wrap the trigger to provide click handler showing the panel
  const triggerComp = cloneElement(trigger, {
    onClick: (event: React.MouseEvent) => {
      setIsPanelShowing(true);

      // stop the click event from propagating (e.g. so Tree node is not selected)
      event.stopPropagation();
    },
  });

  const [panelPosition, setPanelPosition] = useState<PanelPosition>({
    left: undefined,
    right: undefined,
  });
  useEffect(() => {
    if (isPanelShowing && triggerRef.current) {
      const triggerRect = triggerRef.current!.getBoundingClientRect();
      setPanelPosition({ left: triggerRect.right + 10 });
    }
  }, [isPanelShowing]);

  const onWidgetSelect_internal = (widgetKey: string) => {
    setIsPanelShowing(false);
    onWidgetSelect?.(widgetKey);
  };

  const panelRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setIsPanelShowing(false);
    }
  };
  // add/remove click outside listener on mount/unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={triggerRef}>{triggerComp}</div>
      {panelPosition.left &&
        createPortal(
          <WidgetSelectionPanel
            panelRef={panelRef}
            onWidgetSelect={onWidgetSelect_internal}
            isShowing={isPanelShowing}
            platformWidgets={docTreeStore?.widgetList?.map((name) =>
              toWidgetSelectionItem(name),
            )}
            customWidgets={docTreeStore?.customWidgetList?.map((name) =>
              toWidgetSelectionItem(name),
            )}
            style={{ left: panelPosition.left }}
          />,
          document.body,
        )}
    </>
  );
};

function toWidgetSelectionItem(name: string): WidgetSelectionItem {
  return {
    key: name,
  };
}
